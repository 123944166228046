<template>
    <div
        id="app"
        class="app"
        ref="app"
        :class="{
            'app--dark-mode': $store.state.preferences.darkMode,
        }"
    >
        <div class="app__inner" v-show="$store.state.window.loaded">
            <notifications></notifications>
            <div class="dropzone" :class="{ 'dropzone--active': dropzoneActive }" ref="dropzone"></div>
            <sidebar></sidebar>
            <tab-view :tabs="$store.getters.tabs"></tab-view>

            <component v-if="$store.state.panel" :is="$store.state.panel"></component>

            <a id="download" style="display: none"></a>
            <div id="font-preloads" style="position: fixed; top: 1000vh; bottom: 1000vh; visibility: hidden">
                <span v-for="font in $store.state.fonts" :key="font.id" :style="{ fontFamily: ` '${font.id}'` }"></span>
            </div>
        </div>
    </div>
</template>

<script>
import Notifications from "@/components/Notifications.vue"
import PanelView from "@/components/Views/Panel/PanelView.vue"
import Sidebar from "@/components/Sidebar/Sidebar.vue"
import TabView from "@/components/Views/TabView/TabView.vue"

export default {
    name: "app",
    data() {
        return {
            dropzoneActive: false,
            keysDown: {
                meta: false,
            },
        }
    },
    components: {
        Notifications,
        PanelView,
        Sidebar,
        TabView,
    },
    methods: {
        dropHandler(e) {
            event.preventDefault()
            this.dropzoneActive = false
            this.dropHandlerBrowser(e)
        },

        dropHandlerBrowser(e) {
            this.$store.dispatch("openFontFromDropEvent", e)
        },
    },
    created() {
        this.$store.dispatch("initApp")
        window.addEventListener("load", () => setTimeout(() => this.$store.commit("windowLoaded"), 50))
    },
    mounted() {
        function allowDrag(e) {
            // Test that the item being dragged is a valid one
            e.dataTransfer.dropEffect = "copy"
            e.preventDefault()
        }
        this.$refs.dropzone.addEventListener("dragenter", allowDrag)
        this.$refs.dropzone.addEventListener("dragover", allowDrag)

        this.$refs.dropzone.addEventListener("drop", (e) => {
            document.body.classList.remove("dropzone--active")
            this.dropHandler(e)
        })

        window.addEventListener("dragenter", () => {
            this.dropzoneActive = true
            document.body.classList.add("dropzone--active")
        })
        this.$refs.dropzone.addEventListener("dragleave", () => {
            this.dropzoneActive = false
            document.body.classList.remove("dropzone--active")
        })
    },
}
</script>

<style lang="scss" scoped>
body.dropzone--active {
    #app,
    #app * {
        pointer-events: none;
    }
    #app .dropzone {
        filter: none;
        pointer-events: all;
    }
    #app .dropzone * {
        filter: none;
    }
}

#app {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.app {
    &__inner {
        flex: 1 1 100%;
        display: flex;
        flex-direction: row;
        overflow: hidden;
    }
}

.dropzone {
    position: fixed;
    z-index: 10000;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    right: 0;
    visibility: hidden;
    background-color: rgba(255, 0, 0, 0.75);

    &--active {
        visibility: visible;
    }
}

@font-face {
    font-family: "Dinamo Font Gauntlet Icons";
    src: url("./assets/fonts/DinamoFGIcons.woff2") format("woff2"),
        url("./assets/fonts/DinamoFGIcons.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}
</style>
