<template>
    <div>
        <div class="button-pod" style="width: 100%; display: flex; flex-direction: row; margin-bottom: var(--padding)">
            <button
                class="button"
                style="flex: 1 1 50%"
                :class="{ active: currentColorLayer == 'fg' }"
                @click="switchColorLayer('fg')"
            >
                Foreground
            </button>
            <button
                class="button--square"
                style="flex: 0 0 auto; background-color: #cccccc"
                @click="switchBodyTextColors"
            >
                <span class="icon">&#xF021;</span>
            </button>
            <button
                class="button"
                style="flex: 1 1 50%"
                :class="{ active: currentColorLayer == 'bg' }"
                @click="switchColorLayer('bg')"
            >
                Background
            </button>
        </div>

        <div id="picker-wrapper">
            <div id="picker"></div>
            <div class="picker-code flex rw ai-c">
                <template v-if="color != null">
                    <template v-if="colorMode === 'rgb'" class="rgb-input">
                        <span>R</span>
                        <input
                            type="text"
                            :value="color.red"
                            @input="(e) => updateChannelValue('red', e.target.value)"
                        />
                        <span>G</span>
                        <input
                            type="text"
                            :value="color.green"
                            @input="(e) => updateChannelValue('green', e.target.value)"
                        />
                        <span>B</span>
                        <input
                            type="text"
                            :value="color.blue"
                            @input="(e) => updateChannelValue('blue', e.target.value)"
                        />
                    </template>
                    <template v-else-if="colorMode === 'hex'" class="rgb-input">
                        <span>HEX</span>
                        <input
                            type="text"
                            v-model="hexString"
                            @blur="hexString = color.hexString"
                            @input="(e) => updateHexValue(e.target.value)"
                        />
                    </template>
                    <button class="plain icon circle-hover" @click="toggleColorMode">&#57344;</button>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import iro from "@jaames/iro"

export default {
    name: "text-style",
    data() {
        return {
            picker: null,
            currentColorLayer: "fg",
            colorMode: "hex",
            color: null,
            hexString: "",
        }
    },
    computed: {
        tab: function () {
            return this.$store.getters.tab
        },
        fgColor: function () {
            if (this.$store.state.session == null) return "#000"
            return this.$store.getters.fg.color
        },
        bgColor: function () {
            if (this.$store.state.session == null) return "#fff"
            return this.$store.getters.bg.backgroundColor
        },
    },
    watch: {
        fgColor: function (newColor) {
            if (this.currentColorLayer === "fg") this.updatePickerColor(newColor)
        },

        bgColor: function (newColor) {
            if (this.currentColorLayer === "bg") this.updatePickerColor(newColor)
        },
    },
    methods: {
        switchColorLayer(layerName) {
            if (layerName === this.currentColorLayer) return
            this.currentColorLayer = layerName
            this.setCurrentColor()
        },

        setCurrentColor() {
            if (this.currentColorLayer === "fg") this.updatePickerColor(this.fgColor)
            else if (this.currentColorLayer === "bg") this.updatePickerColor(this.bgColor)
        },

        handleColorChange(color) {
            if (this.currentColorLayer === "fg")
                this.$store.dispatch("updateTabFgValue", {
                    name: "color",
                    value: color.rgbaString,
                })
            else if (this.currentColorLayer === "bg")
                this.$store.dispatch("updateTabBgValue", {
                    name: "backgroundColor",
                    value: color.rgbaString,
                })
            this.red = this.picker
        },

        updatePickerColor(color) {
            this.color = new iro.Color(color)
            if (this.hexString == "" || new iro.Color(this.hexString).hexString !== this.color.hexString)
                this.hexString = this.color.hexString
            if (this.color.rgbaString === this.picker.color.rgbaString) return
            this.picker.color.rgbaString = this.color.rgbaString
        },

        switchBodyTextColors() {
            this.$store.dispatch("switchBodyTextColors")
            this.setCurrentColor()
        },

        updateChannelValue(channelName, value) {
            value = parseInt(value)
            if (isNaN(value)) return
            this.picker.color[channelName] = value
        },

        updateHexValue(value) {
            try {
                this.picker.color.hexString = value
            } catch (e) {
                return
            }
        },

        toggleColorMode() {
            this.colorMode = this.colorMode === "rgb" ? "hex" : "rgb"
        },
    },
    mounted() {
        let pickerEl = document.getElementById("picker-wrapper")
        if (pickerEl == null) return
        let pickerWidth = pickerEl.offsetWidth

        this.picker = new iro.ColorPicker("#picker", {
            width: pickerWidth,
            margin: 10,
            handleRadius: 9,
            padding: 4,
            // layoutDirection: "horizontal",
            boxHeight: pickerWidth * 0.4,
            layout: [
                {
                    component: iro.ui.Box,
                    options: {
                        boxHeight: pickerWidth * 0.4,
                        // width: pickerWidth-10-26,
                        // display: "inline-block",
                    },
                },
                {
                    component: iro.ui.Slider,
                    options: {
                        sliderType: "hue",
                        // display: "inline-block",
                        // width: pickerWidth /2,
                        // height: pickerWidth / 2,
                    },
                },
            ],
        })

        this.picker.on("color:change", this.handleColorChange)
        this.setCurrentColor()
    },
}
</script>

<style lang="scss" scoped>
.color-row {
    grid-template-columns: repeat(11, 1fr);
}

.button__color {
    border-radius: 0;
    width: 100%;
    height: 0;
    padding: 0;
    padding-bottom: calc(100% - 0px);
    box-sizing: border-box;
    margin: 6px 0 10px 0;
    background-color: transparent;
    position: relative;

    &--selected {
        border-radius: 50%;
    }
}

.picker-code {
    margin-top: var(--padding);
    width: 100%;

    span {
        flex: 1 1 auto;
        display: inline-block;
        font-weight: bold;
        margin-right: 8px;
        margin-left: 10px;
    }

    input {
        flex: 1 1 100%;
        min-width: 0;
    }

    & > *:first-child {
        margin-left: 0;
    }
    & > *:last-child {
        margin-left: 4px;
    }
}
</style>
<style lang="scss">
// .IroBox,
// .IroSlider {
//     width: 100% !important;
// }
</style>
