export default {
    axes: (state, getters) => {
        if (getters.font == null) return null
        return getters.fontTabData.axes
    },

    fontSourceAxes: (state, getters) => {
        if (getters.font == null) return null
        return getters.font.axes
    },

    getAxisByIndex: (state, getters) => (index) => {
        if (getters.axes == null) return
        index = parseInt(index)
        for (let axis of Object.values(getters.axes)) if (axis.index === index) return axis
        return null
    },

    animating: (state, getters) => {
        // eslint-disable-next-line no-unused-vars
        for (const [axisTag, axis] of Object.entries(getters.axes)) if (axis.animating) return true
        return false
    },

    doesCurrentAxesPositionsMatchAnInstance: (state, getters) => {
        if (!getters.isFontVariable) return null
        if (getters.axes == null || getters.axes.length == 0) return null

        for (let instance of getters.instances) {
            let matched = true
            for (let axisIndex in instance.coordinates) {
                let axis = getters.font.Font.opentype.tables.fvar.axes[axisIndex]
                // Round both values to int to allow for softer matching
                if (parseInt(getters.axes[axis["tag"]].value) !== parseInt(instance.coordinates[axisIndex])) {
                    matched = false
                    break
                }
            }
            if (matched) return instance
        }
        return null
    },

    getFontAxesDefaultStates: (state, getters) => (font) => {
        let axes = {}
        for (let axisTag in font.axes) {
            axes[axisTag] = getters.getFontAxisDefaultState(font.axes[axisTag])
        }
        return axes
    },

    getFontAxisDefaultState: () => (axis) => {
        return {
            ...axis,
            min: axis.minDefault,
            max: axis.maxDefault,
            value: axis.valueDefault,
            animating: false,
            direction: 1,
            speed: 1,
            t: 0,
            loopType: "alternate",
            easing: "easeInOutQuad",
        }
    },
}
