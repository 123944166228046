function getAppEnv() {
    if (process.env.VUE_APP_ID === "font-gauntlet-staging") return "staging"
    if (process.env.VUE_APP_ID === "font-gauntlet-dev") return "development"
    return "production"
}

const config = {
    API: process.env.VUE_APP_API,
    NODE_ENV: process.env.NODE_ENV,
    BASE_URL: process.env.BASE_URL,
    ELECTRON: !!process.env.VUE_APP_ELECTRON,
    VERSION: process.env.VUE_APP_VERSION,
    APP_ID: process.env.VUE_APP_ID || "font-gauntlet",
    APP_ENV: getAppEnv(),
    BUILD_DATE: new Date(parseInt(process.env.VUE_APP_BUILD_DATE)),

    // INDEXEDDB VERSION
    INDEXEDDB_VERSION: 1,

    // LOCAL STORAGE SCHEMA VERSIONS
    USER_VERSION: 1.1,
    PREFERENCES_VERSION: 1.1,
    SESSION_VERSION: 1,
    FONT_VERSION: 1,
}

export default config
