<template>
    <div v-if="ready">
        <template v-for="(key, k) in keys" :key="k">
            <div class="code-row" :class="{ open: keysOpened[key] }" @click="toggleKeyOpen(key)" :data-level="level">
                {{ indent }}

                <span v-if="isObject(thisObject[key])" class="arrow"
                    >{{ keysOpened[key] ? "&#9660;" : "&#9654;" }}&nbsp;</span
                >
                <span v-else>&nbsp;&nbsp;</span>

                <span class="key">{{ key }}</span>
                <span class="colon">&#8203;:&nbsp;</span>

                <span v-if="typeof thisObject[key] === 'string'" class="value string">"{{ thisObject[key] }}"</span>
                <span v-else-if="typeof thisObject[key] === 'number'" class="value number">{{ thisObject[key] }}</span>
                <span v-else-if="typeof thisObject[key] === 'boolean'" class="value boolean">{{
                    thisObject[key]
                }}</span>
                <template v-else-if="isObject(thisObject[key])">
                    <span v-if="isArray(thisObject[key])" class="value number ns">[…]</span>
                    <span v-else class="value number ns">{…}</span>
                    <span v-if="getListOfAllKeys(thisObject[key]).length === 0" class="value-empty">&nbsp;empty</span>
                    <span v-else-if="isArray(thisObject[key])" class="value-length"
                        >&nbsp;{{ getListOfAllKeys(thisObject[key]).length }} items</span
                    >
                </template>
            </div>

            <template v-if="isObject(thisObject[key]) && keysOpened[key]">
                <object-viewer-recursive :thisObject="thisObject[key]" :level="level + 1"></object-viewer-recursive>
            </template>
        </template>
    </div>
</template>

<script>
export default {
    name: "object-viewer",
    props: ["thisObject", "level", "hideFunctions"],
    data() {
        return {
            opened: true,
            keysOpened: {},
            restrictedKeys: [
                "parser", // The table parser itself
                "p", // The table parser position
                "itemVarStore", // until it's fixed (ItemVariationStoreTable constructer needs table argument)
            ],
            ready: false,
        }
    },
    computed: {
        indent: function () {
            return this.createIndent(this.level)
        },

        keys: function () {
            let keys = this.getListOfAllKeys(this.thisObject)
            return keys
        },
    },
    methods: {
        toggleKeyOpen(property) {
            this.keysOpened[property] = !this.keysOpened[property]
        },

        getListOfAllKeys(object) {
            let prototypeKeys = []
            if (Array.isArray(object)) {
                for (let index in object) prototypeKeys.push(index)
                return prototypeKeys
            } else {
                if (Object.getPrototypeOf(object))
                    prototypeKeys = Object.getOwnPropertyNames(Object.getPrototypeOf(object))
                let allKeys = Object.getOwnPropertyNames(object)
                allKeys = this.arrayRemoveDuplicates(prototypeKeys.concat(allKeys))
                // Keys that start with underscores should be ignored
                allKeys = allKeys.filter((key) => key[0] !== "_")
                allKeys = allKeys.filter((key) => this.restrictedKeys.indexOf(key) === -1)
                allKeys = allKeys.filter((i) => typeof this.thisObject[i] !== "function")
                return allKeys
            }
        },

        arrayRemoveDuplicates(array) {
            let a = array.concat()
            for (let i = 0; i < a.length; ++i) {
                for (let j = i + 1; j < a.length; ++j) {
                    if (a[i] === a[j]) a.splice(j--, 1)
                }
            }
            return a
        },

        isObject(el) {
            return typeof el === "object"
        },

        isArray(el) {
            return Array.isArray(el)
        },

        isClass(obj) {
            const isCtorClass = obj.constructor && obj.constructor.toString().substring(0, 5) === "class"
            if (obj.prototype === undefined) {
                return isCtorClass
            }
            const isPrototypeCtorClass =
                obj.prototype.constructor &&
                obj.prototype.constructor.toString &&
                obj.prototype.constructor.toString().substring(0, 5) === "class"
            return isCtorClass || isPrototypeCtorClass
        },

        createIndent(indents) {
            let tab = ""
            for (var i = 0; i < indents; i++) {
                tab += `\xa0\xa0`
            }
            return tab
        },
    },
    created() {
        this.keysOpened = {}
        for (let key of this.keys) this.keysOpened[key] = false
        this.ready = true
    },
}
</script>

<style lang="scss" scoped>
$border: 1px solid var(--color--light--high);

.code-row {
    border-bottom: $border;
    display: flex;
    align-items: center;
    padding: 4px 5px;
    user-select: none;
    font-family: var(--font--mono);
    font-size: 12px;

    &:last-child[data-level="0"] {
        border: 0;
    }

    &:hover {
        background-color: #eeeeee;
    }

    * {
        user-select: none;
    }

    .arrow {
        color: #666666;
        user-select: none;
        cursor: pointer;
    }

    .key {
        color: blue;
        user-select: all;
    }

    .colon {
    }

    .number,
    .boolean {
        color: purple;
        user-select: all;
    }

    .string {
        color: green;
        user-select: all;
    }

    .tabs {
    }

    .empty {
        font-style: italic;
        color: orange;
    }

    .value-empty,
    .value-length {
        font-size: smaller;
    }

    .value-length {
        color: green;
    }

    .value-empty {
        color: red;
        font-style: italic;
    }

    .type {
        color: brown;
    }

    .ns {
        user-select: none;
    }
}
</style>
