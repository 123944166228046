import { markRaw } from "vue"
import SampleText from "@/components/Sidebar/Modules/SampleText.vue"
import CharacterSets from "@/components/Sidebar/Modules/CharacterSets.vue"
import TextProperties from "@/components/Sidebar/Modules/TextProperties.vue"
import Features from "@/components/Sidebar/Modules/Features.vue"
import FontInfo from "@/components/Sidebar/Modules/FontInfo.vue"
import Axes from "@/components/Sidebar/Modules/Axes.vue"
import Instances from "@/components/Sidebar/Modules/Instances.vue"
import Color from "@/components/Sidebar/Modules/Color.vue"

export default [
    {
        type: "single",
        component: markRaw(FontInfo),
        // show: !store.state.config.ELECTRON,
        style: {
            flex: "0 0 auto",
        },
    },
    {
        type: "single",
        component: markRaw(TextProperties),
        style: {
            flex: "0 0 auto",
        },
    },
    {
        tabs: [
            {
                title: "Sample Text",
                component: markRaw(SampleText),
            },
            {
                title: "Glyph Sets",
                component: markRaw(CharacterSets),
            },
        ],
        style: {
            flex: "0 0 auto",
        },
    },
    {
        variableSection: true,
        disabled: ["style-range"],
        tabs: [
            {
                title: "Variable Axes",
                component: markRaw(Axes),
            },
        ],
    },
    {
        variableSection: true,
        tabs: [
            {
                title: "Preset Styles",
                component: markRaw(Instances),
            },
        ],
    },
    {
        tabs: [
            {
                title: "Color",
                component: markRaw(Color),
            },
        ],
    },
    {
        tabs: [
            {
                title: "OpenType Features",
                component: markRaw(Features),
            },
        ],
    },
]
