<template>
    <div class="variation-axis__control">
        <div class="flex rw jc-sb ai-c">
            <div style="flex: 1 1 auto; text-overflow: ellipsis; overflow: hidden; white-space: nowrap">
                <div class="flex rw" style="padding-bottom: 2px">
                    <span class="text-overflow-ellipsis">
                        {{ axis.name }}
                    </span>
                    <span class="variation-axis__control__tag" style="opacity: 50%"
                        >&nbsp;({{ axisTag }})&nbsp;&nbsp;</span
                    >
                </div>
            </div>

            <div class="button-group" style="flex: 0 0 auto">
                <label class="c-select c-select--square" :class="{ disabled: $store.getters.axesInteractionActive }">
                    <span class="icon">&#xf55B;</span>
                    <select
                        @change="
                            (e) =>
                                $store.commit('setAxisEasing', {
                                    axisTag,
                                    easing: e.target.selectedOptions[0].value,
                                })
                        "
                    >
                        <option
                            v-for="(easingFunction, name) in easingFunctions"
                            :key="name"
                            :value="name"
                            v-bind:selected="name === easing"
                        >
                            {{ easingFunction.title }}
                        </option>
                    </select>
                </label>
                <button
                    class="button"
                    :class="{ disabled: $store.getters.axesInteractionActive }"
                    @click="$store.dispatch('toggleAxisSpeed', axisTag)"
                >
                    ×{{ speed.toFixed(1) }}
                </button>
                <button
                    class="button button--square"
                    :class="{
                        'cs--red': $store.getters.userMediaActive && $store.state.userMedia.axes.includes(axisTag),
                        glow: $store.getters.userMediaActive && $store.state.userMedia.axes.includes(axisTag),
                    }"
                    @click="$store.dispatch('toggleAxisMic', axis)"
                >
                    <span class="icon xl">&#127908;</span>
                </button>
            </div>
        </div>

        <div class="flex jc-sb ai-c">
            <button class="button button--square" @click="$store.dispatch('toggleAnimateAxis', axisTag)">
                <span v-if="animating" class="icon">&#xf04c;</span>
                <span v-else class="icon">&#xf04b;</span>
            </button>
            <div style="flex: 1 1 100%; padding-left: 4px">
                <input-range
                    :min="minDefault"
                    :max="maxDefault"
                    :value="value"
                    :crop="true"
                    :step="null"
                    :decimals="getAxisDecimalPlaces(axis)"
                    :minCrop="cropMin"
                    :maxCrop="cropMax"
                    @valueChange="
                        (value) =>
                            $store.dispatch('updateAxisValueFromSlider', {
                                axis,
                                value,
                            })
                    "
                    @minChange="
                        (value) =>
                            $store.commit('setAxisMin', {
                                axisTag,
                                value,
                            })
                    "
                    @maxChange="
                        (value) =>
                            $store.commit('setAxisMax', {
                                axisTag,
                                value,
                            })
                    "
                >
                </input-range>
            </div>
        </div>
    </div>
</template>

<script>
import easingFunctions from "@/data/easingFunctions"
import InputRange from "@/components/Generic/InputRange.vue"
import animationSpeeds from "@/data/animationSpeeds"

export default {
    name: "axes-axis",
    props: ["axisTag", "axis", "mic"],
    data() {
        return {
            animationSpeeds,
            easingFunctions,
        }
    },
    components: {
        InputRange,
    },
    computed: {
        // By default, we try to use the values from the axis object located in the tab data itself
        // For any value that is not defined, we use the fontSources axes data as a fallback

        FontAxis: function () {
            return this.$store.getters.font.axes[this.axisTag] || {}
        },

        easing: function () {
            return this.axis.easing || "none"
        },

        animating: function () {
            return this.axis.animating || false
        },

        speed: function () {
            return this.axis.speed != null ? this.axis.speed : 1
        },

        value: function () {
            return this.axis.value != null ? this.axis.value : this.FontAxis.defaultValue // We must check == null, as 0 == null
        },

        minDefault: function () {
            return this.axis.minDefault != null ? this.axis.minDefault : this.FontAxis.minDefault
        },

        maxDefault: function () {
            return this.axis.maxDefault != null ? this.axis.maxDefault : this.FontAxis.maxDefault
        },

        cropMin: function () {
            return this.axis.min != null ? this.axis.min : this.FontAxis.minDefault
        },

        cropMax: function () {
            return this.axis.max != null ? this.axis.max : this.FontAxis.maxDefault
        },
    },
    methods: {
        getAxisDecimalPlaces(axis) {
            let range = axis.maxDefault - axis.minDefault
            if (range < 10) return 2
            if (range < 20) return 1
            return 0
        },
    },
}
</script>

<style lang="scss" scoped></style>
