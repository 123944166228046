<template>
    <div>
        <span v-if="$store.getters.font == null">Your OpenType features will show up here</span>
        <div v-else-if="features.length > 0" class="features-list">
            <div
                v-for="(feature, f) in features"
                :key="f"
                class="features-list__row flex rw ai-c"
                @click="(e) => $store.commit('toggleTabFontFeatureSettingsValue', feature.tag)"
            >
                <span v-if="isOpenTypeFeatureIncludedInTab(feature)" class="icon active">&#61770; </span>
                <span v-else class="icon">&#62163;</span>
                <span class="name text-overflow-ellipsis">&nbsp;{{ feature.name }}</span>
                <span class="tag" :class="{ 'tag--ss': feature.tag.indexOf('ss') > -1 }">{{ feature.tag }}</span>
            </div>
            <!--             <label v-for="(feature, f) in features" :key="f" class="flex rw ai-c">
                <input
                    type="checkbox"
                    name=""
                    :checked="isOpenTypeFeatureIncludedInTab(feature)"
                    @click="(e) => $store.commit('toggleTabFontFeatureSettingsValue', feature.tag)"
                />
                <span class="name text-overflow-ellipsis">{{ feature.name }}</span>
                <span class="tag" :class="{ 'tag--ss': feature.tag.indexOf('ss') > -1 }">{{ feature.tag }}</span>
                <br />
            </label> -->
        </div>
        <span v-else>Your font has no OpenType features</span>
    </div>
</template>

<script>
export default {
    name: "features",
    computed: {
        features: function () {
            let featuresSS = []
            let features = []
            for (let feature of this.$store.getters.fontFeatures) {
                if (!["kern"].includes(feature.tag)) {
                    if (feature.tag.indexOf("ss") > -1) featuresSS.push(feature)
                    else features.push(feature)
                }
            }

            featuresSS = featuresSS.concat(features)
            return featuresSS
        },
    },
    methods: {
        isOpenTypeFeatureIncludedInTab(feature) {
            if (this.$store.getters.fg.fontFeatureSettings == null) return false
            else return this.$store.getters.fg.fontFeatureSettings.includes(feature.tag)
        },
    },
}
</script>

<style lang="scss" scoped>
.features-list {
    display: grid;
    grid-auto-rows: 1fr;
    row-gap: calc(var(--padding) * 0.3);

    &__row {
        overflow: hidden;

        .icon {
            line-height: 1;
            color: var(--color-border);

            &.active {
                color: var(--color--darkest);
            }
        }

        .name {
            flex: 1 1 auto;
        }

        .tag {
            margin-left: calc(var(--padding) * 0.75);
            flex: 0 0 auto;
            color: var(--color-border);

            &--ss {
                color: var(--color--green---mid);
            }
        }
    }
}
</style>
