import config from "@/config"

export default {
    get(path) {
        return new Promise((resolve, reject) => {
            let req = new XMLHttpRequest()
            req.addEventListener("error", (e) => console.log(e))
            req.addEventListener("load", (e) => {
                if (req.status !== 200) reject()
                let response = JSON.parse(e.target.response)
                if (response.err) reject()
                resolve(response)
            })
            req.open("GET", `${config.API}${path}`)
            req.send()
        })
    },

    post(path, data = {}) {
        return new Promise((resolve, reject) => {
            fetch(`${config.API}${path}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.err) reject()
                    resolve(data)
                })
                .catch((error) => {
                    console.error(error)
                    reject()
                })
        })
    },

    downloadInstanceAtAxisPosition(fontFile, axes) {
        return new Promise((resolve, reject) => {
            let args = axes

            let formData = new FormData()
            formData.append("font", fontFile)
            formData.append("args", JSON.stringify(args))

            let req = new XMLHttpRequest()

            req.addEventListener("error", (e) => console.log(e))
            req.addEventListener("load", (e) => {
                if (req.status !== 200) reject(`There was a problem generating the instance`)
                let response = JSON.parse(e.target.response)
                if (response.err) reject(`There was a problem generating the instance`)
                resolve(response)
            })

            req.open("POST", `${config.API}/gx/instance`)
            req.send(formData)
        })
    },
}
