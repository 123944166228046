import api from "@/misc/api"
import store from "@/store/index"

export default {
    changeTextToSet(context, setName) {
        if (context.getters.tab == null) return
        let newText = ""
        if (setName === "all") {
            let text = ""
            // Exclude: CR
            let excludedCodes = [13]
            for (let glyph of store.getters.font.glyphs) {
                if (glyph.unicode == null) continue
                if (excludedCodes.indexOf(glyph.code) !== -1) continue

                text += glyph.unicode
                for (let featureTag of glyph.features) {
                    if (featureTag.includes("ss") || ["onum"].includes(featureTag)) {
                        text += `<span style="font-feature-settings: '${featureTag}'">${glyph.unicode}</span>`
                    }
                }
            }
            newText = text
        } else {
            newText = context.state.textSets[setName].text
        }
        context.dispatch("forceUpdateTabTextValue", newText)
    },

    setTextFromApiPath(context, apiPath, exclude = null) {
        if (context.getters.tab == null) return
        if (exclude == null) exclude = [store.getters.tab.data.text]
        return new Promise((resolve) => {
            api.post(apiPath, { exclude }).then((res) => {
                context.dispatch("forceUpdateTabTextValue", res.text)
                resolve(res.text)
            })
        })
    },
}
