import db from "@/db"
import utils from "@/misc/utils"
import {
    getFontFullName,
    loadFontFromBuffer,
    loadFontFromFile,
    getSupportedCharCodes,
    getInstancesFromFont,
    getAxesFromFont,
    getOpenTypeFeatures,
    getGlyphs,
} from "@/misc/lib-font-utils"
import { createTabText } from "@/store/defaults/tabText"

export default {
    async newSessionWithFonts(context, data) {
        let session = db.createSession()
        context.dispatch("addTabToSession", session)

        let fontSources = []
        for (let Font of data.fonts) {
            let fontSource = await context.dispatch("createFontSource", Font)
            fontSource.sessionId = session.id
            fontSources.push(fontSource)
        }

        context.dispatch("loadFontSourcesForSession", { session, fontSources })

        await context.dispatch("openSession", session)
    },

    createFontSource(context, Font) {
        let fontSource = db.createFont()
        fontSource.buffer = Font.fontData.buffer
        fontSource.Font = Font
        if (Font.fileHandle != null) fontSource.fileHandle = Font.fileHandle
        if (Font.file != null) fontSource.file = Font.file
        if (Font.customName != null) fontSource.name = Font.customName
        if (Font.filename != null) fontSource.filename = Font.filename
        return fontSource
    },

    async replaceFontInSession(context, Font) {
        let fontToReplace = await context.getters.getFirstFontForSession(context.state.session)
        let fontSource = await context.dispatch("createFontSource", Font)
        let font = await context.dispatch("loadFontSource", { session: context.state.session, fontSource })
        for (let tab of context.getters.tabs) {
            await context.dispatch("addFontToTab", { tab, font })
            context.dispatch("selectTabFont", { tab, font })
        }

        context.dispatch("deleteFontFromSession", fontToReplace)
    },

    addTabToSession(context, session) {
        let tab = createTabText()
        session.tabs.push(tab)
        session.tab = tab.id // New tabs are always shown
    },

    addFontToTab(context, data) {
        data.tab.fonts.push(data.font.id)
        data.tab.fontData[data.font.id] = {
            axes: context.getters.getFontAxesDefaultStates(data.font),
        }
        if (data.tab.selectedFonts.length === 0) context.dispatch("selectTabFont", data)
    },

    deleteFontFromTab(context, data) {
        context.commit("deleteFontFromTab", data)
    },

    deleteFontFromSession(context, font) {
        for (let tab of context.getters.tabs) {
            context.dispatch("deleteFontFromTab", { tab, font })
        }
    },

    selectTabFont(context, data) {
        for (let font of data.tab.selectedFonts) if (data.font.id === font.id) return
        data.tab.selectedFonts = [data.font.id]
        // data.tab.selectedFonts.push(data.font.id)
    },

    async openSession(context, session) {
        setTimeout(async () => {
            await context.commit("setCurrentSession", session)
            context.dispatch("saveSession")
            await context.dispatch("deleteOldFonts")
            setTimeout(() => context.dispatch("checkForSessionFontHandlerUpdates"), 750)
        }, 120)
    },

    checkForSessionFontHandlerUpdates(context) {
        if (!context.state.window.fileSystemAccess) return
        if (context.state.session == null || context.state.fonts.length == 0) return
        for (let font of context.state.fonts) {
            if (font.fileHandle != null) context.dispatch("checkFontHandlerUpdates", font)
        }
        setTimeout(() => context.dispatch("checkForSessionFontHandlerUpdates"), 750)
    },

    async checkFontHandlerUpdates(context, font) {
        if (font.file == null || font.fileHandle == null) return
        const file = await font.fileHandle.getFile()

        // This is a big hack for now
        // We assume a user will only ever use one font file
        // So if a single font file is changed, we create a whole new session with it
        // In the future, we'll need an elegant way to update a single font without having to create a new session
        // if (file.lastModified > font.file.lastModified) context.dispatch("openFontFromFile", { file })

        if (file.lastModified > font.file.lastModified) {
            const newFont = await loadFontFromFile(file)
            if (newFont != null) {
                font.file = file
                font.Font = newFont
                context.dispatch("updateFont", font)
            }
        }
    },

    saveSession(context) {
        db.saveSession()
        context.state.lastSessionExists = true
    },

    openLastSession(context) {
        context.dispatch("openSessionPre")
        db.getLastSession().then(async (session) => {
            let fontSources = await db.getFontsForSession(session)
            context.dispatch("loadFontSourcesForSession", { fontSources, session })
            context.dispatch("openSession", session)
        })
    },

    async loadFontSourcesForSession(context, data) {
        for (let fontSource of data.fontSources) {
            // We have to await as the fonts are needed for the next step
            await context.dispatch("loadFontSource", { fontSource, session: data.session })
        }

        for (let tab of data.session.tabs) {
            if (tab.fonts.length === 0)
                context.dispatch("addFontToTab", { tab, font: context.getters.getFirstFontForSession(data.session) })
        }
    },

    async loadFontSourceForSession(context, data) {
        let font = await context.dispatch("loadFontSource", data)
        if (font == null) return
    },

    async loadFontSource(context, data) {
        let Font = data.fontSource.Font

        // Load the Font class with lib-font,
        // if it was not already supplied
        if (Font == null) {
            Font = await loadFontFromBuffer(data.fontSource.buffer).catch(() => console.log("openFontError"))
            if (Font == null) return null
        }

        let font = {
            name: data.fontSource.name || null,
            filename: data.fontSource.filename || null,
            file: data.fontSource.file || null,
            fileHandle: data.fontSource.fileHandle || null,
            sessionId: data.session.id,
            Font: Font,
            id: data.fontSource.id,
        }

        await context.dispatch("updateFont", font)

        // Delete temporary Font objects
        if ("Font" in data.fontSource) delete data.fontSource["Font"]

        context.state.fonts.push(font)
        db.saveFont(data.fontSource)

        return font
    },

    async updateFont(context, font) {
        // We can already load the font onto the page,
        // Use Font ID as family-name
        let fontFaceId = utils.uid()
        utils.addBase64FontToDocumentStyle(fontFaceId, utils.arrayBufferToBase64(font.Font.fontData.buffer))

        font.fontFaceId = fontFaceId
        font.buffer = font.Font.fontData.buffer
        font.glyphs = getGlyphs(font.Font)
        font.supportedChars = getSupportedCharCodes(font.Font)
        font.instances = getInstancesFromFont(font.Font)
        font.axes = getAxesFromFont(font.Font)
        font.features = getOpenTypeFeatures(font.Font)
        font.variable = !!font.Font.opentype.tables.fvar

        if (font.name == null) font.name = getFontFullName(font.Font)
    },

    closeSession(context) {
        context.commit("closeSession")
    },

    deleteOldFonts(context) {
        context.state.fonts = context.state.fonts.filter((font) => font.sessionId === context.state.session.id)
        db.deleteOldFonts(context.state.session)
    },
}
