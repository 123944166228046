import store from "@/store"

store.commit("setOnline", window.navigator.onLine)

window.addEventListener("offline", function () {
    store.commit("setOnline", false)
})

window.addEventListener("online", function () {
    store.commit("setOnline", true)
})
