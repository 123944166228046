import { createStore } from "vuex"
import config from "@/config.js"
import actions from "@/store/actions"
import getters from "@/store/getters"
import mutations from "@/store/mutations"
import defaultWindow from "@/store/defaults/window"
import db from "@/db"

export default createStore({
    state: {
        config: config,

        fonts: [],

        notifications: [],
        downloadingInstance: false,
        instanceGenerationDisabled: false,

        // The window state
        window: defaultWindow,

        // Sidebar
        sidebar: {
            open: true,
            width: 260,
        },

        lastSessionExists: false,
        session: null,

        user: {
            // The data that needs to be fetch/saved only
            data: db.getUser(),
        },

        preferences: db.getPreferences(),

        settings: {
            presentationMode: false,
            mouseMode: false,
        },

        defaults: {
            fontSize: {
                default: 150,
                min: 7,
                max: 800,
            },
            lineHeight: {
                default: 1,
                min: 0.7,
                max: 1.7,
            },
            letterSpacing: {
                default: 0,
                min: -100,
                max: 100,
            },
        },

        // The panel currently open
        panel: null,

        // The current dashboard tab
        dashboardTabName: "settings",
        fontPanelTabName: "info",

        // Tasks which are running
        currentTasks: {},

        // Microphone Stream
        userMedia: {
            stream: null,
            axes: [],
            analyser: null,
        },

        notificationsShown: [],

        // Current Glyphs Search String
        glyphsSearch: null,

        // Misc
        online: true,
        standalone: window.matchMedia("(display-mode: standalone)").matches,

        dev: config.APP_ENV === "development",
        server: true,

        // File System Access
    },
    actions,
    getters,
    mutations,
})
