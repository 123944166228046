<template>
    <div class="text-view flex cl" :style="getBackgroundStyle()" v-on:drop="(e) => $emit('dropHandler', e)">
        <div class="text-view__body custom-scrollbar">
            <div
                class="text-view__body__inner"
                :class="{
                    'text-view__body__inner--center-mode': $store.getters.fg.centerMode,
                    'user-media-active': $store.getters.userMediaActive,
                }"
            >
                <text-waterfall
                    v-if="tab.viewMode === 'waterfall'"
                    :text="text"
                    :tab="tab"
                    :style="getBodyStyleBase()"
                ></text-waterfall>
                <style-range
                    v-else-if="tab.viewMode === 'style-range'"
                    :tab="tab"
                    :text="text"
                    :liveText="liveText"
                    :style="getBodyStyle()"
                ></style-range>
                <glyphs-overview
                    v-else-if="tab.viewMode === 'glyphs'"
                    :tab="tab"
                    :style="getBodyStyle()"
                ></glyphs-overview>
                <text-default
                    v-else
                    :tab="tab"
                    :style="getBodyStyle()"
                    :text="text"
                    :liveText="liveText"
                ></text-default>
            </div>
        </div>

        <canvas
            id="text-view__canvas"
            style="
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 10000;
                width: 100%;
                height: 100%;
                opacity: 0.5;
                pointer-events: none;
            "
        ></canvas>

        <text-view-bar></text-view-bar>

        <cinema-controls
            v-if="$store.getters.isFontVariable && $store.state.settings.presentationMode"
        ></cinema-controls>
    </div>
</template>

<script>
import utils from "@/misc/utils"
import TextViewBar from "./TextViewBar.vue"
import CinemaControls from "./CinemaControls.vue"
import TextWaterfall from "./Views/TextWaterfall.vue"
import TextDefault from "./Views/TextDefault.vue"
import StyleRange from "./Views/StyleRange.vue"
import GlyphsOverview from "./Views/GlyphsOverview.vue"

export default {
    name: "body-text-view",
    props: ["tab", "data"],
    components: {
        CinemaControls,
        TextViewBar,
        TextDefault,
        TextWaterfall,
        StyleRange,
        GlyphsOverview,
    },
    computed: {
        fg: function () {
            return this.tab.data.style.fg
        },

        text: function () {
            return {
                id: utils.uid(),
                text: this.tab.data.text,
            }
        },

        liveText: function () {
            return {
                id: utils.uid(),
                text: this.tab.data.liveText,
            }
        },
    },
    methods: {
        async toggleWaterfallMode() {
            this.$store.dispatch("toggleTabDataValue", { name: "waterfallMode" })
        },

        async toggleAutoSizeText() {
            await this.$store.dispatch("toggleTabDataValue", { name: "autoSizeText" })
            if (this.tab.data.autoSizeText) this.$store.dispatch("autoSizeText")
        },

        getTextAlign() {
            if (this.fg.centerMode) return "center"
            if (this.fg.direction === "rtl") {
                if (this.fg.textAlign === "left") return ""
            }
            return this.fg.textAlign
        },

        getBodyStyle() {
            let bodyStyle = this.getBodyStyleBase()
            bodyStyle.lineHeight = this.fg.lineHeight
            bodyStyle.fontSize = `${this.fg.fontSize}px`
            return bodyStyle
        },

        getBodyStyleBase() {
            let bodyStyle = {
                fontFamily: `"${this.$store.getters.fontFaceId}"`,
                fontFeatureSettings: utils.getOpentypeFeaturesArrayAsCssString(this.fg.fontFeatureSettings),
                fontVariationSettings: this.$store.getters.fontVariationSettings,
                letterSpacing: `${this.fg.letterSpacing}em`,
                color: this.fg.color,
                textTransform: this.fg.textTransform,
            }

            if (this.$store.getters.viewMode !== "glyphs") {
                bodyStyle.textAlign = this.getTextAlign()
                bodyStyle.direction = this.fg.direction == null ? "ltr" : this.fg.direction
            }

            const featuresToDisable = ["liga", "calt", "rlig", "kern", "rvrn", "rclt"]
            for (let featureTag of featuresToDisable) {
                if (!this.fg.fontFeatureSettings.includes(featureTag)) {
                    let prefix = bodyStyle.fontFeatureSettings == "" ? "" : ", "
                    bodyStyle.fontFeatureSettings += `${prefix}"${featureTag}" 0`
                }
            }

            return bodyStyle
        },

        getBackgroundStyle() {
            let bodyStyle = {
                backgroundColor: this.tab.data.style.bg.backgroundColor,
            }

            return bodyStyle
        },
    },
}
</script>

<style lang="scss" scoped>
.text-view {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;

    &__body {
        flex: 1 1 100%;
        height: 100%;
        width: 100%;
        overflow: hidden;
        overflow-y: auto;
        position: relative;
        box-sizing: border-box;

        &::-webkit-scrollbar-track {
            margin-bottom: var(--bar--height); // To account for the bar on the bottom of the screen
        }

        &__inner {
            min-height: 95%; // So that a single centred line of text is optically centred
            width: 100%;
            padding-bottom: var(--bar--height); // To account for the bar on the bottom of the screen

            &--center-mode {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
}
</style>
<style lang="scss">
.user-media-active .font-text {
    transition: font-variation-settings 0.1s ease;
}
</style>
