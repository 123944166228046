<template>
    <panel :tabs="tabs" :showTitleBar="true"></panel>
</template>

<script>
import { markRaw } from "vue"
import Panel from "@/components/Generic/Panel/Panel.vue"
import Source from "./Source.vue"
import Info from "./Info.vue"

export default {
    name: "font",
    data() {
        return {
            tabs: [markRaw(Info), markRaw(Source)],
        }
    },
    components: {
        Panel,
    },
}
</script>

<style lang="scss" scoped></style>
