import utils from "@/misc/utils"

export default {
    startAudioStream() {
        return new Promise((resolve, reject) => {
            navigator.mediaDevices
                .getUserMedia({ audio: true, video: false })
                .then((stream) => resolve(stream))
                .catch((e) => reject(e))
        })
    },

    stopAudioStream(stream) {
        stream.getTracks().forEach((track) => track.stop())
    },

    getAnalyserForStream(stream) {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)()
        const source = audioContext.createMediaStreamSource(stream)
        let analyser = audioContext.createAnalyser()
        analyser.fftSize = 2048
        analyser.smoothingTimeConstant = 0.4
        source.connect(analyser)
        return analyser
    },

    getVolumeFromAnalyser(analyser) {
        let bufferLength = analyser.frequencyBinCount
        let dataArray = new Uint8Array(bufferLength)
        analyser.getByteFrequencyData(dataArray)
        let volume = utils.getRms(dataArray)
        return volume
    },
}
