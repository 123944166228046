import userMedia from "@/services/userMedia"

export default {
    resetUserMedia(context) {
        if (context.state.userMedia.stream == null) return
        userMedia.stopAudioStream(context.state.userMedia.stream)
        context.commit("setUserMediaStream", null)
        context.commit("setUserMediaAxes", [])
    },

    toggleMicrophoneAll(context) {
        if (context.state.userMedia.stream == null) {
            context.commit("setUserMediaAxes", Object.keys(context.getters.fontAxes))
            context.dispatch("startMic")
        } else {
            context.dispatch("stopMic")
        }
    },

    async startMic(context) {
        if (context.state.userMedia.stream != null) return
        let stream = await userMedia.startAudioStream()
        context.commit("setUserMediaStream", stream)
        context.dispatch("startMicrophoneSuccess")
    },

    stopMic(context) {
        context.dispatch("resetUserMedia")
    },

    startMicrophoneSuccess(context) {
        const analyser = userMedia.getAnalyserForStream(context.state.userMedia.stream)
        context.commit("setUserMediaAnalyser", analyser)
        context.dispatch("stopAnimation")
        context.dispatch("stopMouseMode")

        // Show a warning for animating large amounts of text
        if (context.getters.tab.data.text.length > 50) {
            context.dispatch("showNotification", {
                name: "animating-long-texts",
                once: true,
                text: `Animating long texts causes lag`,
                status: "warning",
            })
        }

        context.dispatch("microphoneFrame")
    },

    microphoneFrame(context) {
        if (context.state.userMedia.stream == null) return
        let volume = userMedia.getVolumeFromAnalyser(context.state.userMedia.analyser)
        context.dispatch("animateFrameMicrophone", { volume, axes: context.state.userMedia.axes })
        setTimeout(() => window.requestAnimationFrame(() => context.dispatch("microphoneFrame")), 1000 / 60)
    },

    async toggleAxisMic(context, axis) {
        if (context.state.userMedia.axes.includes(axis.tag)) await context.dispatch("stopAxisMic", axis)
        else await context.commit("pushUserMediaAxes", axis.tag)
        context.dispatch("afterMicUpdate")
    },

    stopAxisMic(context, axis) {
        if (context.state.userMedia.axes.includes(axis.tag))
            context.commit(
                "setUserMediaAxes",
                context.state.userMedia.axes.filter((tag) => tag != axis.tag)
            )
        context.dispatch("afterMicUpdate")
    },

    afterMicUpdate(context) {
        if (context.state.userMedia.axes.length === 0) context.dispatch("stopMic")
        else context.dispatch("startMic")
    },
}
