import store from "@/store/index"

export default {
    setAxisValue(state, data) {
        data.axis.value = data.value
    },

    setAxisAnimating(state, data) {
        data.axis.animating = data.animating
    },

    stopAxesAnimating() {
        // eslint-disable-next-line no-unused-vars
        for (const [axisTag, axis] of Object.entries(this.getters.axes)) axis.animating = false
    },

    startAxesAnimating() {
        // eslint-disable-next-line no-unused-vars
        for (const [axisTag, axis] of Object.entries(this.getters.axes)) axis.animating = true
    },

    resetAxisAnimatingCrop(state, axisTag) {
        store.getters.axes[axisTag].min = store.getters.axes[axisTag].minDefault
        store.getters.axes[axisTag].max = store.getters.axes[axisTag].maxDefault
    },

    toggleAxisAnimating(state, axisTag) {
        store.getters.axes[axisTag].animating = !store.getters.axes[axisTag].animating
    },

    setAxisSpeed(state, data) {
        store.getters.axes[data.axisTag].speed = data.speed
    },

    stopAxisAnimate(state, data) {
        store.getters.axes[data.axisTag].animating = false
    },

    setAxisTime(state, data) {
        store.getters.axes[data.axisTag].t = data.t
    },

    setAxisMin(state, data) {
        store.getters.axes[data.axisTag].min = data.value
    },

    setAxisMax(state, data) {
        store.getters.axes[data.axisTag].max = data.value
    },

    setAxisEasing(state, data) {
        store.getters.axes[data.axisTag].easing = data.easing
    },

    swapAnimatingAxisDirection(state, axisTag) {
        store.getters.axes[axisTag].direction = store.getters.axes[axisTag].direction * -1
    },
}
