<template>
    <div
        class="font-text text-no-child-formatting"
        :style="style"
        :id="id"
        ref="text"
        @keydown="(e) => $emit('keydown', e)"
        @input="(e) => $emit('input', e)"
        @blur="(e) => $emit('blur', e)"
        @paste="paste"
        contenteditable="true"
        spellcheck="false"
        :key="text.id"
        v-html="text.text"
    ></div>
</template>

<script>
export default {
    name: "font-text",
    props: ["text", "style", "keydown", "input", "blur", "id"],
    methods: {
        paste(e) {
            e.preventDefault()
            let unformattedText = e.clipboardData.getData("Text")
            document.execCommand("insertText", false, unformattedText)
        },
    },
}
</script>

<style lang="scss" scoped></style>
