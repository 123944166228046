<template>
    <div v-if="changelog">
        <p>
            Last Updated {{ $store.getters.lastUpdated }} (V{{ $store.state.config.VERSION }},
            {{ utils.formatDate($store.state.config.BUILD_DATE) }})<br /><br /><br />
        </p>

        <p v-if="$store.getters.userNewVersionVisit" class="big changelog__new">
            Font Gauntlet has been updated since your last visit.<br /><br />
        </p>

        <template v-for="(update, i) in changelog" :key="i">
            <h4>
                V{{ update.version }} ({{ update.date }})<sup
                    v-if="i === 0 && $store.state.user.newVersionVisit"
                    class="changelog__new"
                    >&nbsp;NEW</sup
                >
            </h4>
            <ul>
                <li v-for="(note, n) in update.notes" :key="n">{{ note }}</li>
            </ul>
            <br />
        </template>
    </div>
</template>

<script>
import api from "@/misc/api"
import utils from "@/misc/utils"

export default {
    name: "changelog",
    title: "Changelog",
    data() {
        return {
            changelog: [],
            utils,
        }
    },
    methods: {
        close() {
            this.$store.commit("updateLastVersionUsed")
        },
    },
    created() {
        api.get("/changelog").then((res) => {
            this.changelog = res.data
        })
    },
}
</script>

<style lang="scss" scoped>
.changelog {
    &__new {
        color: #00a200;
    }
}
</style>
