export default {
    introMessage(VERSION, APP_ID, NODE_ENV, standalone, BUILD_DATE) {
        console.log(
            `%cDINAMO%c FONT GAUNTLET%c V${VERSION}`,
            "color: red; font-family: Helvetica, Arial, sans-serif; Segoe Script; font-size: 22px;",
            "color: blue; font-family: Apple Chancery, Apple-Chancery, Segoe Script; font-size: 22px;",
            ""
        )
        console.log(
            `%cApp ID: %c${APP_ID}, %cENV: %c${NODE_ENV}, %cStandalone: %c${standalone}, %cBuild Date: %c${BUILD_DATE}`,
            "color: red;",
            "color: inherit;",
            "color: red;",
            "color: inherit;",
            "color: red;",
            "color: inherit;",
            "color: red;",
            "color: inherit;"
        )
    },

    log(message) {
        console.log(`%c[FONT GAUNTLET]%c ` + message, "color: gold;", "")
    },

    error(message) {
        console.log(`%c[FONT GAUNTLET]%c ` + message, "color: red;", "")
    },

    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min
    },

    arrayBufferToBase64(buffer) {
        let binary = ""
        let bytes = new Uint8Array(buffer)
        let len = bytes.byteLength
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i])
        }
        return window.btoa(binary)
    },

    addBase64FontToDocumentStyle(fontName, base64String) {
        let newStyle = document.createElement("style")
        newStyle.appendChild(
            document.createTextNode(
                `@font-face { font-family: "${fontName}"; src: url("data:application/x-font-woff2;base64,${base64String}"); } `
            )
        )
        document.head.appendChild(newStyle)
    },

    // https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
    uid() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
            (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
        )
    },

    uidShort() {
        var dt = new Date().getTime()
        var uuid = "xxxxxx".replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0
            dt = Math.floor(dt / 16)
            return (c == "x" ? r : (r & 0x3) | 0x8).toString(16)
        })
        return uuid
    },

    downloadBase64File(filename, file) {
        let downloadLink = document.getElementById("download")
        downloadLink.setAttribute("href", "data:text/plain;base64," + file)
        downloadLink.setAttribute("download", filename)
        downloadLink.click()
    },

    stringStripHtml(s) {
        return s.replace(/<[^<>]+>/gm, "")
    },

    formatDate(date) {
        let months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ]
        return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`
    },

    versionIsNewNoPatch(oldVersion, newVersion) {
        if (oldVersion == null || newVersion == null) return false
        let oldVersionSplit = oldVersion.split(".")
        let newVersionSplit = newVersion.split(".")
        if (parseInt(newVersionSplit[0]) > parseInt(oldVersionSplit[0])) return true
        if (parseInt(newVersionSplit[1]) > parseInt(oldVersionSplit[1])) return true
        return false
    },

    buildIsNew(oldBuildDate, newBuildDate) {
        if (oldBuildDate == null) return true
        if (newBuildDate == null) return false
        if (newBuildDate > oldBuildDate) return true
        return false
    },

    featureArrayToCSSString(features, quote) {
        let fontFeatureSettings = []
        for (let featureName of features) {
            fontFeatureSettings.push(`${quote == null ? `"` : quote}${featureName}${quote == null ? `"` : quote}`)
        }
        return fontFeatureSettings.join(", ")
    },

    decToHex(number) {
        let hexString = number.toString(16)
        if (hexString.length === 3) {
            hexString = "0" + hexString
        }
        if (hexString.length === 2) {
            hexString = "00" + hexString
        }
        return hexString
    },

    decToHTMLHex(number) {
        let hexString = number.toString(16)
        if (hexString.length === 3) hexString = "0" + hexString
        if (hexString.length === 2) hexString = "00" + hexString
        return `&#${hexString};`
    },

    decToHTMLUnicode(dec) {
        return `&#${dec};`
    },

    getOpentypeFeaturesArrayAsCssString(features) {
        let fontFeatureSettings = ""
        for (let featureName of features) {
            if (fontFeatureSettings !== "") fontFeatureSettings += ", "
            fontFeatureSettings += `"${featureName}" 1`
        }
        return fontFeatureSettings
    },

    getFilenameFromAxisPosition(axes, familyName) {
        let argsFilename = ""
        for (let axisSlug in axes)
            argsFilename += `${axisSlug}${axes[axisSlug].value.toFixed(
                this.getAutoDecimalPlaces(axes[axisSlug].value)
            )}`
        return familyName.split(" ").join("") + "-" + argsFilename + ".ttf"
    },

    getFilenameFromInstance(instance, familyName) {
        return familyName.split(" ").join("") + "-" + instance.name.split(" ").join("") + ".ttf"
    },

    getAutoDecimalPlaces(value) {
        if (value < 10) return 2
        if (value < 20) return 1
        return 0
    },

    timeSince(date) {
        // date = new Date("January 21 2021 16:00:00")
        const now = new Date()
        let diffSeconds = Math.floor((now - date) / 1000)
        let diffHours = Math.floor(diffSeconds / 60 / 60)

        // Today or Yesterday
        if (diffHours <= 24) return now.getHours() - diffHours < 0 ? "Yesterday" : "today"

        let diffDays = Math.floor(diffHours / 24)

        // Week
        // Check this before year and month,
        // As this transcends years and months
        if (diffDays < now.getDay() + 7) return now.getDay() - diffDays > 0 ? "this week" : "last week"

        // This Month
        if (date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth()) return "this month"

        // Last month
        if (date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth() - 1) return "last month"
        // Edge case for January to December
        if (date.getFullYear() === now.getFullYear() - 1 && date.getMonth() === 11 && now.getMonth() === 0)
            return "last month"

        // Year
        if (date.getFullYear() !== now.getFullYear()) {
            let diff = now.getFullYear() - date.getFullYear()
            return diff > 1 ? diff + " years ago" : "last year"
        }

        // Month
        if (date.getMonth() !== now.getMonth()) {
            let diff = now.getMonth() - date.getMonth()
            return diff > 1 ? diff + " months ago" : "last month"
        }
    },

    formatVersionShort(version) {
        let versionSplit = version.split(".")
        return `${versionSplit[0]}.${versionSplit[1]}`
    },

    getRms(input) {
        let len = input.length
        let i = 0
        let total = 0
        while (i < len) total += Math.abs(input[i++])
        return Math.sqrt(total / len) / 10
    },

    getAverageVolume(array) {
        let values = 0
        let length = array.length
        for (let i = 0; i < length; i++) values += array[i]
        let average = values / length
        return average / 100
    },
}
