<template>
    <div class="cinema-controls" :class="{ 'cinema-controls--active': show }">
        <div
            class="cinema-controls__large"
            :class="{
                'cinema-controls__large--compact': Object.keys($store.getters.axes).length < 2,
            }"
        >
            <button @click="$store.dispatch('toggleAllAxisAnimating')">
                <template v-if="$store.getters.animating">
                    <span class="icon">&#xf04c;</span>
                </template>
                <template v-else>
                    <span class="icon">&#xf04b;</span>
                </template>
            </button>

            <button @click="$store.dispatch('resetAllAxes', $store.state.font)">
                <span class="icon">&#xf022;</span>
            </button>

            <button
                @click="$store.dispatch('toggleMicrophoneAll')"
                :class="{ 'cs--red': $store.getters.userMediaActive, 'glow': $store.getters.userMediaActive }"
            >
                <span class="icon">&#127908;</span>
            </button>
        </div>
        <div style="flex: 1 1 100%" class="cinema-controls__axes">
            <axes></axes>
        </div>
    </div>
</template>

<script>
import Axes from "@/components/Sidebar/Modules/Axes.vue"

export default {
    name: "cinema-controls",
    components: {
        Axes,
    },
    data() {
        return {
            show: true,
        }
    },
    created() {
        window.addEventListener("mousemove", (e) => {
            this.show = window.innerHeight - e.clientY < 150
        })
    },
}
</script>

<style lang="scss" scoped>
.cinema-controls {
    position: fixed;
    bottom: var(--padding);
    background-color: var(--color--light);
    border-radius: calc(var(--padding) * 1.5);
    left: 50%;
    padding: calc(var(--padding) * 1.5);
    transform: translateX(-50%) translateY(100%);
    transition: transform 0.3s 0.3s ease, background-color 0.3s 0.45s ease;

    display: flex;
    flex-direction: row;

    &__large {
        padding-right: calc(var(--padding) * 3);
        display: grid;
        grid-template-rows: repeat(1fr);
        gap: calc(var(--padding) * 0.75);
        align-content: center;

        &--compact {
            grid-template-columns: repeat(3, 1fr);
        }

        button {
            font-size: 30px;
            width: 70px;
            height: 70px;
            border-radius: 50%;

            span {
                font-size: 30px;
            }
        }
    }

    &__axes {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 8px;
        width: 340px;
    }

    &:hover,
    &--active {
        background-color: var(--color--lighter);
        transform: translateX(-50%) translateY(0%);
        transition: transform 0.3s 0s ease, background-color 0.3s ease;
    }

    &:deep(.input-range__marker--thumb) {
        border-color: var(--color--lighter) !important;
    }

    &:deep(.sidebar__sub-section.instance),
    &:deep(.variation-axis__control__tage),
    &:deep(.sidebar__sub-section.all-axes) {
        display: none;
    }

    &:deep(.variation-axis__control) {
        margin-bottom: calc(var(--padding) * 1.5);
    }
}
</style>
