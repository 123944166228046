<template>
    <div class="text-waterfall">
        <div
            class="text-waterfall__row"
            :class="{ 'text-waterfall__row--rtl': $store.getters.fg.direction === 'rtl' }"
            v-for="i in fontSizes"
            :key="i"
        >
            <div class="text-waterfall__row__label">{{ i }}px</div>
            <div class="text-waterfall__row__text">
                <font-text
                    :text="formatWaterfallText(text)"
                    :style="{
                        ...style,
                        'font-size': `${i}px`,
                        'line-height': getWaterfallRowLineHeight(i),
                    }"
                    @keydown="handleWaterfallKeydown"
                    @input="handleWaterfallLiveInput"
                    @blur="handleBodyTextChange"
                ></font-text>
            </div>
        </div>
    </div>
</template>

<script>
import FontText from "./FontText"

export default {
    name: "text-waterfall",
    props: ["tab", "style", "text"],
    components: {
        FontText,
    },
    data() {
        return {
            fontSizes: [180, 140, 110, 90, 70, 60, 50, 40, 30, 24, 20, 16, 14, 12, 10, 9, 8, 7],
        }
    },
    methods: {
        doPreventWaterfallKey(e) {
            if (e.keyCode === 13) return true
            return false
        },

        handleTextLiveInput(e) {
            this.$store.commit("setTabDataValue", {
                name: "liveText",
                value: e.target.innerHTML,
            })
        },

        handleWaterfallLiveInput(e) {
            this.handleTextLiveInput(e)
            // Update other waterfall view text
            let inputs = document.querySelectorAll(".text-waterfall__row__text .font-text")
            for (let input of inputs) {
                if (input != e.target) input.innerHTML = this.formatWaterfallText(e.target.innerHTML)
            }
        },

        handleBodyTextChange(e) {
            e.target.parentNode.scrollLeft = 0
            this.$store.dispatch("forceUpdateTabTextValue", e.target.innerHTML)
        },

        handleWaterfallKeydown(e) {
            if (this.doPreventWaterfallKey(e)) return e.preventDefault()
        },

        getWaterfallRowLineHeight(fontSize) {
            if (fontSize <= 10) return 4
            if (fontSize <= 20) return 3
            if (fontSize <= 25) return 2.5
            if (fontSize <= 30) return 2
            if (fontSize < 50) return 2
            if (fontSize < 70) return 1.5
            if (fontSize < 100) return 1.3
            return "normal"
        },

        formatWaterfallText(text) {
            // return utils.stringStripHtml(text)
            return text
        },
    },
}
</script>

<style lang="scss" scoped>
.text-waterfall {
    --text--padding: 70px;
    padding-bottom: var(--padding);

    &__row {
        position: relative;
        overflow: hidden;
        border-bottom: var(--border);

        &__label {
            position: absolute;
            left: var(--padding);
            top: 45%;
            transform: translateY(-45%);
        }

        &__text {
            padding: 0 0 0 var(--text--padding);
            white-space: nowrap;
            line-height: normal;
            overflow: hidden;

            &:deep(.font-text) {
                div {
                    display: inline !important;
                }

                br {
                    display: none !important;
                }
            }

            // &:deep(div) {
            //     display: inline !important;
            // }

            // &:deep(br) {
            //     display: none !important;
            // }
        }

        &:last-child {
            border-bottom: none;
        }

        &--rtl {
            .text-waterfall__row__text {
                padding: 0 calc(var(--text--padding) + var(--scrollbar--width)) 0 0;
            }

            .text-waterfall__row__label {
                left: auto;
                right: calc(var(--padding) + var(--scrollbar--width));
            }
        }
    }
}
</style>
