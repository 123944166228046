<template>
    <div class="panel-tabs flex rw ai-c">
        <div
            v-for="(tab, t) in tabs"
            :key="t"
            @click="$emit('set-tab', tab)"
            class="panel-tabs__tab flex rw ai-c jc-c"
            :class="{ 'panel-tabs__tab--current': currentTab.name === tab.name }"
        >
            <h5>{{ tab.title }}</h5>
        </div>
    </div>
</template>

<script>
export default {
    name: "panel-tabs",
    props: ["tabs", "currentTab", "setTab"],
}
</script>

<style lang="scss" scoped>
.panel-tabs {
    letter-spacing: 0.05em;
    height: var(--panel-tab--height);
    background: var(--color--lighter);
    background-color: var(--color--light--mid);

    &__tab {
        flex: 1 1 100%;
        text-align: center;
        height: var(--panel-tab--height);
        transition: background-color 0.2s ease;
        cursor: pointer;

        background-color: var(--color--light--mid);

        &--current {
            background-color: var(--color--lighter) !important;
        }

        &:hover {
            background-color: var(--color--light--high);
        }

        &--close {
            button {
                background-color: var(--color--mid);
                padding: 0.35em 0.7em 0.35em 0.7em;
                margin: 0;
            }
        }
    }
}
</style>
