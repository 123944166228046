<template>
    <div class="features" v-if="$store.state.online">
        <div class="features-list custom-scrollbar">
            <card
                v-for="(feature, f) in features"
                :key="f"
                :html="feature.text"
                :icon="feature.icon"
                @mouseover="currentFeature = feature"
            >
                <h5>{{ feature.name }}</h5>
            </card>
        </div>
        <div class="feature" ref="feature-video-wrapper">
            <div
                v-if="showVideo"
                class="feature__media"
                :style="{
                    width: `calc(${videoSize}px - var(--padding) * 9)`,
                    height: `calc(${videoSize}px -
                    var(--padding) * 9)`,
                }"
            >
                <transition name="component-fade" appear>
                    <div :key="currentFeature.media">
                        <div class="feature__media__wrapper">
                            <video
                                v-if="currentFeature.media.includes('.mp4')"
                                :src="`/static/media/${currentFeature.media}`"
                                loop
                                muted
                                autoplay
                            ></video>
                            <img v-else :src="`/static/media/${currentFeature.media}`" />
                        </div>
                        <div v-if="currentFeature.text" class="feature__text">
                            {{ currentFeature.text }}
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
    <div v-else class="flex jc-c ai-c" style="width: 100%; height: 95%">
        <div class="ta-c" style="color: var(--color--dark)">
            <h2><span class="icon">&#57351;</span><br /></h2>
            <h5><br />Connect to the internet to view Features</h5>
        </div>
    </div>
</template>

<script>
import Card from "@/components/Generic/Card.vue"

const features = [
    {
        name: "Audio Mode",
        media: "fg-audio-mode.mp4",
        icon: "&#127908;",
    },
    {
        name: "Mouse Mode",
        media: "fg-mouse-mode.mp4",
        icon: "&#57352;",
    },
    {
        name: "Waterfall View",
        media: "fg-waterfall-view.mp4",
        icon: "&#57353;",
    },
    {
        name: "Presentation View",
        media: "fg-presentation-view.mp4",
        icon: "&#57347;",
    },
    {
        name: "Center Mode",
        media: "fg-center-mode.mp4",
        icon: "&#57346;",
    },
    {
        name: "Auto Text Size",
        media: "fg-auto-size-text.mp4",
        icon: "&#57348;",
    },
    {
        name: "Variable Animations",
        media: "fg-animation.mp4",
        icon: "&#61515;",
    },
    {
        name: "Axis Clipping",
        media: "fg-axis-clipping.mp4",
        icon: "&#57362;",
    },
    {
        name: "Instance Generation",
        media: "fg-generate-instance.mp4",
        icon: "&#61465;",
    },
    {
        name: "Offline Mode",
        media: "fg-offline.png",
        icon: "&#57351;",
        text: "fontgauntlet.com caches locally in your browser. Access Font Gauntlet anywhere, anytime!",
    },
    {
        name: "Install as App with Chrome",
        media: "fg-install.png",
        icon: "&#57360;",
        text:
            "You can use Font Gauntlet as a standalone Chrome app by installing from the search bar. Add it to your dock for daily use!",
    },
]

export default {
    name: "features",
    title: "Features",
    width: 920,
    padding: false,
    components: {
        Card,
    },
    data() {
        return {
            showVideo: false,
            videoSize: 0,
            currentFeature: features[0],
            features,
        }
    },
    methods: {
        updateVideoSize() {
            let el = this.$refs["feature-video-wrapper"]
            let bb = el.getBoundingClientRect()
            if (bb.width < bb.height) this.videoSize = bb.width
            else this.videoSize = bb.height
        },
    },
    mounted() {
        setTimeout(() => {
            this.updateVideoSize()
            this.showVideo = true
        }, 400)
    },
}
</script>

<style lang="scss" scoped>
.features {
    --f--padding: calc(var(--padding) * 3);
    --f--width: calc(100% - var(--f--padding) * 2);
    // --f--video: calc((100% - var(--f--padding)) * 2);
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.features-list {
    height: 100%;
    overflow-y: auto;
    padding: var(--f--padding);

    .card {
        width: 35%;
        margin-bottom: calc(var(--padding) * 1.5);
        transition: background-color 0.2s ease;
        white-space: nowrap;
        &:hover {
            background-color: var(--color--light--high);
        }
    }
}

.feature {
    position: absolute;
    top: var(--f--padding);
    height: calc(100% - var(--f--padding) * 2);
    right: var(--f--padding);
    width: calc(0.65 * var(--f--width) - var(--f--padding));
    pointer-events: none;

    &__text {
        position: absolute;
        top: calc(100% + var(--padding));
        width: 100%;
        text-align: center;
    }

    &__media {
        position: absolute;
        top: 42%;
        left: 50%;
        transform: translateX(-50%) translateY(-42%);

        &__wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            overflow: hidden;
            // transition: width 0.2s ease, height 0.2s ease;

            video,
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.component-fade-enter-active,
.component-fade-leave-active {
    transition: opacity 0.3s ease;
}
.component-fade-enter-from,
.component-fade-leave-to {
    opacity: 0;
}

.component-fade-enter-to,
.component-fade-leave-froms {
    opacity: 1;
}
</style>
