import store from "@/store"

export default function defaultFg() {
    return {
        fontSize: 150,
        lineHeight: 1.05,
        letterSpacing: 0,
        textAlign: "left",
        textDecoration: "",
        textTransform: "",
        color: "#000000",
        direction: store.state.preferences.defaultTextDirection || "ltr",
        fontFeatureSettings: ["calt", "liga", "rlig", "rvrn", "kern", "rclt"],
        fontVariationSettings: "",
        centerMode: false,
    }
}
