<template>
    <div class="text-properties">
        <div class="sidebar__sub-section">
            <div
                class="text-properties__row flex rw ai-c"
                :class="{ disabled: ['waterfall'].includes($store.getters.viewMode) }"
            >
                <div class="text-properties__row__icon">
                    <span
                        class="icon circle-hover link"
                        @click="
                            $store.dispatch('updateTabFgValue', {
                                name: 'fontSize',
                                value: $store.state.defaults.fontSize.default,
                                format: 'float',
                            })
                        "
                        >&#xf054;</span
                    >
                </div>
                <div class="text-properties__row__input">
                    <input-range
                        :min="$store.getters.viewMode === 'glyphs' ? 28 : $store.state.defaults.fontSize.min"
                        :max="$store.getters.viewMode === 'glyphs' ? 200 : $store.state.defaults.fontSize.max"
                        :value="parseFloat(fg.fontSize)"
                        :crop="false"
                        :step="null"
                        :decimals="0"
                        @valueChange="updateFontSize"
                    >
                    </input-range>
                </div>
            </div>

            <div
                class="text-properties__row flex rw ai-c"
                :class="{ disabled: ['glyphs'].includes($store.getters.viewMode) }"
            >
                <div class="text-properties__row__icon">
                    <span
                        class="icon circle-hover link"
                        @click="
                            $store.dispatch('updateTabFgValue', {
                                name: 'letterSpacing',
                                value: $store.state.defaults.letterSpacing.default,
                                format: 'float',
                            })
                        "
                    >
                        &#xf052;
                    </span>
                </div>
                <div class="text-properties__row__input">
                    <input-range
                        :min="$store.state.defaults.letterSpacing.min"
                        :max="$store.state.defaults.letterSpacing.max"
                        :value="fg.letterSpacing * 1000"
                        :crop="false"
                        :step="null"
                        :decimals="0"
                        @valueChange="
                            (value) =>
                                $store.dispatch('updateTabFgValue', {
                                    name: 'letterSpacing',
                                    value: value / 1000,
                                })
                        "
                    >
                    </input-range>
                </div>
            </div>

            <div
                class="text-properties__row flex rw ai-c"
                :class="{ disabled: ['waterfall', 'style-range', 'glyphs'].includes($store.getters.viewMode) }"
            >
                <div class="text-properties__row__icon">
                    <span
                        class="icon circle-hover link"
                        @click="
                            $store.dispatch('updateTabFgValue', {
                                name: 'lineHeight',
                                value: $store.state.defaults.lineHeight.default,
                                format: 'float',
                            })
                        "
                        >&#xf053;</span
                    >
                </div>
                <div class="text-properties__row__input">
                    <input-range
                        :min="$store.state.defaults.lineHeight.min"
                        :max="$store.state.defaults.lineHeight.max"
                        :value="fg.lineHeight"
                        :crop="false"
                        :step="null"
                        :decimals="2"
                        @valueChange="
                            (value) =>
                                $store.dispatch('updateTabFgValue', {
                                    name: 'lineHeight',
                                    value,
                                })
                        "
                    >
                    </input-range>
                </div>
            </div>
        </div>

        <div class="sidebar__sub-section">
            <div class="button-group">
                <div class="button-pod">
                    <button
                        v-for="(option, o) in optionsTextAlignment"
                        :key="o"
                        class="button--square"
                        :class="{
                            active: fg.textAlign == option.value,
                        }"
                        @click="
                            $store.dispatch('updateTabFgValue', {
                                name: 'textAlign',
                                value: option.value,
                            })
                        "
                    >
                        <span v-if="option.value === 'left' && fg.direction === 'rtl'" class="icon">&#xf038;</span>
                        <span v-else class="icon" v-html="option.text"></span>
                    </button>
                </div>

                <button
                    class="toggle"
                    style="flex: 1 1 auto"
                    @click="
                        $store.dispatch('toggleTabForegroundValue', {
                            name: 'direction',
                            values: ['ltr', 'rtl'],
                        })
                    "
                >
                    <span v-if="$store.state.preferences.defaultTextDirection !== fg.direction" class="icon s"
                        >&#xF111;</span
                    >
                    <span v-else class="icon s" style="color: #999999">&#xF192;</span>
                    {{ $store.state.preferences.defaultTextDirection === "ltr" ? "RTL" : "LTR" }}
                </button>

                <div class="button-pod">
                    <button
                        v-for="(option, o) in optionsOtherProperties"
                        :key="o"
                        class="button--square"
                        :class="{
                            active: fg[option.type] == option.value,
                        }"
                        @click="
                            $store.dispatch('toggleTabForegroundValue', {
                                name: 'textTransform',
                                value: option.value,
                            })
                        "
                    >
                        <span class="icon" v-html="option.text"></span>
                    </button>
                </div>

                <button
                    class="toggle button--square"
                    :class="{
                        active: fg.centerMode,
                    }"
                    @click="$store.dispatch('toggleTabForegroundBoolean', 'centerMode')"
                >
                    <span class="icon xl">&#57346;</span>
                </button>

                <button
                    class="toggle button--square"
                    :class="{
                        active: tab.data.autoSizeText,
                    }"
                    @click="$store.dispatch('toggleAutoSizeText')"
                >
                    <span class="icon l">&#57348;</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import InputRange from "@/components/Generic/InputRange.vue"
import defaultFg from "@/store/defaults/fg"

export default {
    name: "text-properties",
    components: {
        InputRange,
    },
    data() {
        return {
            optionsTextAlignment: [
                { value: "left", text: "&#xf036;" },
                { value: "center", text: "&#xf037;" },
            ],
            optionsOtherProperties: [
                { value: "uppercase", text: "&#xf050;", type: "textTransform" },
                {
                    value: "capitalize",
                    text: "&#xf056;",
                    type: "textTransform",
                },
            ],
        }
    },
    computed: {
        tab: function () {
            if (this.$store.state.session != null) return this.$store.getters.tab
            else
                return {
                    data: {
                        autoSizeText: false,
                    },
                }
        },

        fg: function () {
            if (this.$store.state.session != null) return this.$store.getters.fg
            else return defaultFg()
        },
    },
    methods: {
        updateFontSize(value) {
            this.$store.dispatch("updateTabFgValue", {
                name: "fontSize",
                value,
            })
            this.$store.commit("setTabDataValue", { name: "autoSizeText", value: false })
        },
    },
}
</script>

<style lang="scss" scoped>
.text-properties {
    &__row {
        &__icon {
            flex: 0 0 20px;
        }

        &__input {
            flex: 1 1 100%;
        }
    }
}
</style>
