<template>
    <div class="card">
        <div class="flex rw ai-c">
            <div v-if="icon" class="card__icon" style="flex: 0 0 auto">
                <span class="icon" v-html="icon"></span>&nbsp;
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "card",
    props: ["html", "icon"],
}
</script>

<style lang="scss" scoped>
.card {
    padding: calc(var(--padding) * 2);
    padding-bottom: calc(var(--padding) * 2.25);
    border-radius: var(--padding);
    background-color: var(--color--lighter);
    box-sizing: border-box;

    &__icon {
        padding-right: var(--padding);
        .icon {
            line-height: 1;
            font-size: var(--font-size--l);
        }
    }
}
</style>
