<template>
    <div class="text-view__text" :style="{ padding: `${padding}px` }">
        <font-text :text="text" :style="style" @input="handleTextLiveInput" @blur="blur"> </font-text>
        <font-text :id="'virtual-text'" :text="liveText" :style="style"> </font-text>
    </div>
</template>

<script>
import { nextTick } from "vue"
import FontText from "./FontText"

export default {
    name: "text-default",
    props: ["tab", "style", "text", "liveText"],
    components: {
        FontText,
    },
    computed: {
        padding: function () {
            let padding = this.tab.data.style.fg.fontSize * 0.5
            if (padding < 8) padding = 8
            if (padding > 20) padding = null
            return padding
        },
    },
    methods: {
        async handleTextLiveInput(e) {
            if (this.tab.data.liveText === e.target.innerHTML) return

            await this.$store.commit("setTabDataValue", {
                name: "liveText",
                value: e.target.innerHTML,
            })

            nextTick(() => {
                if (this.tab.data.autoSizeText) {
                    this.$store.dispatch("autoSizeText")
                }
            })
        },

        blur(e) {
            this.$store.dispatch("forceUpdateTabTextValue", e.target.innerHTML)
        },
    },
}
</script>

<style lang="scss" scoped>
.text-view__text {
    padding: calc(var(--padding) * 2);
    overflow: visible;
    -webkit-appearance: none !important;
    appearance: none !important;
    overflow-wrap: break-word;
}
</style>
<style lang="scss">
#virtual-text {
    position: fixed;
    top: 1000%;
    left: 1000%;
    display: inline-block !important;
    width: auto !important;
    visibility: hidden !important;
    white-space: nowrap !important;
}
</style>
