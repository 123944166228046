import utils from "@/misc/utils"

export default {
    lastUpdated: (state) => {
        return utils.timeSince(state.config.BUILD_DATE)
    },

    online: (state) => {
        return state.online
    },

    server: (state) => {
        return state.server
    },
}
