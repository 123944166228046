<template>
  <div class="view">
  </div>
</template>

<script>
export default {
  name: "view",
  props: []
}
</script>
