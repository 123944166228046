<template>
    <div class="text-view-bar bar blur" v-show="!$store.state.settings.presentationMode">
        <div class="text-view-bar__section flex rw ai-c">
            <template v-if="!$store.state.sidebar.open"> <button class="square">&nbsp;</button>&nbsp; </template>
            <span>View:</span>
            <button
                :class="{ active: $store.getters.viewMode == null }"
                v-on:click="$store.dispatch('updateTabViewMode', null)"
            >
                Plain
            </button>
            <button
                :class="{ active: $store.getters.viewMode === 'waterfall' }"
                v-on:click="$store.dispatch('updateTabViewMode', 'waterfall')"
            >
                Waterfall
            </button>
            <button
                v-if="$store.getters.isFontVariable"
                :class="{ active: $store.getters.viewMode === 'style-range' }"
                v-on:click="$store.dispatch('updateTabViewMode', 'style-range')"
            >
                Styles
            </button>
            <button
                :class="{ active: $store.getters.viewMode === 'glyphs' }"
                v-on:click="$store.dispatch('updateTabViewMode', 'glyphs')"
            >
                Glyphs
            </button>
            <button @click="$store.dispatch('togglePresentationMode')">Present</button>
        </div>
        <div class="text-view-bar__section flex rw jc-fe">
            <label class="search" v-show="$store.getters.viewMode === 'glyphs'">
                <input
                    type="text"
                    name=""
                    :value="$store.getters.searchString"
                    placeholder="Search"
                    spellcheck="false"
                    style="width: 100%; max-width: 200px"
                    v-on:input="(e) => handleSearch(e.target.value)"
                />
                <span
                    v-if="$store.getters.searchString"
                    v-on:click="(e) => clearSearch(e.target.previousElementSibling)"
                ></span>
            </label>
            <button v-show="$store.getters.viewMode == null" @click="$store.dispatch('takeScreenshot')">
                <span class="icon">&#128247;</span>&nbsp;Screenshot<sup class="tt--u" style="color: var(--color--blue)"
                    >&nbsp;Beta</sup
                >
            </button>
            <button @click="$store.dispatch('copyCssCode')">
                <span class="icon">&#57345;</span>&nbsp;Copy CSS Code
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "text-view-bar",
    props: ["tab"],
    methods: {
        async toggleWaterfallMode() {
            this.$store.dispatch("toggleTabDataValue", { name: "waterfallMode" })
        },

        async toggleAutoSizeText() {
            await this.$store.dispatch("toggleTabDataValue", { name: "autoSizeText" })
            if (this.tab.data.autoSizeText) this.$store.dispatch("autoSizeText")
        },

        handleSearch(s) {
            this.$store.dispatch("updateTabSearchString", s.toLowerCase().trim())
        },

        clearSearch(el) {
            this.$store.dispatch("updateTabSearchString", null)
            el.value = ""
        },
    },
}
</script>

<style lang="scss" scoped>
.text-view-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 auto;
    position: absolute;
    bottom: 0;
    width: 100%;

    &__section > * + * {
        margin-left: calc(var(--padding) / 2);
    }
}
</style>
