<template>
    <div class="tab-view-bar flex rw" v-show="!$store.state.settings.presentationMode">
        <transition-group name="tab-button" tag="div" class="tab-view-bar__tabs flex rw nwr">
            <div
                class="tab-view-bar__button"
                v-for="(tab, t) in tabs"
                v-bind:key="t"
                :class="{
                    'tab-view-bar__button--open': tab.id === $store.getters.tab.id,
                }"
                appear
            >
                &nbsp;
                <div class="tab-view-bar__button__inner text-overflow-ellipsis" @click="() => handleTabClick(tab)" v-html="getTabTitle(tab)">
                </div>
                <div class="tab-view-bar__button__close" @click="() => closeTab(t)">
                    <span v-if="Object.keys(additionalAllowed).includes(tab.type)" class="icon text-color"
                        >&#xf00d;
                    </span>
                </div>
            </div>
        </transition-group>

        <div
            class="tab-view-bar__button tab-view-bar__button--additional"
            v-if="Object.keys(additionalAllowed).length > 0"
            @click="() => handleAdditionalTabClick(Object.keys(additionalAllowed)[0])"
        >
            <span class="icon">&#x2795;</span>
        </div>
    </div>
</template>

<script>
import utils from "@/misc/utils"

export default {
    name: "tab-view-bar",
    props: ["tabs", "tabTypes"],
    data() {
        return {
            additionalAllowed: this.getAdditionalAllowed(),
        }
    },
    methods: {
        getTabTitle(tab) {
            if (tab.viewMode === "glyphs") return "Glyphs Overview"
            let text = this.formatTextContentString(tab.data.liveText)
            if (tab.type === "text" && text !== "") return text
            return tab.title || this.tabTypes[tab.name].title
        },

        getAdditionalAllowed() {
            if (this.tabTypes == null) return {}
            let allowed = {}
            for (let tabTypeName in this.tabTypes) {
                let tabType = this.tabTypes[tabTypeName]
                if (tabType.allowAdditional != null && tabType.allowAdditional) allowed[tabTypeName] = tabType
            }
            return allowed
        },

        getCurrentTabIndex() {
            for (var i = 0; i < this.tabs.length; i++) if (this.tabs[i].open) return i
            return 0
        },

        handleTabClick(tab) {
            this.$emit("tabsUpdate", tab)
        },

        closeTab(tab) {
            this.$emit("closeTab", tab)
        },

        handleAdditionalTabClick(tabName) {
            this.$emit("updateAdditional", {
                name: tabName,
            })
        },

        formatTextContentString(s) {
            s = utils.stringStripHtml(s)
            // Convert nbps to normal space
            s = s.replace(/&nbsp;/g, " ")
            // Trim left/right whitespace
            s = s.trim()
            let sOldLenth = s.length
            s = s.substr(0, 100)
            if (s.length < sOldLenth) s += "…"
            return s
        },
    },
}
</script>

<style lang="scss" scoped>
.tab-view-bar {
    flex: 0 0 auto;

    &__tabs {
        flex: 1 1 100%;
    }

    &__button {
        flex: 1 1 auto;
        cursor: pointer;
        user-select: none;
        text-align: center;
        height: var(--tab--bar--height);
        line-height: var(--tab--bar--height);
        @include text-small;
        font-family: var(--font);
        position: relative;
        color: var(--color--dark);
        background-color: var(--color--light--mid);
        transition: background-color 0.1s ease-out, color 0.1s ease-out, border-color 0.1s ease-out;
        border-left: 1px solid var(--color--mid--high);

        &:first-child {
            border: 0;
        }

        &--open + * {
            border-color: transparent;
        }

        &__inner {
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: 100%;
            padding: 0 calc(var(--tab--bar--height) + 3px) 0 calc(var(--tab--bar--height));
            width: 100%;
            transform: translateY(-50%) translateX(-50%);
        }

        &__close {
            visibility: hidden;
            position: absolute;
            top: 0;
            right: 0;
            width: calc(var(--tab--bar--height) + 3px);
            height: 100%;
            line-height: var(--tab--bar--height);
            transition: background-color 0.1s ease-out;
            color: var(--color--darkest);
            padding-bottom: 2px;

            .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                padding-bottom: 1px;
            }

            &:hover {
                background-color: var(--color--red--mid);
            }
        }

        &:hover {
            background-color: var(--color--light--high);
            color: var(--color--darkest);

            .tab-view-bar__button__close {
                visibility: visible;
            }
        }

        &--open {
            background-color: var(--color--lighter) !important;
            color: var(--color--darkest);
            border-color: transparent;
        }

        &--additional {
            flex: 0 0 calc(var(--tab--bar--height) + 2px);
            // padding: 0 0 0 2px;
            background-color: var(--color--yellow--mid);
            color: var(--color--darkest);
            transition: background-color 0.1s ease-out;
            border: 0;

            .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                padding-bottom: 1px;
                padding-left: 1px;
            }

            &:hover {
                background-color: var(--color--green);
            }
        }
    }
}

.tab-button-enter-active {
    transition: flex 0.5s ease !important;
}

.tab-button-leave-active {
    transition: flex 0.2s ease !important;
    // Don't allow user to click on button again
    // When it's already closing, or see the close button
    pointer-events: none !important;
    .tab-view-bar__button__close {
        visibility: hidden !important;
    }
}

.tab-button-enter-from,
.tab-button-leave-to {
    flex: 0 0 0% !important;
}
</style>
