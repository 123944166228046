<template>
    <div class="sidebar" :style="sidebarCSS">
        <div class="sidebar__inner custom-scrollbar-thin" v-if="$store.state.window.loaded" v-show="open">
            <template v-for="(section, index) in sidebarSections" :key="index">
                <sidebar-section v-if="showSection(section)" :section="section"></sidebar-section>
            </template>
        </div>

        <template v-if="open">
            <sidebar-bar></sidebar-bar>
        </template>

        <div
            class="sidebar__show"
            :class="{ 'sidebar__show--active': $store.state.settings.presentationMode }"
            @click="handleSidebarToggle"
        >
            <button class="button">
                <span class="icon">{{ open ? "&#xF100;" : "&#xF101;" }}</span>
                <div class="sidebar__show__text">
                    <span>&nbsp;&nbsp;Stop Presenting&nbsp;</span>
                </div>
            </button>
        </div>
    </div>
</template>

<script>
import sidebarSections from "@/components/Sidebar/sidebarSections"
import SidebarSection from "@/components/Sidebar/SidebarSection.vue"
import SidebarBar from "@/components/Sidebar/SidebarBar.vue"

export default {
    name: "app-sidebar",
    components: {
        SidebarBar,
        SidebarSection,
    },
    data() {
        return {
            sidebarSections,
        }
    },
    computed: {
        open: function () {
            return this.$store.state.sidebar.open != false && !this.$store.state.settings.presentationMode
        },

        sidebarCSS: function () {
            return {
                flex: `0 0 ${this.open ? this.$store.state.sidebar.width : 0}px`,
                maxWidth: `${this.$store.state.sidebar.width}px`,
            }
        },
    },
    methods: {
        showSection(section) {
            if (section.show == false) return false
            if (section.variableSection == true && this.$store.getters.isFontVariable == false) return false
            return true
        },

        handleSidebarToggle() {
            if (this.$store.state.settings.presentationMode) this.$store.dispatch("togglePresentationMode")
            else this.$store.dispatch("toggleSidebar")
        },
    },
}
</script>

<style lang="scss" scoped>
.sidebar {
    flex: 0 0 var(--sidebar--width);
    max-width: var(--sidebar--width);
    position: relative;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: var(--color--lightest);

    &__inner {
        position: relative;
        flex-wrap: nowrap;
        padding-bottom: var(--bar--height);
        height: 100%;
        overflow-y: scroll;
        border-right: var(--border);

        &::-webkit-scrollbar-track {
            margin-bottom: var(--bar--height); // To account for the bar on the bottom of the screen
        }
    }

    &__show {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 11;
        padding: 6px 5px 6px 6px;
        cursor: pointer;

        button {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            transition: flex 0.3s ease;
            width: var(--button--square--width);
            overflow: hidden;
            transition: width 0.25s ease;
        }

        &__text {
            white-space: nowrap;
            opacity: 0;
            transition: opacity 0.2s ease;
        }

        &--active {
            button {
                width: 100%;
            }

            .sidebar__show__text {
                opacity: 1;
            }
        }
    }
}
</style>
<style lang="scss">
.sidebar__sub-section {
    margin-bottom: var(--padding);
    overflow: visible;
    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
}
</style>
