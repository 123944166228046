<template>
    <div class="input-range" :class="{ 'input-range--crop': crop }">
        <div class="input-range__inner">
            <div class="input-range__track-wrapper input-range__track-wrapper--thumb" ref="track-thumb">
                <div
                    class="input-range__marker input-range__marker--thumb border-color--base"
                    ref="thumb"
                    :class="{
                        'input-range__marker--thumb--active': thumbInputActive,
                    }"
                    :style="{
                        left: `${valueToPercentage(thumbPosition) * 100}%`,
                    }"
                    @mousedown="handleThumbMousedown"
                    @mouseup="handleThumbMouseup"
                    @touchstart="handleThumbMousedown"
                    @touchend="handleThumbMouseup"
                >
                    <input
                        type="number"
                        ref="thumb-input"
                        :value="formatValueDisplay(thumbPosition)"
                        @change="handleThumbInputChange"
                    />
                </div>
            </div>

            <template v-if="crop">
                <div class="input-range__track-wrapper input-range__track-wrapper--crop-left" ref="track-crop-left">
                    <div
                        class="input-range__marker input-range__marker--crop"
                        :style="{
                            left: `${valueToPercentage(cropMin) * 100}%`,
                        }"
                    >
                        <div class="input-range__marker__inner">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 10 10"
                                preserveAspectRatio="none"
                                @mousedown="handleCropLeftMousedown"
                                @mouseup="handleCropLeftMouseup"
                                @touchstart="handleCropLeftMousedown"
                                @touchend="handleCropLeftMouseup"
                            >
                                <polygon points="0,0 10,5 0,10" />
                            </svg>
                            <div class="input-range__marker__number" v-if="cropLeftDown">
                                {{ formatValueDisplay(cropMin) }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="input-range__track-wrapper input-range__track-wrapper--crop-right" ref="track-crop-right">
                    <div
                        class="input-range__marker input-range__marker--crop"
                        :style="{
                            left: `${valueToPercentage(cropMax) * 100}%`,
                        }"
                        ref="crop-left"
                    >
                        <div class="input-range__marker__inner">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 10 10"
                                preserveAspectRatio="none"
                                @mousedown="handleCropRightMousedown"
                                @mouseup="handleCropRightMouseup"
                                @touchstart="handleCropRightMousedown"
                                @touchend="handleCropRightMouseup"
                            >
                                <polygon points="0,5 10,0 10,10" />
                            </svg>
                            <div class="input-range__marker__number" v-if="cropRightDown">
                                {{ formatValueDisplay(cropMax) }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="input-range__track-wrapper">
                    <div class="input-range__track"></div>
                </div>
            </template>

            <div
                class="input-range__track-wrapper input-range__track-wrapper--crop-left"
                @mousedown="handleTrackMousedown"
            >
                <div
                    v-if="crop"
                    class="input-range__track input-range__track--crop-left"
                    :style="{
                        left: `${valueToPercentage(cropMin) * 100}%`,
                        right: `calc(${(1 - valueToPercentage(thumbPosition)) * 100}% - ${cropWidth}px)`,
                    }"
                ></div>
                <div
                    v-else
                    class="input-range__track input-range__track--crop-left"
                    :style="{
                        left: `${valueToPercentage(cropMin) * 100}%`,
                        right: `calc(${(1 - valueToPercentage(thumbPosition)) * 100}% - ${cropWidth}px)`,
                    }"
                ></div>
            </div>

            <div
                class="input-range__track-wrapper input-range__track-wrapper--crop-right"
                @mousedown="handleTrackMousedown"
            >
                <div
                    class="input-range__track input-range__track--crop-right"
                    :style="{
                        left: `calc(${valueToPercentage(thumbPosition) * 100}% - ${cropWidth}px)`,
                        right: `${(1 - valueToPercentage(cropMax)) * 100}%`,
                    }"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
import { nextTick } from "vue"

export default {
    name: "input-range",
    props: {
        value: {
            type: Number,
            default: 0,
        },
        min: {
            type: Number,
            default: 0,
        },
        minCrop: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 100,
        },
        maxCrop: {
            type: Number,
            default: 0,
        },
        crop: {
            type: Boolean,
            default: false,
        },
        step: {
            type: Number,
            default: 1,
        },
        decimals: {
            type: Number,
            default: 0,
        },
    },
    watch: {
        value: function (value) {
            // We only need to update if the output value if different
            // Otherwise the slider will appear jumpy
            if (this.validateOutputValue(this.thumbPosition) != this.validateOutputValue(value)) {
                this.thumbPosition = this.validateValue(value) // Validate value, as it may be outside the max/min
            }
        },

        min: function (value) {
            this.cropMin = value
            this.updateRange()
        },

        max: function (value) {
            this.cropMax = value
            this.updateRange()
        },

        minCrop: function (value) {
            this.cropMin = value
            this.updateRange()
        },

        maxCrop: function (value) {
            this.cropMax = value
            this.updateRange()
        },
    },
    data() {
        return {
            mousedown: false,
            mousedownStartValue: null,
            cropLeftDown: false,
            cropRightDown: false,
            valueStart: this.value == null ? 0 : this.value,
            cropMin: this.min,
            cropMax: this.max,
            range: this.max - this.min,
            thumbPosition: this.value,
            thumbWidth: 20,
            cropWidth: 10,
            thumbInputActive: false,
            active: false,
        }
    },
    methods: {
        handleThumbMousedown(e) {
            e.preventDefault()
            this.mousedown = true
            if (e.target == this.$refs["thumb-input"]) this.mousedownStartValue = e.clientX
        },
        handleCropLeftMousedown(e) {
            e.preventDefault()
            this.cropLeftDown = true
        },
        handleCropRightMousedown(e) {
            e.preventDefault()
            this.cropRightDown = true
        },
        handleThumbMouseup(e) {
            this.mousedown = false
            this.cropLeftDown = false
            this.cropRightDown = false
            if (this.mousedownStartValue == e.clientX) {
                this.thumbInputActive = true
                if (this.$refs["thumb-input"] != null) this.$refs["thumb-input"].focus()
            }
        },
        handleCropLeftMouseup() {
            this.cropLeftDown = false
        },
        handleCropRightMouseup() {
            this.cropRightDown = false
        },

        handleThumbMousemove(e) {
            if (this.mousedown)
                this.updateThumbPosition(this.getClientXAsRangePercentage(e, this.$refs["track-thumb"], true))
            if (this.cropLeftDown)
                this.updateCropMin(this.getClientXAsRangePercentage(e, this.$refs["track-crop-left"]))
            if (this.cropRightDown)
                this.updateCropMax(this.getClientXAsRangePercentage(e, this.$refs["track-crop-right"]))
        },

        getClientXAsRangePercentage(e, track, crop) {
            e.preventDefault()
            let clientX = e.clientX
            if (clientX == null && e.touches != null) clientX = e.touches[0].clientX
            if (clientX == null) return

            let trackBb = track.getBoundingClientRect()
            let trackLeft = trackBb.left
            let trackWidth = trackBb.right - trackLeft
            let position = clientX - trackLeft
            if (position < 0) position = 0
            if (position > trackWidth) position = trackWidth
            let percentage = position / trackWidth

            let value = this.min + this.range * percentage
            if (crop) value = this.cropValue(value)
            return value
        },

        updateThumbPosition(value) {
            this.thumbPosition = value
            let thumbPositionFormated = this.validateOutputValue(value)
            this.$emit("valueChange", thumbPositionFormated)
        },

        updateCropMax(value) {
            this.cropMax = value
            this.$emit("maxChange", this.cropMax)
            if (this.cropMin > this.cropMax) this.updateCropMin(this.cropMax)
            if (this.thumbPosition > this.cropMax) this.updateThumbPosition(this.cropMax)
        },

        updateCropMin(value) {
            this.cropMin = value
            this.$emit("minChange", this.cropMin)
            if (this.cropMax < this.cropMin) this.updateCropMax(this.cropMin)
            if (this.thumbPosition < this.cropMin) this.updateThumbPosition(this.cropMin)
        },

        valueToPercentage(value) {
            return (value - this.min) / this.range
        },

        handleTrackMousedown(e) {
            this.updateThumbPosition(this.getClientXAsRangePercentage(e, this.$refs["track-thumb"], true))
            this.handleThumbMousedown(e)
        },

        handleThumbMouseDown(e) {
            this.thumbInputActive = false
            let path = e.path || e.composedPath()
            this.active = path.indexOf(this.$el) > -1
            if (this.$refs["thumb-input"] != null) this.$refs["thumb-input"].blur()
        },

        updateRange() {
            this.range = this.max - this.min
        },

        validateOutputValue(value) {
            if (this.step != null) {
                if (this.step === 1) value = Math.floor(value)
                else value = parseFloat((Math.floor(value / this.step) * this.step).toFixed(2))
            }
            return value
        },

        validateValue(value, crop) {
            if (crop) return this.cropValue(value)
            if (value < this.min) value = this.min
            if (value > this.max) value = this.max
            return value
        },

        cropValue(value) {
            if (value < this.cropMin) return this.cropMin
            if (value > this.cropMax) return this.cropMax
            return value
        },

        handleThumbInputChange(e) {
            if (this.$refs["thumb-input"] != null) this.$refs["thumb-input"].blur()
            this.thumbInputActive = false
            let value = parseFloat(e.target.value)
            if (isNaN(value)) return
            value = this.validateValue(value, true)
            this.updateThumbPosition(value)
        },

        formatValueDisplay(value) {
            return value.toFixed(this.decimals)
        },

        getSmallJump() {
            if (this.range <= 10) return 0.1
            return 1
        },

        getBigJump() {
            if (this.range <= 10) return 1
            return 10
        },

        increaseValueSmall() {
            return this.validateValue(this.thumbPosition + this.getSmallJump(), true)
        },

        increaseValueBig() {
            return this.validateValue(this.thumbPosition + this.getBigJump(), true)
        },

        decreaseValueSmall() {
            return this.validateValue(this.thumbPosition - this.getSmallJump(), true)
        },

        decreaseValueBig() {
            return this.validateValue(this.thumbPosition - this.getBigJump(), true)
        },

        valueUpShortcut(e) {
            e.preventDefault()
            let value
            if (e.shiftKey) value = this.increaseValueBig()
            else value = this.increaseValueSmall()
            this.updateThumbPosition(value)
        },

        valueDownShortcut(e) {
            e.preventDefault()
            let value
            if (e.shiftKey) value = this.decreaseValueBig()
            else value = this.decreaseValueSmall()
            this.updateThumbPosition(value)
        },
    },
    mounted() {
        window.addEventListener("mousemove", this.handleThumbMousemove)
        window.addEventListener("touchmove", this.handleThumbMousemove)
        window.addEventListener("mouseup", this.handleThumbMouseup)
        window.addEventListener("mousedown", this.handleThumbMouseDown)

        nextTick(() => {
            this.thumbWidth = this.$refs["thumb"].getBoundingClientRect().width
            if (this.crop) this.cropWidth = this.$refs["crop-left"].getBoundingClientRect().width
        })

        document.addEventListener(
            "keydown",
            (e) => {
                if (e.keyCode === 39 || e.keyCode === 38) {
                    if (e.keyCode === 39 && this.thumbInputActive) return // If thumbInput is active, left/right keys need to be skipped
                    if (this.active) this.valueUpShortcut(e)
                }
                if (e.keyCode === 37 || e.keyCode === 40) {
                    if (e.keyCode === 37 && this.thumbInputActive) return // If thumbInput is active, left/right keys need to be skipped
                    if (this.active) this.valueDownShortcut(e)
                }
            },
            false
        )

        // setTimeout(() => this.$emit("valueChange", -100), 1000)
    },
}
</script>

<style lang="scss" scoped>
$thumbWidth: 42px;
$thumbHeight: 20px;
$cropWidth: 8px;

.input-range {
    height: 28px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0;

    &__inner {
        position: relative;
        flex: 1 1 100%;
        height: 20px;
    }

    &__track-wrapper {
        position: absolute;
        height: 20px;
        left: 0;
        right: 0;

        &--thumb {
            left: 0;
            right: 0;
            // Update for non-crop
            left: $thumbWidth/2 + $cropWidth/2;
            right: $thumbWidth/2 + $cropWidth/2;
        }

        &--click {
            padding: $thumbHeight / 2 0;
        }

        &--crop-left,
        &--crop-right {
            cursor: pointer;
        }

        &--crop-left {
            left: 5px;
            right: ($thumbWidth/2 + $cropWidth/2) * 2;
        }

        &--crop-right {
            left: ($thumbWidth/2 + $cropWidth/2) * 2;
            right: 5px;
        }
    }

    &--crop {
        height: 34px;
        padding: 0 $cropWidth;

        .input-range__track-wrapper {
            &--thumb {
                left: $thumbWidth/2 + $cropWidth/2;
                right: $thumbWidth/2 + $cropWidth/2;
            }

            &--crop-left {
                left: 0;
            }

            &--crop-right {
                right: 0;
            }
        }
    }

    &__track {
        height: 1px;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        background-color: #bbbbbb;
        z-index: 0;

        &--crop-left,
        &--crop-right {
            background-color: #888888;
            z-index: 1;
        }
    }

    &__marker {
        position: absolute;
        top: 50%;
        cursor: pointer;
        z-index: 2;
        transform: translateY(-50%);
        box-sizing: border-box;
        text-align: center;
        user-select: none;

        &--thumb {
            height: $thumbHeight;
            width: $thumbWidth;
            background-color: var(--color--green---mid);
            border-radius: $thumbHeight;
            border: 2px solid white;
            transform: translateY(-50%) translateX(-$thumbWidth/2);
            // transform-origin: center;
            transform-origin: top left;
            transition: transform 0.075s ease-out;
            z-index: 3;
            display: flex;

            span {
                color: black !important;
            }

            input {
                color: black !important;
                width: 100%;
                text-align: center;
                font-size: 11px;
                cursor: pointer;
                // user-select: none;
                margin: 0;
                padding: 0;
                padding-bottom: 1px;
                border: 0;
                line-height: 1;
                font-feature-settings: "tnum" 1;
            }

            &--active {
                transform: scale(1.3) translateX(-50%) translateY(-50%);
                border: 2px solid transparent !important;

                input {
                    // user-select: all;
                }
            }
        }

        &--crop {
            height: $thumbHeight;
            width: $cropWidth;
            cursor: ew-resize;
            transform: translateY(-50%) translateX(-$cropWidth/2);

            &__inner {
                position: relative;
                width: 100%;
                height: 100%;
            }

            svg {
                height: $thumbHeight;
                width: $cropWidth;
                polygon {
                    fill: blue;
                }
            }
        }

        &__number {
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            top: 100%;
            padding-top: 2px;
            line-height: 1;
            user-select: none;
            pointer-events: none;
        }
    }
}

.input-range__tooltip-input {
    position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 20px;
    background-color: #eeeeee;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    display: none;

    &::after {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #eeeeee;
    }

    input {
        width: 100%;
    }

    &--show {
        display: block;
    }
}
</style>
