<template>
    <panel :tabs="tabs" :startTab="currentTab"></panel>
</template>

<script>
import { markRaw } from "vue"
import Panel from "@/components/Generic/Panel/Panel.vue"
import Changelog from "./Changelog/Changelog.vue"
import Info from "./Info/Info.vue"
import Settings from "./Settings/Settings.vue"
import Features from "./Features/Features.vue"

export default {
    name: "dashboard",
    data() {
        return {
            tabs: [markRaw(Info), markRaw(Features), markRaw(Settings), markRaw(Changelog)],
        }
    },
    computed: {
        currentTab: function () {
            return this.tabs.find((tab) => tab.name === this.$store.state.dashboardTabName)
        },
    },
    components: {
        Panel,
    },
}
</script>
