<template>
    <div
        class="sidebar-bar bar blur"
        style="display: flex; flex-direction: row; justify-content: space-between"
        v-show="!$store.state.settings.presentationMode"
    >
        <div class="button-group" style="flex: 1 1 auto; justify-content: flex-end">
            <!-- <button
                class="icon"
                v-on:click="$store.commit('togglePreferenceValue', 'darkMode')"
                style="background-color: #00ff00; color: black"
            >
                <template v-if="!$store.state.preferences.darkMode">&#xF186;</template>
                <template v-else>&#xF185;</template>
            </button> -->

            <button class="button--square" v-on:click="$store.commit('setDashboardTab', 'info')">
                <span class="icon">&#x2139;</span>
            </button>

            <button
                class="button--square"
                style="margin-right: 0"
                v-on:click="$store.commit('setDashboardTab', 'settings')"
            >
                <span class="icon">&#xF013;</span>
            </button>
        </div>
    </div>
</template>

<script>
import utils from "@/misc/utils"

export default {
    name: "sidebar-bar",
    computed: {
        version: function () {
            return utils.formatVersionShort(this.$store.state.config.VERSION)
        },
    },
}
</script>

<style lang="scss" scoped>
.sidebar-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    border-right: var(--border);
}
</style>
