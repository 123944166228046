import utils from "@/misc/utils"

export default {
    updateUserLastVersionUsed(state) {
        state.user.data.lastVersionUsed = state.config.VERSION
    },

    updateUserLastBuildDateUsed(state) {
        state.user.data.lastBuildDateUsed = state.config.BUILD_DATE.getTime()
    },

    setUserNewVersionVisit(state) {
        state.user.newVersionVisit = utils.versionIsNewNoPatch(state.user.data.lastVersionUsed, state.config.VERSION)
    },

    setUserNewBuildVisit(state) {
        state.user.newBuildVisit = utils.buildIsNew(
            state.user.data.lastBuildDateUsed,
            state.config.BUILD_DATE.getTime()
        )
    },
}
