import panels from "@/components/Panels/panels"

export default {
    togglePanel(state, panelName) {
        if (state.panelName == null) state.panel = null
        if (state.panel == null) state.panel = panels[panelName]
        else state.panel = null
    },

    setDashboardTab(state, tabName) {
        state.panel = panels["dashboard"]
        state.dashboardTabName = tabName
    },
}
