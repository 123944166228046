/* eslint-disable no-console */

import { register } from "register-service-worker"
import store from "@/store/index"

if (process.env.NODE_ENV === "production") {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log("Service Worker: App is being served from cache by a service worker.")
        },
        registered() {
            console.log("Service Worker: Service worker has been registered.")
        },
        cached() {
            console.log("Service Worker: Content has been cached for offline use.")
        },
        updatefound() {
            console.log("Service Worker: New content is downloading.")
            store.dispatch("showNotification", {
                text: "Font Gauntlet is updating, please wait...",
                length: "infinite",
                icon: "spinner",
            })
        },
        updated() {
            console.log("Service Worker: New content is available; please refresh.")
            window.location.reload()
        },
        offline() {
            console.log("Service Worker: No internet connection found. App is running in offline mode.")
        },
        error(error) {
            console.error("Service Worker: Error during service worker registration:", error)
        },
    })
}
