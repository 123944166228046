import utils from "@/misc/utils"
import api from "@/misc/api"
import { loadFontFromFile, loadFontFromUrl, getInstanceCoordinatesAsAxesWithNames } from "@/misc/lib-font-utils"

export default {
    async openFont(context, { Font, disableInstanceGeneration }) {
        // If a session is already open, replace the existing font
        if (context.state.session != null) await context.dispatch("replaceFontInSession", Font)
        else await context.dispatch("newSessionWithFonts", { fonts: [Font] })
        context.commit("setInstanceGenerationDisabled", disableInstanceGeneration === true)
    },

    async openFontFromDropEvent(context, e) {
        if (context.state.window.fileSystemAccess && e.dataTransfer.items) {
            const item = e.dataTransfer.items[0]
            const fileHandle = await item.getAsFileSystemHandle()
            context.dispatch("openFontFileHandler", fileHandle)
        } else if (e.dataTransfer.files) {
            let file = e.dataTransfer.files[0]
            context.dispatch("openFontFromFile", { file })
        }
    },

    async openFontFromFileEvent(context, e) {
        console.log(e)
        if (e.target.files.length === 0) return
        let file = e.target.files[0]
        await context.dispatch("openFontFromFile", { file })
    },

    async openFontFromFile(context, data) {
        context.dispatch("openSessionPre")
        let Font = await loadFontFromFile(data.file).catch(() => context.dispatch("openFontError"))
        if (typeof Font === "undefined") return
        Font.filename = data.file.name
        Font.file = data.file
        if (data.fileHandle) Font.fileHandle = data.fileHandle
        await context.dispatch("openFont", { Font })
    },

    async openFontFromUrl(context, { url, familyName, disableInstanceGeneration }) {
        context.dispatch("openSessionPre")
        let Font = await loadFontFromUrl(url).catch(() => context.dispatch("openFontError"))
        if (typeof Font === "undefined") return
        if (familyName != null) Font.customName = familyName
        context.dispatch("openFont", { Font, disableInstanceGeneration })
    },

    async openSessionPre(context) {
        context.dispatch("showNotification", {
            status: "function",
            type: "spinner",
            length: "quick",
        })
    },

    openFontError(context) {
        context.dispatch("showNotification", {
            text: `There was a problem opening your font`,
            status: "danger",
        })
    },

    async openFontFileHandler(context, fileHandle) {
        const file = await fileHandle.getFile()
        await context.dispatch("openFontFromFile", { file, fileHandle })
        // console.log(">>", context.state.session)
        // context.dispatch("checkFontFileHandlerUpdates", { file, fileHandle })
    },

    async openFontWithFileSystemDialog(context) {
        const [fileHandle] = await window.showOpenFilePicker()
        context.dispatch("openFontFileHandler", fileHandle)
    },

    downloadInstance(context, instance) {
        if (context.state.downloadingInstance) return
        if (!context.getters.isFontVariable) return

        let axes = getInstanceCoordinatesAsAxesWithNames(context.state.font, instance)

        context.commit("setDownloadingInstance", true)
        api.downloadInstanceAtAxisPosition(context.state.fontData.file, axes).then(
            (res) => {
                context.commit("setDownloadingInstance", false)
                // let filename = utils.getFilenameFromAxisPosition(axes, context.getters.fontFamilyName)
                let filename = utils.getFilenameFromInstance(instance, context.getters.fontFamilyName)
                utils.downloadBase64File(filename, res.fontBase64)
            },
            (err) => {
                context.commit("setDownloadingInstance", false)
                context.dispatch("showNotification", {
                    text: err,
                    status: "danger",
                })
            }
        )
    },

    downloadCurrentInstance(context) {
        if (!context.state.online) return
        if (context.state.instanceGenerationDisabled) return
        if (context.state.downloadingInstance) return
        if (!context.getters.isFontVariable) return

        // Current tab axis location
        let tabAxes = context.getters.axes
        let axes = {}
        // Conform data to API structure
        for (let axisSlug in tabAxes) {
            axes[axisSlug] = {
                value: tabAxes[axisSlug].value,
                name: tabAxes[axisSlug].name,
            }
        }

        context.commit("setDownloadingInstance", true)
        let file = new File([context.getters.font.buffer], "font.ttf")
        api.downloadInstanceAtAxisPosition(file, axes).then(
            (res) => {
                context.commit("setDownloadingInstance", false)
                let filename = utils.getFilenameFromAxisPosition(axes, context.getters.fontFamilyName)
                utils.downloadBase64File(filename, res.fontBase64)
            },
            (err) => {
                context.commit("setDownloadingInstance", false)
                context.dispatch("showNotification", {
                    text: err,
                    status: "function",
                })
            }
        )
    },

    copyCssCode(context) {
        let opentypeString = utils.getOpentypeFeaturesArrayAsCssString(context.getters.fg.fontFeatureSettings)
        let variationsString = context.getters.fontVariationSettings

        if (opentypeString === "" && variationsString === "") return

        let code = ".my-text {"
        if (opentypeString !== "") code += `\n\tfont-feature-settings: ${opentypeString};`
        if (variationsString !== "") code += `\n\tfont-variation-settings: ${variationsString};`

        code += "\n}"
        navigator.clipboard.writeText(code)

        context.dispatch("showNotification", {
            text: `Your CSS code was copied to your clipboard<br><br> <code>${code}</code>`,
            status: "function",
            length: "quick",
            icon: "&#57345;",
        })
    },
}
