export default {
  linear: {
    title: "Linear",
    function: function (t) {
      return t
    },
  },
  easeInOutQuad: {
    title: "Ease Quad",
    function: function (t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
    },
  },
  easeInOutCubic: {
    title: "Ease Cubic",
    function: function (t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
    },
  },
  easeInOutQuart: {
    title: "Ease Quart",
    function: function (t) {
      return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t
    },
  },
  easeInOutQuint: {
    title: "Ease Quint",
    function: function (t) {
      return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t
    },
  },
}
