<template>
    <info-test></info-test>
</template>

<script>
import { defineAsyncComponent } from "vue"
const InfoLazy = defineAsyncComponent(() => import("./InfoLazy.vue"))

export default {
    name: "info",
    title: "Info",
    height: 800,
    components: {
        "info-test": InfoLazy,
    },
}
</script>
