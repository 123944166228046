import db from "@/db"

export default {
    initUser(context) {
        // Check if this is a new version/build visit
        context.commit("setUserNewVersionVisit")
        context.commit("setUserNewBuildVisit")

        // Show notification if user is viewing a new version (excluding bug patches)
        if (context.state.user.newBuildVisit) {
            context.dispatch("showNotification", {
                status: "function",
                text: "Font Gauntlet has been updated since your last visit",
                icon: "&#x2139;",
            })
        }

        // Now update version/build data
        context.commit("updateUserLastVersionUsed")
        context.commit("updateUserLastBuildDateUsed")

        // Save user data
        db.saveUser()
    },
}
