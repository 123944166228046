import { covertAxesToFontVariationsString } from "@/misc/lib-font-utils"

export default {
    instances: (state, getters) => {
        if (getters.font == null) return null
        return getters.font.instances
    },

    getFontById: (state) => (id) => {
        if (state.session == null) return null
        return state.fonts.find((font) => font.id === id)
    },

    fonts: (state, getters) => {
        if (getters.session == null) return null
        return state.fonts
    },

    font: (state, getters) => {
        if (getters.tab == null) return null
        if (getters.tab.fonts.length === 0) return null
        if (getters.tab.selectedFonts.length === 0) return getters.getFontById(getters.tab.fonts[0])
        return getters.getFontById(getters.tab.selectedFonts[0])
    },

    getFirstFontForSession: (state) => (session) => {
        for (let font of state.fonts) {
            if (font.sessionId === session.id) return font
        }
        return null
    },

    fontTabData: (state, getters) => {
        if (getters.fontId == null) return null
        return getters.tab.fontData[getters.fontId]
    },

    Font: (state, getters) => {
        if (getters.fontId == null) return null
        return getters.font.Font
    },

    fontId: (state, getters) => {
        if (getters.tab == null) return null
        if (getters.tab.selectedFonts.length == 0) return null
        return getters.tab.selectedFonts[0]
    },

    fontFaceId: (state, getters) => {
        if (getters.tab == null) return null
        if (getters.tab.selectedFonts.length == 0) return null
        return getters.getFontById(getters.tab.selectedFonts[0]).fontFaceId
    },

    fontAxes: (state, getters) => {
        if (getters.font == null) return {}
        return getters.font.axes
    },

    fullFontName: (state, getters) => {
        if (getters.font.name == null) {
            if (getters.font.filename != null) return getters.font.filename
            return "Empty Font Name"
        }
        return getters.font.name
    },

    fontFamilyName: (state, getters) => {
        let name = getters.font.Font.opentype.tables.name.get(1)
        return name === "." ? "Empty Font Name" : name
    },

    fontFeatures: (state, getters) => {
        if (getters.font == null) return []
        return getters.font.features
    },

    isFontVariable: (state, getters) => {
        if (getters.font == null) return false
        return getters.font.variable == true
    },

    fontVariationSettings: (state, getters) => {
        // let fontVariationSettings = ""
        // for (let axis in getters.axes) {
        //     if (fontVariationSettings !== "") fontVariationSettings += ", "
        //     fontVariationSettings += `"${axis}" ${getters.axes[axis].value}`
        // }
        // return fontVariationSettings
        return covertAxesToFontVariationsString(getters.axes)
    },

    fontTotalGlyphs: (state) => {
        if (state.fontData.meta == null) return 0
        return Object.keys(state.fontData.meta.cmap).length
    },

    isFontUsedByAnyTabs: (state, getters) => (font) => {
        for (let tab of getters.tabs) {
            if (font.id in tab.fonts) return true
        }
        return false
    },
}
