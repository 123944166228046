import appActions from "@/store/actions/app"
import axesActions from "@/store/actions/axes"
import fontActions from "@/store/actions/font"
import notificationActions from "@/store/actions/notification"
import sessionActions from "@/store/actions/session"
import shortcutsActions from "@/store/actions/shortcuts"
import tabActions from "@/store/actions/tab"
import textActions from "@/store/actions/text"
import userActions from "@/store/actions/user"
import userMediaActions from "@/store/actions/userMedia"

export default {
    ...appActions,
    ...axesActions,
    ...fontActions,
    ...notificationActions,
    ...sessionActions,
    ...shortcutsActions,
    ...tabActions,
    ...textActions,
    ...userActions,
    ...userMediaActions,
}
