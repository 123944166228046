<template>
    <div class="notifications-wrapper" :class="{ 'notifications-wrapper--bar-open': $store.getters.font }" v-if="show">
        <transition-group name="notification-list" tag="div" class="notifications" appear>
            <div
                v-for="notification in notifications"
                :key="notification.id"
                class="notification"
                @click="$store.commit('removeNotification', notification)"
                :class="{
                    'notification--status-warning': notification.status == 'warning',
                    'notification--status-danger': notification.status == 'danger',
                    'notification--status-function':
                        notification.status == 'function' || notification.status == 'success',
                    'notification--status-blue': notification.color == 'blue',
                }"
            >
                <div class="flex rw ai-c">
                    <template v-if="notification.type === 'spinner' || notification.icon">
                        <svg
                            v-if="notification.type === 'spinner' || notification.icon === 'spinner'"
                            viewBox="0 0 100 100"
                            :style="{
                                width: notification.type === 'spinner' ? '50px' : '34px',
                                height: notification.type === 'spinner' ? '50px' : '34px',
                            }"
                            :class="{
                                notification__icon: notification.icon === 'spinner',
                            }"
                            class="spinner"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <line x1="18" y1="18" x2="35" y2="35" />
                            <line x1="50" y1="5" x2="50" y2="30" />
                            <line x1="82" y1="18" x2="65" y2="35" />
                            <line x1="70" y1="50" x2="95" y2="50" />
                            <line x1="82" y1="82" x2="65" y2="65" />
                            <line x1="50" y1="70" x2="50" y2="95" />
                            <line x1="18" y1="82" x2="35" y2="65" />
                            <line x1="5" y1="50" x2="30" y2="50" />
                        </svg>
                        <div v-else-if="notification.icon" class="notification__icon" style="flex: 0 0 auto">
                            <span class="icon" v-html="notification.icon"></span>&nbsp;
                        </div>
                    </template>
                    <div v-html="notification.text"></div>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
export default {
    name: "notifications",
    data() {
        return {
            show: false,
        }
    },
    computed: {
        notifications() {
            return this.$store.state.notifications
        },
    },
    mounted() {
        // Delay showing the notifications on load
        setTimeout(() => (this.show = true), 750)
    },
}
</script>

<style lang="scss" scoped>
.notifications-wrapper {
    position: fixed;
    width: 360px;
    right: 0;
    top: 0;
    z-index: 1000;
    margin-right: calc(var(--padding) * 1.5);
    margin-top: calc(var(--padding) * 1.5);
    transition: margin 0.7s ease;

    &--bar-open {
        margin-right: calc(var(--padding) * 2.25);
        margin-top: calc(var(--tab--bar--height) + var(--padding) * 1.5);
    }
}

.notifications {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.notification {
    padding: calc(var(--padding) * 2);
    padding-bottom: calc(var(--padding) * 2.25);
    border-radius: var(--padding);
    background-color: var(--color--light-mid);

    // width: 100%;
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.7s ease, opacity 0.3s, background-color 0.2s ease;

    @include blurBackground(var(--color--blue--light--rgb));

    &:hover {
        @include blurBackground(var(--color--blue--light--hint--rgb));
    }

    &--status-warning {
        @include blurBackground(var(--color--orange--rgb));

        &:hover {
            @include blurBackground(var(--color--orange--hint--rgb));
        }

        * {
            color: white !important;
        }
    }

    &--status-danger {
        // background-color: #d81a1a;
        background-color: var(--color--red);
        * {
            color: white !important;
        }
    }

    &--status-function {
        @include blurBackground(var(--color--green--light--rgb));

        &:hover {
            @include blurBackground(var(--color--green--light--hint--rgb));
        }
    }

    &--status-blue {
        @include blurBackground(var(--color--blue--light--rgb));

        &:hover {
            @include blurBackground(var(--color--blue--light--hint--rgb));
        }
    }

    &__icon {
        padding-right: var(--padding);
        .icon {
            line-height: 1;
            font-size: var(--font-size--l);
        }
    }
}

.notification {
    margin-bottom: var(--padding);
}

.notification-list-enter-active,
.notification-list-leave-active {
}

.notification-list-enter-from {
    transform: translateY(-100px);
    opacity: 0;
}

.notification-list-leave-active {
    position: absolute;
}

.notification-list-leave-to {
    // transform: translateY(-1px);
    opacity: 0;
}
</style>
