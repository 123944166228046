import store from "@/store"

function disableForStandalone() {
    return !store.state.standalone
}

let shortcuts = [
    // GENERAL
    {
        key: "f",
        meta: true,
        event: "togglePresentationMode",
    },

    // TABS
    {
        key: 219, // [
        meta: true,
        shift: true,
        ctrl: () => disableForStandalone(),
        event: "previousTab",
    },
    {
        key: 221, // ]
        meta: true,
        shift: true,
        ctrl: () => disableForStandalone(),
        event: "nextTab",
    },
    {
        key: 84, // t
        meta: true,
        shift: () => disableForStandalone(),
        ctrl: () => disableForStandalone(),
        event: "addTabToCurrentSession",
    },
    {
        key: 87, // w
        meta: true,
        shift: () => disableForStandalone(),
        ctrl: () => disableForStandalone(),
        event: "deleteCurrentTab",
    },

    // TEXT
    {
        keys: ["-"],
        meta: true,
        event: "decreaseFontSize",
        category: "tab",
    },
    {
        keys: ["=", "+", "*"],
        meta: true,
        event: "increaseFontSize",
        category: "tab",
    },
    {
        keys: ["-"],
        meta: true,
        shift: true,
        event: "decreaseLineHeight",
        category: "tab",
    },
    {
        keys: ["=", "+", "*"],
        meta: true,
        shift: true,
        event: "increaseLineHeight",
        category: "tab",
    },
]

export default shortcuts
