import config from "@/config"
import utils from "@/misc/utils"

export default {
    create() {
        return {
            id: utils.uid(),
            sessionId: null,
            created: new Date().getTime(),
            version: config.FONT_VERSION,
            buffer: null,
            name: null,
            filename: null,
        }
    },
}
