<template>
    <section>
        <template v-if="$store.getters.font">
            <object-viewer :thisObject="tables" :level="0" :hideFunctions="true"></object-viewer>
        </template>
    </section>
</template>

<script>
import ObjectViewer from "@/components/Generic/ObjectViewer.vue"

export default {
    name: "font-panle-tab-source",
    title: "Font Source",
    padding: false,
    fullHeight: true,
    components: {
        ObjectViewer,
    },
    computed: {
        tables: function () {
            let tables = {}

            for (let directory of this.$store.getters.font.Font.opentype.directory) {
                // We have to try to get the table
                // In Safari there were problems
                try {
                    let table = this.$store.getters.font.Font.opentype.tables[directory["tag"]]
                    if (table != null) tables[directory["tag"]] = table
                } catch (e) {
                    console.log(e)
                }
            }

            return tables
        },
    },
}
</script>

<style lang="scss" scoped>
section {
    padding: calc(var(--padding) * 2);
}
</style>
