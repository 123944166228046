<template>
    <div class="tab-view">
        <tab-view-bar
            v-if="$store.getters.font"
            :tabs="tabs"
            @updateAdditional="updateAdditional"
            @tabsUpdate="tabsUpdate"
            @closeTab="closeTab"
            :tabTypes="tabTypes"
        >
        </tab-view-bar>
        <div class="tab-view__view">
            <component v-if="$store.getters.font" :is="tabComponent" :tab="tab" :data="tab.data" :key="tab.id">
            </component>
            <start-view v-else> </start-view>
        </div>
    </div>
</template>

<script>
import { markRaw } from "vue"
import View from "@/components/Generic/View.vue"
import TabViewBar from "./TabViewBar.vue"
import TextView from "@/components/Views/TextView/TextView.vue"
import StartView from "@/components/Views/StartView/StartView.vue"

export default {
    extends: View,
    name: "tab-view",
    props: ["tabs", "items", "tabStyle"],
    data() {
        return {
            tabIndex: 0,
            tabTypes: {
                text: {
                    type: "text",
                    component: markRaw(TextView),
                    // default: tabText,
                    allowAdditional: true,
                },
            },
        }
    },
    components: {
        "tab-view-bar": markRaw(TabViewBar),
        "start-view": StartView,
    },
    computed: {
        tabComponent: function () {
            return this.tabTypes[this.$store.getters.tab.type].component
        },
        tab: function () {
            return this.$store.getters.tab
        },
    },
    methods: {
        tabsUpdate(tab) {
            this.$store.dispatch("setCurrentTab", tab)
        },

        closeTab(tabIndex) {
            this.$store.dispatch("deleteTabByIndex", tabIndex)
        },

        updateAdditional(tab) {
            this.$store.dispatch("addTabToCurrentSession", tab)
        },
    },
}
</script>

<style lang="scss" scoped>
.tab-view {
    flex: 1 1 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    &__view {
        flex: 1 1 100%;
        overflow: hidden;
        position: relative;
    }

    &__tab {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        // visibility: hidden;
        // opacity: 0;
        display: none;

        &--open {
            // visibility: visible;
            // opacity: 1;
            display: block;
        }
    }
}
</style>
