import { toRaw } from "vue"
import config from "@/config"
import store from "@/store/index"
import ls from "@/services/ls"

// Schemas
import sessionSchema from "@/db/schemas/session"
import defaultUser from "@/db/schemas/user"
import defaultPreferences from "@/db/schemas/preferences"
import fontSchema from "@/db/schemas/font"

// function syncSchemas(oldSchema, newSchema) {
//     console.log(oldSchema, newSchema)
//     for (let key in oldSchema) {
//         if (key in newSchema) {}
//     }
// }

export default {
    openDb(callback) {
        const schemaVersion = config.INDEXEDDB_VERSION // Update to force setup changes
        const req = window.indexedDB.open(store.state.config.APP_ID, schemaVersion)

        // Setup stores when the user's db is not aligned with current schema
        req.onupgradeneeded = function (e) {
            let db = e.target.result

            if (!db.objectStoreNames.contains("fonts")) {
                let fonts = db.createObjectStore("fonts", {
                    keyPath: "id",
                    autoIncrement: true,
                })
                fonts.createIndex("sessionId", "sessionId")
            }

            if (!db.objectStoreNames.contains("sessions")) {
                db.createObjectStore("sessions", {
                    keyPath: "id",
                    autoIncrement: true,
                })
            }
        }

        req.onerror = function (e) {
            console.log(e)
            callback(true, null)
        }

        req.onsuccess = function (e) {
            let db = e.target.result
            callback(false, db)
        }
    },

    getLastSession() {
        return new Promise((resolve) => {
            this.openDb((err, dbb) => {
                let tx = dbb.transaction("sessions", "readonly")
                let store = tx.objectStore("sessions")
                let cursorRequest = store.openCursor()
                cursorRequest.onsuccess = function (event) {
                    let cursor = event.target.result
                    if (cursor) resolve(cursor.value)
                    else resolve(null)
                }
            })
        })
    },

    saveSession() {
        // console.log("Saving session")
        this.openDb((err, db) => {
            if (err) console.log(err)
            let tx = db.transaction("sessions", "readwrite")
            let sessionStore = tx.objectStore("sessions")

            // Only keep most recent session
            let objectStoreRequest = sessionStore.clear()
            objectStoreRequest.onsuccess = () => {
                // We have to covert the session from a Proxy to its reference object
                let sessionRecord = toRaw(store.state.session)
                // If the user was typing when the session ended, the text is not up to date
                for (let tab of sessionRecord.tabs) if (tab.type === "text") tab.data.text = tab.data.liveText
                sessionStore.put(sessionRecord)
            }
        })
    },

    getUser() {
        let user = ls.getItem("user")
        // user.lastBuildDateUsed = 34556
        // user.version = 0
        let newUser = user
        // Upgrade user if they use an outdated schema version
        if (user == null || user.version !== defaultUser.version) newUser = defaultUser

        // Set useful vars
        // newUser.newVersionVisit = utils.versionIsNewNoPatch(newUser.lastVersionUsed, config.VERSION)
        // newUser.newBuildVisit = utils.buildIsNew(user.lastBuildDateUsed, config.lastBuildDateUsed)
        // console.log(JSON.stringify(user))
        // console.log(JSON.stringify(newUser))
        return newUser
    },

    saveUser() {
        ls.setItem("user", store.state.user.data)
    },

    getPreferences() {
        let preferences = ls.getItem("preferences")
        // Upgrade user if they use an outdated schema version
        if (preferences == null || preferences.version !== defaultPreferences.version) preferences = defaultPreferences
        return preferences
    },

    savePreferences() {
        ls.setItem("preferences", store.state.preferences)
    },

    createSession() {
        let session = sessionSchema.create()
        return session
    },

    createFont() {
        let font = fontSchema.create()
        return font
    },

    saveFont(font) {
        // console.log("Saving FONT")
        this.openDb((err, db) => {
            if (err) console.log(err)
            let tx = db.transaction("fonts", "readwrite")
            let fonts = tx.objectStore("fonts")

            let request = fonts.get(font.id)
            request.onsuccess = function () {
                if (request.result == undefined) {
                    fonts.add(font)
                }
            }
        })
    },

    getFontsForSession(session) {
        return new Promise((resolve, reject) => {
            this.openDb((err, db) => {
                let transaction = db.transaction("fonts")
                let fonts = transaction.objectStore("fonts")
                let fonts_sessionId = fonts.index("sessionId")

                let request = fonts_sessionId.getAll(session.id)

                request.onsuccess = function () {
                    if (request.result !== undefined) {
                        resolve(request.result)
                    } else {
                        reject("No fonts found")
                    }
                }
            })
        })
    },

    deleteOldFonts(session) {
        this.openDb((err, db) => {
            let transaction = db.transaction("fonts", "readwrite")
            let fonts = transaction.objectStore("fonts")
            let request = fonts.openCursor()
            request.onsuccess = function () {
                let cursor = request.result
                if (cursor) {
                    if (cursor.value.sessionId !== session.id) fonts.delete(cursor.key)
                    cursor.continue()
                }
            }
        })
    },
}
