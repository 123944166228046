<template>
    <div class="start-view custom-scrollbar">
        <div class="start-view__modal usn">
            <h2>
                Drop or&nbsp;
                <template v-if="$store.state.window.fileSystemAccess">
                    <button class="h2" @click="$store.dispatch('openFontWithFileSystemDialog')">
                        &nbsp;Open&nbsp;
                    </button>
                </template>
                <template v-else>
                    <label class="button h2">
                        &nbsp;Open&nbsp;
                        <input
                            type="file"
                            style="display: none; position: fixed; top: -100em"
                            @change="(e) => $store.dispatch('openFontFromFileEvent', e)"
                        />
                    </label>
                </template>
                &nbsp;your font to get started<br />
                <template v-if="$store.state.lastSessionExists"
                    >or resume your&nbsp;&nbsp;<button class="h2" @click="$store.dispatch('openLastSession')">
                        &nbsp;Last Session&nbsp;
                    </button></template
                >

                <!-- <button class="button-show" @click="(e) => e.currentTarget.classList.toggle('show')">
                    <div>Test</div>
                    <div class="reveal">
                        <span class="reveal">Show</span>
                    </div>
                </button> -->
            </h2>

            <div v-if="$store.state.server && $store.state.preferences.showDinamoFonts" class="dinamo-fonts">
                Open a Dinamo Variable Font<br /><br />
                <div class="start-view__modal__fonts button-group" style="justify-content: center">
                    <button
                        v-for="(font, f) in dinamoFonts"
                        :key="f"
                        @click="
                            $store.dispatch(`openFontFromUrl`, {
                                url: `/static/fonts/${font.url}`,
                                familyName: font.familyName,
                                disableInstanceGeneration: true,
                            })
                        "
                    >
                        {{ font.name }}
                    </button>
                </div>
            </div>
        </div>
        <div class="start-view__footer usn">
            <span v-if="!$store.state.online">
                <span class="icon">&#57351;</span>&nbsp;You are offline. Some features are limited.<br /><br
            /></span>
            <div class="flex rw jc-c">
                <button class="glow blue" @click="$store.commit('setDashboardTab', 'features')">New Features</button>
                &nbsp;&nbsp;
                <button @click="$store.commit('setDashboardTab', 'settings')">
                    <span class="icon">&#xF013;</span>&nbsp;Settings
                </button>
                &nbsp;&nbsp;
                <button @click="$store.commit('setDashboardTab', 'changelog')">
                    Last updated {{ $store.getters.lastUpdated }}
                </button>
            </div>
            <span>
                <br />
                {{ uiText.privacy }}
            </span>
        </div>
    </div>
</template>

<script>
import uiText from "@/data/uiText.json"

export default {
    name: "start-view",
    props: ["tab", "data"],
    data() {
        return {
            uiText,
            dinamoFonts: [
                {
                    name: "ABC Arizona",
                    familyName: "ABC Arizona Plus Variable",
                    url: "ABCArizonaPlusVariable.woff2",
                },
                {
                    name: "ABC Diatype",
                    familyName: "ABC Diatype Plus Variable",
                    url: "ABCDiatypePlusVariable.woff2",
                },
                {
                    name: "ABC Camera",
                    familyName: "ABC Camera Variable",
                    url: "ABCCameraVariable.woff2",
                },
                {
                    name: "ABC Camera Plain",
                    familyName: "ABC Camera Plain Variable",
                    url: "ABCCameraPlainVariable.woff2",
                },
                {
                    name: "ABC Favorit",
                    familyName: "ABC Favorit Variable",
                    url: "ABCFavoritVariable.woff2",
                },
                {
                    name: "ABC Ginto",
                    familyName: "ABC Ginto Plus Variable",
                    url: "ABCGintoPlusVariable.woff2",
                },
                {
                    name: "ABC Gravity",
                    familyName: "ABC Gravity Variable",
                    url: "ABCGravityVariable.woff2",
                },
                {
                    name: "ABC Helveesti",
                    familyName: "ABC Helveesti Plus Variable",
                    url: "ABCHelveestiPlusVariable.woff2",
                },
                {
                    name: "ABC Marfa",
                    familyName: "ABC Marfa Variable",
                    url: "ABCMarfaVariable.woff2",
                },
                {
                    name: "ABC Maxi",
                    familyName: "ABC Maxi Plus Variable",
                    url: "ABCMaxiPlusVariable.woff2",
                },
                {
                    name: "ABC Monument Grotesk",
                    familyName: "ABC Monument Grotesk Variable",
                    url: "ABCMonumentGroteskVariable.woff2",
                },
                {
                    name: "ABC Prophet",
                    familyName: "ABC Prophet Variable",
                    url: "ABCProphetVariable.woff2",
                },
                {
                    name: "ABC Social",
                    familyName: "ABC Social Plus Variable",
                    url: "ABCSocialPlusVariable.woff2",
                },
                {
                    name: "ABC Synt",
                    familyName: "ABC Synt Variable",
                    url: "ABCSyntVariable.woff2",
                },
                {
                    name: "ABC Whyte",
                    familyName: "ABC Whyte Plus Variable",
                    url: "ABCWhytePlusVariable.woff2",
                },
            ],
        }
    },
}
</script>

<style lang="scss" scoped>
.start-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-bottom: 4%;
    background: var(--color--body);
    position: relative;

    &__modal {
        max-width: 620px;
        text-align: center;

        &__fonts {
            max-width: 520px;
        }
    }

    &__footer {
        position: absolute;
        bottom: 0;
        max-width: 620px;
        text-align: center;
        padding: calc(var(--padding) * 2);
        color: var(--color--dark);
    }

    .dinamo-fonts {
        margin-top: calc(var(--padding) * 5);
    }
}
</style>
