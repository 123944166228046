export default {
    windowLoaded(state) {
        state.window.loaded = true
    },

    togglePresentationMode(state) {
        state.settings.presentationMode = !state.settings.presentationMode
    },

    setTabViewMode(state, viewMode) {
        this.getters.tab.viewMode = viewMode
    },

    setMouseMode(state, active) {
        state.settings.mouseMode = active
    },

    setDownloadingInstance(state, isDownloading) {
        state.downloadingInstance = isDownloading
    },

    setOnline(state, online) {
        state.online = online
        state.server = state.dev ? true : online
    },

    setAppStandalone(state) {
        state.standalone = window.matchMedia("(display-mode: standalone)").matches
    },

    // NOTE: NEED TO WORK ON THIS
    resetApplication() {
        // state.bodyText = defaults.defaultBodyText
    },

    setSidebarOpen(state, open) {
        state.sidebar.open = open
    },

    setGlyphsSearch(state, searchString) {
        state.glyphsSearch = searchString
    },

    setInstanceGenerationDisabled(state, isDisabled) {
        state.instanceGenerationDisabled = isDisabled
    },
}
