export default {
    setCurrentTab(state, tab) {
        state.session.tab = tab.id
    },

    deleteTabByIndex(state, tabIndex) {
        state.session.tabs.splice(tabIndex, 1)
    },

    setTabDataValue(state, data) {
        if (this.getters.tab == null) return
        this.getters.tab.data[data.name] = data.value
        this.dispatch("saveSession")
    },

    setTabFgValue(state, data) {
        if (this.getters.tab == null) return
        if (typeof data.value === "undefined") return
        this.getters.fg[data.name] = data.value
    },

    setTabBgValue(state, data) {
        if (this.getters.tab == null) return
        if (typeof data.value === "undefined") return
        this.getters.bg[data.name] = data.value
    },

    toggleTabFontFeatureSettingsValue(state, name) {
        if (this.getters.tab == null) return
        if (!this.getters.fg.fontFeatureSettings.includes(name)) this.getters.fg.fontFeatureSettings.push(name)
        else this.getters.fg.fontFeatureSettings.splice(this.getters.fg.fontFeatureSettings.indexOf(name), 1)
        this.dispatch("saveSession")
    },

    deleteFontFromTab(state, data) {
        if (data.font.id in data.tab.fontData) delete data.tab.fontData[data.font.id]
        data.tab.fonts = data.tab.fonts.filter((fontId) => fontId !== data.font.id)
        data.tab.selectedFonts = data.tab.selectedFonts.filter((fontId) => fontId !== data.font.id)
    },
}
