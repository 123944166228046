const openTypeFeatures = require("@/data/opentype-features.json")
import { Font } from "../../node_modules/lib-font/lib-font.browser.js"
import utils from "@/misc/utils"

export async function loadFontFromFile(file) {
    await loadFontPre()

    return new Promise((resolve, reject) => {
        // Read file
        let reader = new FileReader()
        reader.onload = (e) => {
            loadFontFromBuffer(e.target.result)
                .then((Font) => resolve(Font))
                .catch((err) => reject(err))
        }
        // Load the file as a buffer
        reader.readAsArrayBuffer(file)
    })
}

export async function loadFontFromBuffer(buffer) {
    await loadFontPre()

    return new Promise((resolve, reject) => {
        // Async function to catch errors
        async function loadFontFromArrayBuffer() {
            try {
                const font = new Font("fgf")
                font.onload = (evt) => resolve(evt.detail.font)
                await font.fromDataBuffer(buffer, "fgf.otf")
            } catch (e) {
                reject(e)
            }
        }
        loadFontFromArrayBuffer()
    })
}

export async function loadFontFromUrl(url) {
    await loadFontPre()

    return new Promise((resolve, reject) => {
        async function loadFontFromUrl() {
            try {
                const font = new Font("fgf")
                font.onload = (e) => {
                    resolve(e.detail.font)
                }
                font.onerror = (e) => {
                    console.log(e)
                    reject(e)
                }
                font.src = url
            } catch (e) {
                console.log(e)
                reject(e)
            }
        }
        loadFontFromUrl()
    })
}

function loadFontPre() {
    return loadWoffLibraries()
}

export function getAxesFromFont(font) {
    let axes = {}
    if (!!font.opentype.tables.fvar == false) return axes

    let supportedAxes = font.opentype.tables.fvar.getSupportedAxes()
    for (let axisIndex in supportedAxes) {
        let axisTag = supportedAxes[axisIndex]
        let axis = font.opentype.tables.fvar.getAxis(axisTag)
        axes[axisTag] = {
            tag: axis.tag,
            index: parseInt(axisIndex),
            name: getUiName(font, axis.axisNameID),
            value: axis.defaultValue,
            valueDefault: axis.defaultValue,
            minDefault: axis.minValue,
            maxDefault: axis.maxValue,
        }

        if (axis.flags.length == 16) axes[axisTag]["hidden"] = axis.flags[15]
    }
    return axes
}

export function getOpenTypeFeatures(Font) {
    const GSUB = Font.opentype.tables.GSUB
    let features = []
    // Some fonts have no GSUB table
    if (GSUB == null) return features

    GSUB.getSupportedScripts().forEach((script) => {
        let table = GSUB.getScriptTable(script)
        GSUB.getSupportedLangSys(table).forEach((langsys) => {
            let table = GSUB.getLangSysTable(script, langsys)
            GSUB.getFeatures(table).forEach((feature) => {
                if (features.find((f) => f.tag === feature.featureTag) != null) return
                features.push({
                    tag: feature.featureTag,
                    name: getFeatureName(Font, feature),
                })
            })
        })
    })
    return features
}

function getFeatureName(Font, feature) {
    let UIname = null
    if (feature.featureTag.startsWith("ss")) {
        const params = feature.getFeatureParams()
        if (params != null && "UINameID" in params) UIname = getUiName(Font, params.UINameID)
    }
    if (UIname == null) UIname = openTypeFeatures[feature.featureTag]
    return UIname
}

export function getSupportedCharCodes(font) {
    // console.log("getSupportedCharCodes")
    let supportedCharCodes = []
    // console.log(font.opentype.tables.cmap.getSubTable(0))
    const supportedCharRanges = font.opentype.tables.cmap.encodingRecords.map((r) => r.table.getSupportedCharCodes())
    for (let supportedCharRange of supportedCharRanges) {
        for (let codeRange of supportedCharRange) {
            for (let i = codeRange.start; i <= codeRange.end; i++) {
                if (!supportedCharCodes.includes(i)) supportedCharCodes.push(i)
            }
        }
    }
    // console.log(supportedCharCodes)
    return supportedCharCodes
}

export function getGlyphs(font) {
    let glyphs = []
    const { cmap, GSUB, post } = font.opentype.tables
    const supportedCharCodes = getSupportedCharCodes(font)

    // console.log("getGlylphd")
    // console.log(font)

    for (let code of supportedCharCodes) {
        let glyphId = cmap.getGlyphId(code)
        let glyphData = cmap.reverse(glyphId)

        let glyphName = null
        // Only post table version 2 support lookups
        if (post.version === 2) {
            glyphName = font.opentype.tables.post.getGlyphName(glyphId)
            if (glyphName[0] === " " || glyphName.length > 30) glyphName = null
        }

        glyphs.push({
            id: glyphId,
            name: glyphName,
            code: code,
            unicode: glyphData.unicode,
            hex: utils.decToHex(code),
            html: utils.decToHTMLUnicode(code),
            features: [],
        })
    }

    // Discover what features glyphs are apart of
    if (GSUB != null) {
        try {
            let scripts = GSUB.getSupportedScripts()
            scripts.forEach((script) => {
                let langsys = GSUB.getSupportedLangSys(script)

                langsys.forEach((lang) => {
                    let langSysTable = GSUB.getLangSysTable(script, lang)
                    let features = GSUB.getFeatures(langSysTable)
                    // let featureCount = features.length

                    features.forEach((feature) => {
                        // console.log(feature)
                        const lookupIDs = feature.lookupListIndices

                        lookupIDs.forEach((id) => {
                            const lookup = GSUB.getLookup(id)
                            if (lang !== "dflt") return
                            // console.log(lookup)

                            for (let i = 0; i < lookup.subTableCount; i++) {
                                const subtable = lookup.getSubTable(i)
                                const coverage = subtable.getCoverageTable()
                                // console.log(subtable)
                                // console.log(coverage)

                                const glyphIds = getGlyphsFromCoverageTable(coverage)

                                for (let glyphId of glyphIds) {
                                    // LookupType 1: Single Substitution Subtable
                                    if (lookup.lookupType === 1) {
                                        const glyph = glyphs.find((g) => g.id === glyphId)
                                        if (glyph != null) {
                                            if (!glyph.features.includes(feature.featureTag))
                                                glyph.features.push(feature.featureTag)
                                        }
                                    }
                                }
                            }

                            // Only dump lookup type 6 for DFLT/dflt
                            //                     if (lookup.lookupType === 6 && lang === "dflt") {
                            //                         for (let i = 0; i < lookup.subTableCount; i++) {
                            //                             let subtable = lookup.getSubTable(i)
                            //
                            //                             console.log(`=====================================================`)
                            //                             console.log(`lookup type 6 in dflt, lookup ${id}, subtable ${i}`)
                            //                             console.log(`=====================================================`)
                            //                             console.log(subtable)
                            //
                            //                             if (subtable.backtrackGlyphCount > 0)
                            //                                 subtable.backtrackCoverageOffsets.forEach((offset, id) => {
                            //                                     let coverage = subtable.getCoverageFromOffset(offset)
                            //                                     console.log(`backtrack coverage ${id + 1}:`, coverage)
                            //                                 })
                            //
                            //                             if (subtable.lookaheadGlyphCount > 0)
                            //                                 subtable.lookaheadCoverageOffsets.forEach((offset, id) => {
                            //                                     let coverage = subtable.getCoverageFromOffset(offset)
                            //                                     console.log(`lookahead coverage ${id + 1}:`, coverage)
                            //                                 })
                            //
                            //                             subtable.seqLookupRecords.forEach((slRecord) => {
                            //                                 console.log(`sequence lookup record:`, slRecord)
                            //                             })
                            //                         }
                            //                     }
                        })
                    })
                })
            })
        } catch (e) {
            console.log(e)
        }
    }

    // console.log(glyphs)
    return glyphs
}

export function getGlyphsFromCoverageTable(coverage) {
    if (coverage.coverageFormat === 1) {
        return coverage.glyphArray
    }

    if (coverage.coverageFormat === 2) {
        let glyphs = []
        for (let rangeRecord of coverage.rangeRecords) {
            for (let glyphId = rangeRecord.startGlyphID; glyphId <= rangeRecord.endGlyphID; glyphId++) {
                glyphs.push(glyphId)
            }
        }
        return glyphs
    }

    return []
}

export function getInstancesFromFont(Font) {
    let instances = []
    if (!Font.opentype.tables.fvar) return instances
    for (let instance of Font.opentype.tables.fvar.instances) {
        instance["name"] = getUiName(Font, instance.subfamilyNameID)
        instance["fontVariationSettings"] = covertAxesToFontVariationsString(
            getInstanceCoordinatesAsAxesWithNames(Font, instance)
        )
        instance["id"] = utils.uid()
        instances.push(instance)
    }
    return instances
}

export function getUiName(Font, id) {
    return Font.opentype.tables.name.get(id)
}

export function getInstanceCoordinatesAsAxes(Font, instance) {
    let axes = {}
    for (let axisIndex in instance.coordinates) {
        let axis = Font.opentype.tables.fvar.axes[axisIndex]
        axes[axis.tag] = instance.coordinates[axisIndex]
    }
    return axes
}

export function getInstanceCoordinatesAsAxesWithNames(font, instance) {
    let axes = getInstanceCoordinatesAsAxes(font, instance)
    for (let axisTag in axes) {
        axes[axisTag] = {
            value: axes[axisTag],
            name: getAxesFromFont(font)[axisTag]["name"],
        }
    }
    return axes
}

export function loadWoffLibraries() {
    return new Promise((resolve) => {
        async function importFunctions() {
            if (window.unbrotliDynamicImport == null) {
                const unbrotli = await import("@/vendor/unbrotli.js")
                window.unbrotliDynamicImport = unbrotli.default
            }
            if (window.inflateDynamicImport == null) {
                const inflate = await import("@/vendor/inflate.js")
                window.inflateDynamicImport = inflate.default.inflate
            }
            resolve()
        }
        importFunctions()
    })
}

export function covertAxesToFontVariationsString(axes) {
    let fontVariationSettings = ""
    for (let axis in axes) {
        if (fontVariationSettings !== "") fontVariationSettings += ", "
        fontVariationSettings += `"${axis}" ${parseFloat(axes[axis].value.toFixed(4))}`
    }
    return fontVariationSettings
}

export function getFontFullName(Font) {
    let name = Font.opentype.tables.name.get(4)
    if (name == null || name === "." || name === "") return null
    return name
}
