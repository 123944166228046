export default {
    runTabShortcut(context, name) {
        if (context.getters.tab == null) return
        context.dispatch(name)
    },

    decreaseFontSize(context) {
        context.dispatch("updateTabFgValue", { name: "fontSize", value: context.getters.fg.fontSize - 10 })
    },

    increaseFontSize(context) {
        context.dispatch("updateTabFgValue", { name: "fontSize", value: context.getters.fg.fontSize + 10 })
    },

    decreaseLineHeight(context) {
        context.dispatch("updateTabFgValue", { name: "lineHeight", value: context.getters.fg.lineHeight - 0.05 })
    },

    increaseLineHeight(context) {
        context.dispatch("updateTabFgValue", { name: "lineHeight", value: context.getters.fg.lineHeight + 0.05 })
    },
}
