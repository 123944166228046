<template>
    <section v-for="(section, s) in sections" :key="s">
        <h4>
            <strong>{{ section.title }}</strong>
        </h4>
        <div v-for="(item, i) in section.items" :key="i" class="item row flex rw jc-sb ai-c item">
            <h5>
                {{ item.name }} <span v-if="item.subtitle" class="subtitle">({{ item.subtitle }})</span>
            </h5>
            <p class="us-t">{{ item.value }}</p>
        </div>
    </section>
</template>

<script>
export default {
    name: "font-panle-tab-info",
    title: "Font Info",
    computed: {
        sectionsData: function () {
            return [
                {
                    title: "General Font Info",
                    test: () => "name" in this.$store.getters.font.Font.opentype.tables,
                    items: [
                        {
                            name: "Font Family Name",
                            subtitle: "ID 1",
                            value: this.$store.getters.font.Font.opentype.tables.name.get(1),
                        },
                        {
                            name: "Font Subfamily Name",
                            subtitle: "ID 2",
                            value: this.$store.getters.font.Font.opentype.tables.name.get(2),
                        },
                        {
                            name: "Unique Font Identifier",
                            subtitle: "ID 3",
                            value: this.$store.getters.font.Font.opentype.tables.name.get(3),
                        },
                        {
                            name: "Full Font Name",
                            subtitle: "ID 4",
                            value: this.$store.getters.font.Font.opentype.tables.name.get(4),
                        },
                        {
                            name: "Version",
                            subtitle: "ID 5",
                            value: this.$store.getters.font.Font.opentype.tables.name.get(5),
                        },
                        {
                            name: "PostScript Name",
                            subtitle: "ID 6",
                            value: this.$store.getters.font.Font.opentype.tables.name.get(6),
                        },
                        {
                            name: "Typographic Family Name",
                            subtitle: "ID 16",
                            value: this.$store.getters.font.Font.opentype.tables.name.get(16),
                        },
                        {
                            name: "Typographic Subfamily Name",
                            subtitle: "ID 17",
                            value: this.$store.getters.font.Font.opentype.tables.name.get(17),
                        },
                        {
                            name: "Vendor ID",
                            value: this.$store.getters.font.Font.opentype.tables["OS/2"].achVendID,
                        },
                    ],
                },
                {
                    title: "Metrics",
                    test: () => "OS/2" in this.$store.getters.font.Font.opentype.tables,
                    items: [
                        {
                            name: "Units per Em",
                            value: this.$store.getters.font.Font.opentype.tables.head.unitsPerEm,
                        },
                        {
                            name: "Cap Height",
                            value: this.$store.getters.font.Font.opentype.tables["OS/2"].sCapHeight,
                        },
                        {
                            name: "x Height",
                            value: this.$store.getters.font.Font.opentype.tables["OS/2"].sxHeight,
                        },
                        {
                            name: "typo Ascender",
                            value: this.$store.getters.font.Font.opentype.tables["OS/2"].sTypoAscender,
                        },
                        {
                            name: "typo Descender",
                            value: this.$store.getters.font.Font.opentype.tables["OS/2"].sTypoDescender,
                        },
                        {
                            name: "typo Line Gap",
                            value: this.$store.getters.font.Font.opentype.tables["OS/2"].sTypoLineGap,
                        },
                        {
                            name: "hhea Ascender",
                            value: this.$store.getters.font.Font.opentype.tables["hhea"].ascender,
                        },
                        {
                            name: "hhea Descender",
                            value: this.$store.getters.font.Font.opentype.tables["hhea"].descender,
                        },
                        {
                            name: "hhea Line Gap",
                            value: this.$store.getters.font.Font.opentype.tables["hhea"].lineGap,
                        },
                        {
                            name: "win Ascender",
                            value: this.$store.getters.font.Font.opentype.tables["OS/2"].usWinAscent,
                        },
                        {
                            name: "win Descent",
                            value: this.$store.getters.font.Font.opentype.tables["OS/2"].usWinDescent,
                        },
                    ],
                },
                {
                    title: "Miscellaneous",
                    test: () => "OS/2" in this.$store.getters.font.Font.opentype.tables,
                    items: [
                        {
                            name: "Number of Glyphs",
                            value: this.$store.getters.font.Font.opentype.tables.maxp.numGlyphs,
                        },
                        {
                            name: "Weight Class",
                            value: this.$store.getters.font.Font.opentype.tables["OS/2"].usWeightClass,
                        },
                        {
                            name: "Width Class",
                            value: this.$store.getters.font.Font.opentype.tables["OS/2"].usWidthClass,
                        },
                        {
                            name: "Italic Angle",
                            value: this.$store.getters.font.Font.opentype.tables["post"].italicAngle,
                        },
                        {
                            name: "Underline Position",
                            value: this.$store.getters.font.Font.opentype.tables["post"].underlinePosition,
                        },
                        {
                            name: "Underline Thickness",
                            value: this.$store.getters.font.Font.opentype.tables["post"].underlineThickness,
                        },
                        {
                            name: "Strikeout Position",
                            value: this.$store.getters.font.Font.opentype.tables["OS/2"].yStrikeoutPosition,
                        },
                        {
                            name: "Strikeout Size",
                            value: this.$store.getters.font.Font.opentype.tables["OS/2"].yStrikeoutSize,
                        },
                    ],
                },
            ]
        },
        sections: function () {
            return this.sectionsData.filter((s) => s.test())
        },
    },
    mounted() {},
}
</script>

<style lang="scss" scoped>
.item {
    border-bottom: 1px solid var(--color--light);
    padding: calc(var(--padding) * 1.5) 0;

    &:last-child {
        border-bottom: 0;
    }

    .subtitle {
        color: var(--color--mid--high);
    }

    p {
        background: var(--color--lighter);
        border-radius: 2px;
        padding: 0 0.2em;
        font-family: var(--font--mono);
    }
}

section {
    margin-bottom: calc(var(--padding) * 3);

    h4 + .item {
        margin-top: calc(var(--padding) * 1);
    }
}
</style>
