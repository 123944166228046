export default {
    showNotification(state, notification) {
        notification.timeout = setTimeout(() => {
            this.commit("removeNotification", notification)
        }, notification.length)

        state.notifications.unshift(notification)

        // Add name to stack of notifications show
        if (notification.name != null) {
            if (!state.notificationsShown.includes(notification.name)) state.notificationsShown.push(notification.name)
        }
    },

    removeNotification(state, notification) {
        for (let i = 0; i < state.notifications.length; i++) {
            if (state.notifications[i].id === notification.id) {
                if (notification.timeout) clearTimeout(notification.timeout)
                state.notifications.splice(i, 1)
                break
            }
        }
    },

    removeOldestNotification(state) {
        state.notifications.shift()
    },
}
