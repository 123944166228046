export default {
    tab: (state, getters) => {
        if (state.session == null) return null
        return getters.getTabById(state.session.tab)
    },

    tabAxis: (state, getters) => (tag) => {
        if (getters.axes[tag] == null) return null
        return getters.axes[tag]
    },

    tabs: (state) => {
        if (state.session == null) return []
        return state.session.tabs
    },

    getTabById: (state) => (id) => {
        for (let tab of state.session.tabs) {
            if (tab.id === id) return tab
        }
        return null
    },

    tabIndex: (state, getters) => {
        if (getters.tab == null) return null
        for (let tabIndex in state.session.tabs)
            if (state.session.tabs[parseInt(tabIndex)].id === getters.tab.id) return parseInt(tabIndex)
        return null
    },

    fg: (state, getters) => {
        if (getters.tab == null) return null
        return getters.tab.data.style.fg
    },

    bg: (state, getters) => {
        if (getters.tab == null) return null
        return getters.tab.data.style.bg
    },

    tabData: (state, getters) => {
        if (getters.tab == null) return null
        return getters.tab.data
    },

    searchString: (state, getters) => {
        if (getters.tab == null) return null
        return getters.tab.data.searchString
    },

    waterfallMode: (state, getters) => {
        if (getters.tabData == null) return false
        if (getters.tabData.waterfallMode == null) return false
        return getters.tabData.waterfallMode
    },

    viewMode: (state, getters) => {
        if (getters.tab == null) return null
        else return getters.tab.viewMode
    },

    mouseMode: (state) => {
        return state.settings.mouseMode
    },

    axesInteractionActive: (state, getters) => {
        return getters.mouseMode || getters.userMediaActive
    },
}
