<template>
    <div class="tabs-buttons flex rw">
        <button
            class="tab__button"
            v-for="(tab, t) in tabs"
            :key="t"
            :class="{ 'tab__button--open': tab.title === currentTab.title }"
            @click="() => $emit('update', tab)"
        >
            {{ tab.title }}&nbsp;&nbsp;&nbsp;
        </button>
    </div>
</template>

<script>
export default {
    name: "sidebar-tabs",
    props: ["tabs", "currentTab"],
}
</script>

<style lang="scss" scoped>
.tabs-buttons {
    flex: 0 0 auto;
    padding: calc(var(--padding) / 2) var(--padding) calc(var(--padding) / 1.5) var(--padding);

    .tab__button {
        @include button-plain;
        font-weight: bold;
        opacity: 0.4;
        transition: opacity 0.2s ease;

        &:hover {
            opacity: 0.6s;
        }

        &--open {
            opacity: 1 !important;
        }
    }
}
</style>
