import appGetters from "@/store/getters/app"
import axesGetters from "@/store/getters/axes"
import fontGetters from "@/store/getters/font"
import sessionGetters from "@/store/getters/session"
import tabsGetters from "@/store/getters/tabs"
import userMediaGetters from "@/store/getters/userMedia"

export default {
    ...appGetters,
    ...axesGetters,
    ...fontGetters,
    ...tabsGetters,
    ...sessionGetters,
    ...userMediaGetters,
}
