<template>
    <div class="font-info">
        <div class="font-info__logo" style="display: flex; flex-direction: row; align-items: center">
            <a href="http://www.abcdinamo.com" target="_blank">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="13"
                    viewBox="0 0 1060.37 168"
                    style="display: block; margin-right: 2px"
                >
                    <g style="fill: red">
                        <path
                            d="M976.8,124.6c-22.1,0-40.1-17.9-40.1-40.1c0-22.1,17.9-40.1,40.1-40.1c22.1,0,40.1,17.9,40.1,40.1
                            c0,22.1-17.8,40-39.9,40.1C977,124.6,976.9,124.6,976.8,124.6 M976.8,1c-46.1,0-83.5,37.4-83.5,83.5s37.4,83.5,83.5,83.5
                            c46.1,0,83.5-37.4,83.5-83.5C1060.3,38.4,1022.9,1,976.8,1 M812.5,1c-22.5,0.1-40.6,18.3-40.6,40.8v85.4c0,8.4-6.8,15.2-15.3,15.2
                            c-8.4,0-15.2-6.8-15.2-15.2V41.8c0-22.5-18.2-40.7-40.7-40.7c-22.5,0-40.7,18.2-40.7,40.7c0,0,0,0,0,0V168h25.4V41.8
                            c0-8.4,6.8-15.2,15.3-15.2c8.4,0,15.2,6.8,15.2,15.2v85.4c0,22.5,18.2,40.7,40.7,40.7c22.5,0,40.7-18.2,40.7-40.7c0,0,0,0,0,0
                            V41.8c0-8.4,6.8-15.2,15.3-15.2c8.4,0,15.2,6.8,15.2,15.2V168h25.4V41.8C853.3,19.2,835,1,812.5,1C812.5,1,812.5,1,812.5,1
                             M576.4,116.2c0,17.5-14.2,31.7-31.7,31.7S513,133.7,513,116.2s14.2-31.7,31.7-31.7l0,0C562.2,84.5,576.3,98.6,576.4,116.2
                            C576.4,116.1,576.4,116.1,576.4,116.2 M544.7,0c-4.8-0.1-9.2,2.8-11,7.2L464.5,168h160.3L555.7,7C553.7,2.8,549.4,0,544.7,0
                             M418.5,1v123.8c-0.5,13-11.5,23.1-24.5,22.5c-12.2-0.5-22-10.3-22.5-22.5V44.2c0-23.9-19.4-43.2-43.2-43.2
                            c-23.8,0-43.2,19.3-43.2,43.2V168h19.7V44.2c0.5-13,11.5-23.1,24.5-22.5c12.2,0.5,22,10.3,22.5,22.5v80.6
                            c0,23.9,19.4,43.2,43.2,43.2c23.8,0,43.2-19.3,43.2-43.2V1H418.5z M207.3,26.2c11.1,0.1,20,9.1,19.9,20.2c0,11-8.9,19.9-19.9,19.9
                            s-19.9-8.9-19.9-19.9c0,0,0,0,0,0C187.4,35.3,196.3,26.2,207.3,26.2 M177.8,168h59V1h-59V168z M56.6,149h-7.4
                            c-10.8,0-19.6-8.8-19.7-19.7V39.6C29.4,29,37.7,20.3,48.2,19.7h8.4c35.7,0,64.7,29,64.7,64.7S92.4,149,56.6,149 M56.6,1H0v167
                            h56.6c46.1-1,82.7-39.2,81.7-85.3C137.3,38,101.3,2,56.6,1"
                        />
                    </g>
                </svg>
            </a>
            <h5 class="usn" style="color: blue">&nbsp;Font Gauntlet</h5>
        </div>

        <div class="button-group" style="flex-wrap: nowrap">
            <template v-if="$store.getters.font">
                <button
                    class="flex rw ai-c"
                    @click="$store.commit('togglePanel', 'font')"
                    style="flex: 1 1 auto; text-align: left; justify-content: space-between; overflow: hidden"
                >
                    <span class="flex rw ai-c" style="overflow: hidden">
                        <span class="icon flash" style="flex: 0 0 auto">&#xF111;&nbsp;</span>
                        <span class="text-overflow-ellipsis" style="flex: 1 1 auto">{{
                            $store.getters.fullFontName
                        }}</span>
                        <span v-if="$store.getters.isFontVariable" style="opacity: 0.5; flex: 0 0 auto"
                            >&nbsp;(VF)</span
                        >
                    </span>
                    <span>&nbsp;&nbsp;<span class="icon">&#128270;</span></span>
                </button>
                <button class="button--square" @click="$store.dispatch('closeSession')" style="flex: 0 0 auto">
                    <span class="icon">&#xf00d;</span>
                </button>
            </template>
            <label
                v-else
                class="button"
                style="flex: 1 1 auto; text-align: left; justify-content: space-between; overflow: hidden"
            >
                <span style="overflow: hidden">
                    <span class="icon" style="flex: 0 0 auto; color: #bbb">&#xF111;&nbsp;</span>
                    <span class="text-overflow-ellipsis" style="flex: 1 1 auto">Open Your Font</span>
                </span>
                <input
                    type="file"
                    style="display: none; position: fixed; top: -100em"
                    @change="(e) => $store.dispatch('openFontFromFileEvent', e)"
                />
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: "font-info",
}
</script>

<style lang="scss">
.font-info {
    padding: calc(var(--padding) * 0.6) 0 calc(var(--padding) * 1) 0;
    &__logo {
        margin-bottom: var(--padding);
    }
}

@keyframes flash {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.flash {
    color: var(--color--green---mid);
    animation-name: flash;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-play-state: running;
}
</style>
