import utils from "@/misc/utils"

export default {
    showNotification(context, data) {
        let notification = {
            id: utils.uid(),
            text: data.text,
            status: data.status || "default",
            type: data.type,
            icon: data.icon,
            once: data.once == true,
            name: data.name || null,
            color: data.color || null,
        }

        // Check if the notification has already been shown,
        // And should not be shown again
        if (context.state.notificationsShown.includes(notification.name)) return

        let length = 4000
        if ("length" in data) {
            if (data.length === "quick") length = 1500
            else if (data.length === "infinite") length = 100000000
            else length = data.length
        }
        notification.length = length

        context.commit("showNotification", notification)
    },
}
