import { createTabText } from "@/store/defaults/tabText"

export default {
    setCurrentTab(context, tab) {
        context.dispatch("leavingTab", context.getters.tab)
        context.commit("setCurrentTab", tab)
        context.dispatch("saveSession")
    },

    previousTab(context) {
        let tabIndex = context.getters.tabIndex
        if (tabIndex == null) return
        if (context.getters.tabs.length === 1) return
        if (tabIndex === 0) return
        context.dispatch("setCurrentTab", context.getters.tabs[tabIndex - 1])
    },

    nextTab(context) {
        let tabIndex = context.getters.tabIndex
        if (tabIndex == null) return
        if (context.getters.tabs.length === 1) return
        if (tabIndex === context.getters.tabs.length - 1) return
        context.dispatch("setCurrentTab", context.getters.tabs[tabIndex + 1])
    },

    deleteTabByIndex(context, tabIndex) {
        let tabToDelete = context.state.session.tabs[tabIndex]

        // Regardless of whether we're closing the session or the tab
        // If the current tab is being closed we need to run it's close hook
        if (tabToDelete.id === context.getters.tab.id) context.dispatch("leavingTab", context.getters.tab)

        if (context.state.session.tabs.length > 1) {
            if (tabToDelete.id === context.getters.tab.id) {
                if (tabIndex === 0) context.dispatch("setCurrentTab", context.state.session.tabs[1])
                else context.dispatch("setCurrentTab", context.state.session.tabs[tabIndex - 1])
            }

            context.commit("deleteTabByIndex", tabIndex)
            context.dispatch("saveSession")
        } else {
            // If user is closing last tab, Close the font
            // (We remember the last tab so if they open another font, it's shown the same way)
            context.dispatch("closeSession")
        }
    },

    deleteCurrentTab(context) {
        let tabIndex = context.getters.tabIndex
        if (tabIndex == null) return
        context.dispatch("deleteTabByIndex", tabIndex)
    },

    leavingTab(context) {
        context.dispatch("stopTabAxisInteractions")
    },

    stopTabAxisInteractions(context) {
        context.dispatch("stopAnimation")
        context.dispatch("resetUserMedia")
        context.dispatch("stopMouseMode")
    },

    async addTabToCurrentSession(context) {
        let tab = createTabText()
        context.dispatch("addFontToTab", {
            tab,
            font: context.getters.getFirstFontForSession(context.state.session),
        })
        context.state.session.tabs.push(tab)
        context.dispatch("setCurrentTab", tab)
    },

    updateTabFgValue(context, data) {
        if (context.getters.tab == null) return
        if (typeof data.value === "undefined") return
        if (data.value === context.getters.fg[data.name]) return // Don't update if the value is the same

        // Check if value has a min/max and the value if outside this range
        if (data.name in context.state.defaults && typeof data.value == "number") {
            let defaults = context.state.defaults[data.name]
            if ("min" in defaults && data.value < context.state.defaults[data.name].min) data.value = defaults.min
            if ("max" in defaults && data.value > context.state.defaults[data.name].max) data.value = defaults.max
        }

        context.commit("setTabFgValue", data)
        context.dispatch("saveSession")
    },

    updateTabBgValue(context, data) {
        if (context.getters.tab == null) return
        if (typeof data.value === "undefined") return
        if (data.value === context.getters.bg[data.name]) return // Don't update if the value is the same
        context.commit("setTabBgValue", data)
        context.dispatch("saveSession")
    },

    toggleTabDataValue(context, data) {
        if (context.getters.tab == null) return
        let defaultValue = data.defaultValue != null ? data.defaultValue : false
        let value = context.getters.tab.data[data.name] == null ? !defaultValue : !context.getters.tab.data[data.name]
        context.commit("setTabDataValue", { name: data.name, value })
    },

    toggleTabForegroundBoolean(context, name) {
        if (context.getters.fg == null) return
        context.dispatch("updateTabFgValue", {
            name,
            value: !context.getters.fg[name],
        })
    },

    toggleTabForegroundValue(context, data) {
        // console.log(data)
        if (context.getters.fg == null) return
        let currentValue = context.getters.fg[data.name]
        let newValue

        // If 2 values are supplied, toggle between them
        if (data.values != null && data.value == null && data.values.length === 2) {
            newValue = data.values[0]
            if (data.values.indexOf(currentValue) === 0) newValue = data.values[1]
            // If a single value was passed
            // We fallback to ""
        } else {
            if (currentValue === data.value) newValue = ""
            else newValue = data.value
        }

        context.dispatch("updateTabFgValue", {
            name: data.name,
            value: newValue,
        })
    },

    switchBodyTextColors(context) {
        if (context.getters.bg == null) return
        let bgColor = this.getters.bg.backgroundColor
        context.dispatch("updateTabBgValue", {
            name: "backgroundColor",
            value: context.getters.fg.color,
        })
        context.dispatch("updateTabFgValue", {
            name: "color",
            value: bgColor,
        })
    },

    switchToInstance(context, instance) {
        for (let axisIndex in instance.coordinates) {
            let axis = context.getters.getAxisByIndex(axisIndex)
            context.dispatch("updateAxisValue", {
                axis,
                value: instance.coordinates[axisIndex],
            })
            // Reset crop of axes, as the instance may be outside the crop
            context.commit("resetAxisAnimatingCrop", axis["tag"])
        }

        // If Style Range View is active, switch to Default view
        if (context.getters.tab.viewMode === "style-range") context.dispatch("updateTabViewMode", null)
    },

    forceUpdateTabTextValue(context, text) {
        if (text === context.getters.tab.data.liveText && text === context.getters.tab.data.text) return // Don't update for no reason
        context.commit("setTabDataValue", { name: "text", value: text })
        context.commit("setTabDataValue", { name: "liveText", value: text })
    },

    autoSizeText(context) {
        if (context.getters.viewMode !== null) return

        let currentSize = context.getters.fg.fontSize
        let maxWidth = document.querySelector(".text-view__body__inner").clientWidth
        let textWidth = document.getElementById("virtual-text").getBoundingClientRect().width
        let size = (maxWidth / textWidth) * currentSize * 0.9

        if (size < 80) size = 80

        context.dispatch("updateTabFgValue", {
            name: "fontSize",
            value: size,
        })
    },

    async toggleAutoSizeText(context) {
        await context.dispatch("toggleTabDataValue", { name: "autoSizeText" })
        if (context.getters.tab.data.autoSizeText) context.dispatch("autoSizeText")
    },

    syncTabText(context) {
        // console.log("syncTabText")
        // console.log(context.getters.tab.data.text, context.getters.tab.data.liveText)
        context.getters.tab.data.text = context.getters.tab.data.liveText
        // console.log(context.getters.tab.data)
    },

    updateTabViewMode(context, viewMode) {
        if (viewMode === "" || viewMode === "default") viewMode = null

        context.dispatch("stopTabAxisInteractions")

        if (viewMode === "glyphs") {
            context.dispatch("updateTabFgValue", { name: "fontSize", value: 80 })
        }

        context.commit("setTabViewMode", viewMode)
    },

    updateTabSearchString(context, searchString) {
        context.commit("setTabDataValue", { name: "searchString", value: searchString })
    },
}
