<template>
    <div class="glyphs-overview" :style="gostyle">
        <RecycleScroller
            class="scroller"
            :items="rows"
            :item-size="rowHeight"
            v-slot="{ item }"
            :buffer="400"
            keyField=""
            page-mode
        >
            <div
                class="glyphs-overview__row"
                :style="{ height: `${rowHeight}px`, gridTemplateColumns: `repeat(${columns}, 1fr)` }"
            >
                <div class="glyph" v-for="(glyph, g) in item" :key="g">
                    <div class="glyph__inner" @click="copyGlyph(glyph)">
                        <span v-html="glyph.html"></span>
                    </div>
                    <div class="glyph__label" :style="{ 'font-size': labelFontSize }">
                        <span v-if="glyph.name" class="text-overflow-ellipsis">{{ glyph.name }}</span>
                        <span v-else class="text-overflow-ellipsis tt--u">{{ glyph.hex }}</span>
                        <a
                            v-if="glyph.hex && columns < 12"
                            class="glyph__label__code tt--u"
                            target="_blank"
                            :href="`https://unicode-table.com/en/${glyph.hex}`"
                        >
                            &nbsp;&nbsp;{{ glyph.hex }}
                        </a>
                    </div>
                </div>
            </div>
        </RecycleScroller>
    </div>
</template>

<script>
export default {
    name: "glyphs-overview",
    props: ["tab", "style"],
    data() {
        return {
            gridScalar: 1.8,
        }
    },
    computed: {
        fontSize: function () {
            let fontSize = this.tab.data.style.fg.fontSize
            if (fontSize > 200) fontSize = 200
            if (fontSize < 28) fontSize = 28
            return fontSize
        },

        labelFontSize: function () {
            if (this.fontSize <= 28) return "9px"
            if (this.fontSize <= 34) return "10px"
            return null
        },

        gostyle: function () {
            let style = this.style
            style.fontSize = `${this.fontSize}px`
            return style
        },

        columns: function () {
            return Math.round(
                (window.innerWidth - this.$store.state.sidebar.width) / (this.fontSize * this.gridScalar * 1.05)
            )
        },

        searchString: function () {
            return this.$store.getters.searchString
        },

        glyphs: function () {
            let glyphs = this.$store.getters.font.glyphs
            if (this.searchString != null && this.searchString != "") {
                let newGlyphs = []
                for (let glyph of glyphs) {
                    if (this.isGlyphDisplayed(glyph)) newGlyphs.push(glyph)
                }
                glyphs = newGlyphs
            }
            return glyphs
        },

        rowHeight: function () {
            return this.fontSize * this.gridScalar + 15
        },

        rows: function () {
            let rows = []
            for (let i = 0; i < this.glyphs.length; i += this.columns) {
                let chunk = this.glyphs.slice(i, i + this.columns)
                rows.push(chunk)
            }
            return rows
        },

        padding: function () {
            return 10
        },
    },
    methods: {
        isGlyphDisplayed(glyph) {
            if (this.searchString === "") return true
            if (glyph.name != null && glyph.name.toLowerCase().includes(this.searchString)) return true
            if (glyph.hex != null && glyph.hex.toLowerCase().includes(this.searchString)) return true
            return false
        },

        copyGlyph(glyph) {
            if (glyph.unicode != null) navigator.clipboard.writeText(glyph.unicode)
        },
    },
}
</script>

<style lang="scss" scoped>
.glyphs-overview {
    --border: 1px solid var(--color--light);
    padding: var(--padding);
    padding-bottom: calc(var(--padding) * 3);

    &__row {
        display: grid;
        // border-bottom: var(--border);
    }

    .glyph {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: stretch;
        // border-right: var(--border);
        position: relative;
        // padding: calc(var(--padding) * 2);
        // padding-top: calc(var(--padding) * 3);

        &:last-child {
            border-right: 0;
        }

        &__inner {
            flex: 1 1 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: var(--border-radius);
            transition: background-color 0.6s ease;

            &:active {
                transition: background-color 0s ease;
                background-color: var(--color--lighter);
            }
        }

        &__label {
            position: absolute;
            bottom: 0;
            width: 100%;
            flex: 0 0 auto;
            padding: 0 calc(var(--padding) * 0.5);
            @include uit;
            text-align: center;
            overflow: hidden;
            display: flex;
            white-space: nowrap;
            justify-content: center;

            &__code {
                color: var(--color--dark);
            }
        }
    }
}
</style>
