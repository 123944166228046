<template>
    <div class="sidebar__sub-section-wrapper" v-if="$store.getters.isFontVariable && instances && instances.length > 0">
        <label class="c-select" style="width: 100%; display: flex; justify-content: space-between">
            <span class="text-overflow-ellipsis" v-if="currentInstance != null"
                >Current: {{ currentInstance.name }}</span
            >
            <span class="text-overflow-ellipsis" v-else>Select Style</span>
            <select @change="onChange">
                <option :value="10000" disabled selected style="display: none">Current Position</option>
                <option v-for="(instance, i) in instances" :key="i" :value="i">
                    {{ instance.name }}
                </option>
            </select>
            <span class="icon small">&#9660;</span>
        </label>
    </div>
</template>

<script>
export default {
    name: "instances",
    computed: {
        instances: function () {
            return this.$store.getters.instances
        },
        currentInstance: function () {
            return this.$store.getters.doesCurrentAxesPositionsMatchAnInstance
        },
    },
    methods: {
        downloadInstance(instance) {
            this.$store.dispatch("downloadInstance", instance)
        },

        onChange(e) {
            this.switchToInstance(e.target.selectedOptions[0].value)
            e.target.value = 10000
        },

        switchToInstance(instanceIndex) {
            this.$store.dispatch("switchToInstance", this.$store.getters.instances[instanceIndex])
        },
    },
}
</script>
