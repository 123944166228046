export default {
    setUserMediaStream(state, stream) {
        state.userMedia.stream = stream
    },

    setUserMediaAnalyser(state, analyser) {
        state.userMedia.analyser = analyser
    },

    setUserMediaAxes(state, axes) {
        state.userMedia.axes = axes
    },

    pushUserMediaAxes(state, axisTag) {
        state.userMedia.axes.push(axisTag)
    },
}
