<template>
    <div>
        <div class="grid grid--row-2">
            <button
                v-for="(button, b) in buttons"
                :key="b"
                class="text-overflow-ellipsis"
                :class="{ offline: !$store.getters[button.test] }"
                :disabled="!$store.getters[button.test]"
                @click="getSampleText(button)"
            >
                <span class="dota" v-if="button.loading && button.indicateLoading"
                    ><span>•</span><span>•</span><span>•</span></span
                >
                <span v-else>{{ button.name }}</span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "sample-text",
    data() {
        return {
            buttons: [
                {
                    name: "Title",
                    apiPath: "/title",
                    test: "server",
                    loading: false,
                },
                {
                    name: "Pangram",
                    apiPath: "/pangram",
                    test: "server",
                    loading: false,
                },
                {
                    name: "Paragraph",
                    apiPath: "/paragraph",
                    test: "server",
                    loading: false,
                },
                {
                    name: "Wikipedia",
                    apiPath: "/wikipedia",
                    loading: false,
                    test: "online",
                    indicateLoading: true,
                },
            ],
        }
    },
    methods: {
        async getSampleText(button) {
            if (button.loading) return
            button.loading = true
            await this.$store.dispatch("setTextFromApiPath", "/text" + button.apiPath)
            button.loading = false
        },
    },
}
</script>
