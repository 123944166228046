<template>
    <div class="section">
        <div class="row flex rw jc-sb ai-c" v-for="(preference, p) in preferences" :key="p">
            <h5>{{ preference.name }}</h5>

            <button
                v-if="preference.type === 'toggle'"
                class="button"
                @click="$store.commit('togglePreferenceValue', preference.key)"
            >
                <span v-if="$store.state.preferences[preference.key]" class="icon">&#xF111;</span>
                <span v-else class="icon" style="color: #999999">&#xF192;</span>&nbsp;
                <span
                    v-if="$store.state.preferences[preference.key]"
                    v-html="(preference.text && preference.text.true) || 'On'"
                ></span>
                <span v-else v-html="(preference.text && preference.text.false) || 'Off'"></span>
            </button>

            <div v-else-if="preference.type === 'select'" class="button-group">
                <button
                    v-for="(optionName, optionValue) in preference.options"
                    :key="optionValue"
                    :class="{
                        active: $store.state.preferences[preference.key] == optionValue,
                    }"
                    @click="$store.commit('setPreferenceValue', { name: preference.key, value: optionValue })"
                    v-html="optionName"
                ></button>
            </div>
        </div>
    </div>

    <div class="section bleed">
        <h4><br /><br />Shortcuts<br /><br /></h4>

        <div v-for="(shortcut, s) in shortcuts" :key="s" class="shortcut-row flex rw jc-sb ai-c">
            <h5>{{ shortcut.name }}</h5>
            <div>
                <span v-for="(key, k) in shortcut.keys" :key="k"
                    ><span v-if="k > 0">&nbsp;&nbsp;</span><span class="key">{{ key }}</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "settings",
    title: "Settings",
    width: 560,
    data() {
        return {
            preferences: [
                // {
                //     name: "Dark Mode",
                //     key: "darkMode",
                //     type: "toggle",
                // },
                {
                    name: "Show Dinamo Fonts on start page",
                    key: "showDinamoFonts",
                    type: "toggle",
                    text: {
                        true: `<span class="icon">&#57360;</span>`,
                        false: `<span class="icon">&#57361;</span>`,
                    },
                },
                {
                    name: "Default Text Direction",
                    key: "defaultTextDirection",
                    type: "select",
                    options: {
                        ltr: "&nbsp;LTR&nbsp;",
                        rtl: "&nbsp;RTL&nbsp;",
                    },
                },
                {
                    name: "Show Hidden Font Axes",
                    key: "showHiddenFontAxes",
                    type: "toggle",
                },
                // {
                //     name: "New Tab Text",
                //     key: "newTabText",
                //     type: "select",
                //     options: {
                //         overview: "Overview",
                //         pangram: "Pangram",
                //         title: "Title",
                //         paragraph: "Paragraph",
                //     },
                // },
            ],
            shortcuts: [
                { keys: ["CMD", "+"], name: "Increase font size" },
                { keys: ["CMD", "−"], name: "Decrease font size" },
                { keys: ["CMD", "SHIFT", "+"], name: "Increase line height" },
                { keys: ["CMD", "SHIFT", "−"], name: "Decrease line height" },
            ],
        }
    },
}
</script>

<style lang="scss" scoped>
.row {
    border-bottom: 1px solid var(--color--light);
    padding: calc(var(--padding) * 1.5) 0;

    &:last-child {
        border-bottom: 0;
    }
}

.shortcut-row {
    padding: calc(var(--padding) * 1.5) 0;
    border-bottom: 1px solid var(--color--light);

    &:last-child {
        border-bottom: 0;
    }
}

.key {
    @include button;
    border-radius: 5px;
}
</style>
