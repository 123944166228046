<template>
    <div
        class="sidebar__section"
        :class="{
            'sidebar__section--tabs': section.tabs,
            'sidebar__section--disabled': isSectionDisabled(),
        }"
        :style="section.style"
    >
        <sidebar-tabs
            v-if="section.tabs"
            :tabs="section.tabs"
            :currentTab="currentTab"
            @update="handleTabClick"
        ></sidebar-tabs>

        <div class="sidebar__section__inner" v-if="section.tabs">
            <component :is="currentTab.component"></component>
        </div>

        <div class="sidebar__section__inner" v-if="section.tabs == null">
            <component :is="section.component"></component>
        </div>
    </div>
</template>

<script>
import SidebarTabs from "@/components/Sidebar/SidebarTabs.vue"

export default {
    name: "sidebar-section",
    props: ["section"],
    components: {
        SidebarTabs,
    },
    data() {
        return {
            currentTab: this.section.tabs ? this.section.tabs[0] : null,
        }
    },
    methods: {
        handleTabClick(tab) {
            this.currentTab = tab
        },

        isSectionDisabled() {
            if (this.$store.getters.tab == null) return false
            if (this.section.disabled == null) return false
            return this.section.disabled.includes(this.$store.getters.tab.viewMode)
        },
    },
}
</script>

<style lang="scss" scoped>
.sidebar__section {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--color--border);

    &:last-child {
        border: 0;
    }

    &__inner {
        padding: var(--padding);
        padding-bottom: calc(var(--padding) * 1.25);
    }

    &--tabs {
        .sidebar__section__inner {
            padding-top: 0;
        }
    }

    &--disabled {
        .sidebar__section__inner {
            @include disabled;
        }
    }
}
</style>
