import "@/styles/vars.scss"
import "@/styles/index.scss"
import { createApp } from "vue"
import App from "@/App.vue"
import store from "@/store"
import "@/services/serviceWorker"

const app = createApp(App)
app.use(store)
app.mount("#app-wrapper")

// Virtual Scroller
import VueVirtualScroller from "vue-virtual-scroller"
app.use(VueVirtualScroller)
import "vue-virtual-scroller/dist/vue-virtual-scroller.css"

// Object Viewer (for Font Source)
import ObjectViewer from "@/components/Generic/ObjectViewer.vue"
app.component("object-viewer-recursive", ObjectViewer)

// These can be initialised after mounting
import "@/services/KeyHandler"
import "@/services/internetConnection"
