import shortcuts from "@/data/shortcuts"
import store from "@/store"

class KeyHandler {
    constructor() {
        document.addEventListener("keydown", (e) => {
            // Update if the app is standalone of not
            // A bit silly we do it here, but there's no event for this
            store.commit("setAppStandalone")

            for (let shortcut of shortcuts) {
                if (this.isShortcut(shortcut, e)) {
                    this.runShortcut(e, shortcut)
                    break
                }
            }
        })
    }

    runShortcut(e, shortcut) {
        e.preventDefault()
        if (shortcut.category === "tab") store.dispatch("runTabShortcut", shortcut.event)
        else store.dispatch(shortcut.event)
    }

    isShortcut(shortcut, e) {
        // console.log(e)
        if ("keys" in shortcut) {
            if (!shortcut.keys.includes(e.key) && !shortcut.keys.includes(e.keyCode)) return false
        } else {
            if (e.key !== shortcut.key && e.keyCode !== shortcut.key) return false
        }
        if (!e.metaKey && "meta" in shortcut && shortcut.meta === true) return false
        if (!e.shiftKey && "shift" in shortcut && shortcut.shift === true) return false
        if (!e.ctrlKey && "ctrl" in shortcut && shortcut.ctrl === true) return false

        if (e.metaKey && shortcut["meta"] == null) return false
        if (e.shiftKey && shortcut["shift"] == null) return false
        if (e.ctrlKey && shortcut["ctrl"] == null) return false

        return true
    }
}

export default new KeyHandler()
