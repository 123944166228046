import config from "@/config"

export default {
    getItem(key) {
        try {
            return JSON.parse(localStorage.getItem(`${config.APP_ID}-${key}`))
        } catch {
            return null
        }
    },

    setItem(key, data) {
        localStorage.setItem(`${config.APP_ID}-${key}`, JSON.stringify(data))
    },
}
