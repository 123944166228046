<template>
    <div>
        <div class="grid grid--row-2">
            <button class="text-overflow-ellipsis" @click="$store.dispatch('changeTextToSet', 'all')">
                Entire Font
            </button>
            <button
                class="text-overflow-ellipsis"
                v-for="set in textSets"
                :key="set.name"
                :class="{ offline: !$store.getters.server }"
                :disabled="!$store.getters.server"
                @click="$store.dispatch('setTextFromApiPath', `/text-set/${set.apiPath}`)"
            >
                {{ set.name }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "character-sets",
    data() {
        return {
            textSets: [
                { apiPath: "overview", name: "Overview" },
                { apiPath: "macos-roman", name: "MacOS Roman" },
                { apiPath: "windows-1252", name: "Windows 1252" },
                { apiPath: "basic-latin", name: "Basic Latin" },
                { apiPath: "latin-1-supplement", name: "Latin Supplement" },
                { apiPath: "latin-extended-a", name: "Latin Extended-A" },
            ],
        }
    },
}
</script>
