function textToLineChunks(ctx, lineWidth, text) {
    const __BR__ = "__BR__"
    text = text.replace(/(<div(?:.*?)><br>)/g, " " + __BR__ + " ")
    text = text.replace(/(<(?:br|div)(?:.*?)>)/g, " " + __BR__ + " ")
    text = text.replace(/(<([^>]+)>)/g, "")
    text = text.replace("&gt;", ">")
    text = text.replace("&lt;", "<")
    // console.log(text)

    let chunksRaw = text.split(/([\s-–—])/)
    chunksRaw = chunksRaw.filter((t) => t !== "")

    let chunks = []
    for (let n = 0; n < chunksRaw.length; n++) {
        let chunk = chunksRaw[n]

        if (chunk !== __BR__) {
            let chunkLength = ctx.measureText(chunk).width
            if (chunkLength > lineWidth) {
                const chars = chunk.split("")
                chunks = chunks.concat(chars)
            } else {
                chunks.push(chunk)
            }
        } else {
            chunks.push(chunk)
        }
    }

    return chunks
}

export function textBox(
    ctx,
    text,
    x,
    y,
    width,
    height,
    fontSize,
    lineHeight,
    fontFace,
    textAlign = "start",
    textAlignVertical = "top",
    color = "black"
) {
    // console.log("textBox")
    // console.log(text, x, y, width, height, fontSize, fontFace, textAlign, textAlignVertical, color)

    lineHeight = fontSize * lineHeight

    ctx.fillStyle = color
    ctx.font = `${fontSize}px "${fontFace}"`
    ctx.textBaseline = "bottom"
    ctx.textAlign = textAlign

    // y += lineHeight
    if (textAlign === "center") x = x + width / 2
    else if (textAlign === "right") x = x + width

    let chunks = textToLineChunks(ctx, width, text)
    let currentLine = ""
    let lines = []
    let textBlockHeight = 0
    // console.log(chunks)

    function addLine() {
        lines.push(currentLine)
        textBlockHeight += lineHeight
        currentLine = ""
    }

    // Pre render lines
    for (let n = 0; n < chunks.length; n++) {
        let chunk = chunks[n]
        // Skip whitespace chars at the beginning of lines
        if (currentLine === "" && chunk.match(/\s/)) continue

        // Process line breaks
        if (chunk === "__BR__") {
            addLine()
            continue
        }

        let testLine = currentLine + chunk
        let testLineWidth = ctx.measureText(testLine).width

        if (testLineWidth > width) {
            addLine()

            if (n < chunks.length - 1) {
                if (chunk.match(/\s/)) currentLine = ""
                else currentLine = chunk
            }
        } else {
            currentLine = testLine
        }
    }
    if (currentLine !== "") addLine()

    if (textAlignVertical === "middle") {
        if (textBlockHeight < height) {
            y += (height - textBlockHeight) / 2
        }
    }

    for (let line of lines) {
        y += lineHeight
        ctx.fillText(line, x, y)
    }

    // ctx.fillText(currentLine, x, y)
}
