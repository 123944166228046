import config from "@/config"

export default {
    version: config.PREFERENCES_VERSION,
    darkMode: false,
    showDinamoFonts: true,
    defaultTextDirection: "ltr",
    newTabTextCategory: "overview",
    showHiddenFontAxes: false,
    sidebar: {
        showColor: true,
    },
}
