import db from "@/db"

export default {
    togglePreferenceValue(state, name) {
        state.preferences[name] = !state.preferences[name]
        db.savePreferences()
    },

    setPreferenceValue(state, data) {
        state.preferences[data.name] = data.value
        db.savePreferences()
    },
}
