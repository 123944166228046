// import {reactive} from "vue"
import utils from "@/misc/utils"
import { validateTab } from "@/store/defaults/tabMisc"
import defaultFg from "@/store/defaults/fg"

export function createTabText() {
    return validateTab({
        id: utils.uid(),
        type: "text",
        name: "Text",
        title: "Text",
        viewMode: null,
        selectedFonts: [],
        fonts: [],
        fontData: {},
        data: {
            text: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
            liveText: "one",
            axes: {},
            style: {
                fg: {
                    ...defaultFg(),
                },
                bg: {
                    backgroundColor: "#ffffff",
                },
            },
        },
    })
}
