import store from "@/store"
import utils from "@/misc/utils"
import animationSpeeds from "@/data/animationSpeeds"
import easingFunctions from "@/data/easingFunctions"

function handleMouseMove(e) {
    if (!store.state.settings.mouseMode) return
    if (store.getters.axes == null) return
    let index = 0
    // eslint-disable-next-line no-unused-vars
    for (const [axisTag, axis] of Object.entries(store.getters.axes)) {
        if (index > 1) break
        let scalar = index === 1 ? e.clientX / window.innerWidth : e.clientY / window.innerHeight
        let currentValue = axis.min + (axis.max - axis.min) * scalar
        store.dispatch("updateAxisValue", { axis, value: currentValue })
        index++
    }
}

export default {
    updateAxisValueFromSlider(context, data) {
        // When updating from a user interaction on the slider,
        // stop existing animations
        context.dispatch("stopAxisMic", data.axis)
        context.commit("setAxisAnimating", { axis: data.axis, animating: false })
        context.dispatch("updateAxisValue", data)
    },

    updateAxisValue(context, data) {
        data.value = parseFloat(data.value)
        if (data.value < data.axis.minDefault || data.value > data.axis.maxDefault) return // Don't allow illegal values
        context.commit("setAxisValue", data)
    },

    resetTabFontAxes(context, data) {
        try {
            data.tab.fontData[data.font.id].axes = context.getters.getFontAxesDefaultStates(data.font)
        } catch (e) {
            utils.error("Failed: resetTabFontAxes")
            utils.error(e)
        }
    },

    resetTabAllFontAxes(context, data) {
        for (let fontId in data.tab.fontData) {
            let font = context.getters.getFontById(fontId)
            context.dispatch("resetTabFontAxes", { tab: data.tab, font })
        }
    },

    resetAllAxes(context) {
        context.dispatch("stopTabAxisInteractions")
        context.dispatch("resetTabAllFontAxes", { tab: context.getters.tab })
    },

    toggleAllAxisAnimating(context) {
        if (context.getters.animating) context.dispatch("stopAnimation")
        else context.commit("startAxesAnimating")
        if (context.getters.animating) context.dispatch("startAnimation")
    },

    toggleAxisSpeed(context, axisTag) {
        let currentIndex = animationSpeeds.indexOf(context.getters.axes[axisTag].speed)
        let speed = animationSpeeds[(currentIndex + 1) % animationSpeeds.length]
        context.commit("setAxisSpeed", { axisTag, speed })
    },

    toggleAnimateAxis(context, axisTag) {
        context.commit("toggleAxisAnimating", axisTag)
        if (context.getters.animating) context.dispatch("startAnimation")
    },

    startAnimation(context) {
        context.dispatch("resetUserMedia")
        context.dispatch("stopMouseMode")
        context.dispatch("animate")
    },

    stopAnimation(context) {
        context.commit("stopAxesAnimating")
    },

    animate(context) {
        // Show a warning for animating large amounts of text
        if (context.getters.tab.data.text.length > 50) {
            context.dispatch("showNotification", {
                name: "animating-long-texts",
                once: true,
                text: `Animating long texts causes lag`,
                status: "warning",
            })
        }

        context.dispatch("animateFrame")
    },

    animateFrame(context) {
        // Exit if no axes animating
        if (!context.getters.animating) return

        // Animate each axis
        for (const [axisTag, axis] of Object.entries(context.getters.axes)) {
            // Skip the axis if it's not animating
            if (!axis.animating) continue

            let currentValue = axis.value
            let defaultFramesPerTransition = 90

            if (axis.loopType === "alternate") {
                if ((axis.t >= 1 && axis.direction === 1) || (axis.t <= 0 && axis.direction === -1))
                    context.commit("swapAnimatingAxisDirection", axisTag)
            }

            let newTime = axis.t + (1 / defaultFramesPerTransition) * axis.direction * axis.speed

            if (axis.loopType === "forwards" && axis.t >= 1) newTime = 0

            context.commit("setAxisTime", { axisTag, t: newTime })

            let transitionMultiplier = easingFunctions[axis.easing].function(newTime)
            currentValue = axis.min + (axis.max - axis.min) * transitionMultiplier

            context.dispatch("updateAxisValue", { axis, value: currentValue })
        }

        // Setup next frame
        setTimeout(() => window.requestAnimationFrame(() => context.dispatch("animateFrame")), 1000 / 60)
    },

    // Volume is a value between 0 and 1
    animateFrameMicrophone(context, data) {
        for (const [axisTag, axis] of Object.entries(context.getters.axes)) {
            if (!data.axes.includes(axisTag)) continue
            let currentValue = axis.min + (axis.max - axis.min) * data.volume
            context.dispatch("updateAxisValue", { axis, value: currentValue })
        }
    },

    toggleMouseMode(context) {
        if (!context.state.settings.mouseMode) context.dispatch("startMouseMode")
        else context.dispatch("stopMouseMode")
    },

    startMouseMode(context) {
        context.dispatch("stopTabAxisInteractions")
        if (context.getters.axes == null) return
        context.commit("setMouseMode", true)
        document.addEventListener("mousemove", handleMouseMove)
    },

    stopMouseMode(context) {
        context.commit("setMouseMode", false)
        document.removeEventListener("mousemove", handleMouseMove)
    },
}
