import utils from "@/misc/utils"
import { textBox } from "@/misc/canvas"
import db from "@/db"
import api from "@/misc/api"

export default {
    initApp(context) {
        context.commit("setAppStandalone")
        utils.introMessage(
            context.state.config.VERSION,
            context.state.config.APP_ID,
            context.state.config.NODE_ENV,
            context.state.standalone,
            context.state.config.BUILD_DATE.getTime()
        )

        context.dispatch("initMessages")
        context.dispatch("initUser")

        db.getLastSession().then((res) => {
            context.state.lastSessionExists = res != null
        })

        context.dispatch("initUrlParamSession")
    },

    initMessages(context) {
        // context.state.config.APP_ENV = "production"

        // Get and display messages from the API depending on the app environment
        if (["production", "staging"].includes(context.state.config.APP_ENV)) {
            api.get(`/messages/${context.state.config.APP_ENV}`).then((data) => {
                for (let message of data.messages) context.dispatch("showNotification", message)
            })
        }
    },

    initUrlParamSession(context) {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)

        if (urlParams.has("font")) {
            const param_font = urlParams.get("font")
            let param_familyName = urlParams.get("family-name")
            if (param_familyName === "") param_familyName = null

            if (param_font != null && param_font !== "")
                context.dispatch("openFontFromUrl", { url: param_font, familyName: param_familyName })
        }
    },

    async togglePresentationMode(context) {
        context.dispatch("updateTabViewMode", null)
        await context.commit("togglePresentationMode")

        if (context.state.settings.presentationMode) {
            context.dispatch("updateTabFgValue", {
                name: "centerMode",
                value: true,
            })
            await context.commit("setTabDataValue", {
                name: "autoSizeText",
                value: true,
            })
            context.dispatch("autoSizeText")
        } else {
            context.commit("setSidebarOpen", true)
            context.dispatch("autoSizeText")
            context.dispatch("updateTabFgValue", {
                name: "centerMode",
                value: false,
            })
            await context.commit("setTabDataValue", {
                name: "autoSizeText",
                value: false,
            })
        }
    },

    toggleSidebar(context) {
        context.commit("setSidebarOpen", !context.state.sidebar.open)
    },

    takeScreenshot(context) {
        context.dispatch("showNotification", {
            name: "screenshot",
            once: true,
            text: `Taking Screenshots is an experimental/beta feature`,
            type: "function",
            icon: "&#128247;",
        })

        const canvasScale = 2
        const textView = document.querySelector(".text-view")
        const textViewBox = textView.getBoundingClientRect()
        const textViewBody = document.querySelector(".text-view__body")
        const scrollTop = textViewBody.scrollTop * canvasScale
        const textViewText = document.querySelector(".text-view__text")
        const textViewTextStyle = window.getComputedStyle(textViewText)

        const paddingTop = parseInt(textViewTextStyle["padding-top"]) * canvasScale
        const paddingLeft = parseInt(textViewTextStyle["padding-left"]) * canvasScale
        const paddingRight = parseInt(textViewTextStyle["padding-right"]) * canvasScale
        const paddingBottom = parseInt(textViewTextStyle["padding-bottom"]) * canvasScale

        const canvas = document.getElementById("text-view__canvas")
        const ctx = canvas.getContext("2d")

        canvas.width = textViewBox.width * canvasScale
        canvas.height = textViewBox.height * canvasScale
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.fillStyle = context.getters.bg.backgroundColor
        ctx.fillRect(0, 0, canvas.width, canvas.height)

        canvas.style.fontVariationSettings = context.getters.fontVariationSettings
        // Always ensure liga and calt are activated
        canvas.style.fontFeatureSettings =
            utils.getOpentypeFeaturesArrayAsCssString(context.getters.fg.fontFeatureSettings) + ' "liga", "calt"'

        let textAlign = context.getters.fg.textAlign
        if (textAlign === "left" && context.getters.fg.direction === "rtl") textAlign = "right"
        let textAlignVertical = "top"
        if (context.getters.fg.centerMode) {
            textAlign = "center"
            textAlignVertical = "middle"
        }

        textBox(
            ctx,
            context.getters.tab.data.liveText,
            paddingLeft,
            paddingTop - scrollTop,
            canvas.width - paddingLeft - paddingRight,
            canvas.height - paddingTop - paddingBottom,
            context.getters.fg.fontSize * canvasScale,
            context.getters.fg.lineHeight,
            context.getters.fontFaceId,
            textAlign,
            textAlignVertical,
            context.getters.fg.color
        )

        let dt = canvas.toDataURL("image/png")
        dt = dt.replace("data:image/png;base64,", "")

        utils.downloadBase64File(context.getters.fullFontName.replaceAll(" ", "") + "-" + utils.uidShort() + ".png", dt)
    },
}
