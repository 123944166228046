<template>
    <!-- <keep-alive> -->
    <panel v-if="panel != null" :panel="panel" :fullHeight="true" :key="panel.name">
        <component :is="panel"></component>
    </panel>
    <!-- </keep-alive> -->
</template>

<script>
import Panel from "@/components/Generic/Panel/Panel.vue"

export default {
    name: "panel-view",
    components: {
        Panel,
    },
    computed: {
        panel: function () {
            return this.$store.state.panel
        },
    },
}
</script>
