<template>
    <div class="panel__wrapper" v-if="view || tabs">
        <transition appear name="background">
            <div class="panel__background" v-on:click="handleClose"></div>
        </transition>
        <transition appear name="panel">
            <div class="panel" :style="getStyle()">
                <div class="panel__header" v-if="menuTabs != null">
                    <panel-tabs :tabs="menuTabs" :currentTab="tab" @set-tab="setTab"></panel-tabs>
                </div>

                <div class="panel__body custom-scrollbar" :style="getBodyStyle()">
                    <template v-if="view != null">
                        <component :is="view"></component>
                    </template>
                    <template v-else>
                        <keep-alive>
                            <component :is="tab"></component>
                        </keep-alive>
                    </template>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import PanelTabs from "@/components/Generic/Panel/PanelTabs.vue"

export default {
    name: "panel",
    props: ["view", "tabs", "startTab", "showTitleBar"],
    components: {
        PanelTabs,
    },
    data() {
        return {
            tab: this.getStartTab(),
        }
    },
    computed: {
        bodyStyle: function () {
            let style = {}
            if (this.tab.padding != null && !this.tab.padding) style.padding = "0"
            return style
        },

        menuTabs: function () {
            if (this.view != null && this.showTitleBar) return [this.view]
            if (this.tabs != null) return this.tabs
            return null
        },
    },
    methods: {
        handleClose() {
            this.$store.commit("togglePanel")
        },

        getStyle() {
            let style = { width: `760px`, height: `760px` }
            if (this.tab.width) style.width = `${this.tab.width}px`
            return style
        },

        getBodyStyle() {
            let style = {}
            if (this.tab.padding != null && this.tab.padding == false) style.padding = "0"
            return style
        },

        setTab(newTab) {
            this.tab = newTab
        },

        getStartTab() {
            if (this.view != null) return this.view
            if (this.startTab != null) return this.startTab
            if (this.tabs != null) return this.tabs[0]
            return null
        },
    },
}
</script>

<style lang="scss" scoped>
.panel__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    padding: 60px 60px 70px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.panel {
    position: relative;
    max-height: 100%;
    max-width: 100%;
    z-index: 11;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: calc(var(--padding) * 1.5);
    background-color: var(--color--lightest);
    transition: width 0.3s ease, height 0.3s ease;

    &--full-height {
        min-height: 100%;
    }

    &__body {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        padding: calc(var(--padding) * 3);
        position: relative;
    }

    &__background {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.21);
    }

    &:deep(.section) {
        // margin: 0 calc(var(--padding) * -2);
        // padding: calc(var(--padding) * 2);

        // &:last-child {
        //     // padding-bottom: calc(var(--padding) * 3);
        //     margin-bottom: calc(var(--padding) * -2);
        // }
    }
}
</style>
<style scoped>
.background-enter-active {
    transition: opacity 0.2s ease;
}

.background-enter-from {
    opacity: 0;
}

.panel-enter-active {
    transition: opacity 0.2s ease, transform 0.2s ease;
}

.panel-enter-from {
    opacity: 0;
    transform: scale(0.95);
}
</style>
