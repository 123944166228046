<template>
    <div>
        <div class="variation-axis" v-if="fontAxes">
            <div
                class="sidebar__sub-section all-axes section grid"
                style="grid-template-columns: 1fr 1fr 1fr auto"
                v-if="Object.keys(fontAxes).length > 0"
            >
                <button class="button" @click="$store.dispatch('toggleAllAxisAnimating')">
                    <template v-if="$store.getters.animating">
                        <span class="icon s">&#xf04c;</span>&nbsp;Pause
                    </template>
                    <template v-else> <span class="icon s">&#xf04b;</span>&nbsp;&nbsp;Play </template>
                </button>
                <button class="button hit" @click="$store.dispatch('resetAllAxes', $store.state.font)">
                    <span class="icon s">&#xf022;</span>
                    &nbsp;Reset
                </button>
                <button
                    class="button"
                    :class="{ 'cs--red': $store.getters.userMediaActive, glow: $store.getters.userMediaActive }"
                    @click="$store.dispatch('toggleMicrophoneAll')"
                >
                    <span class="icon s">&#127908;</span>
                    &nbsp;Audio
                </button>
                <button
                    class="button square"
                    :class="{
                        'cs--red': $store.state.settings.mouseMode,
                        glow: $store.state.settings.mouseMode,
                    }"
                    @click="$store.dispatch('toggleMouseMode')"
                >
                    <span class="icon">&#57352;</span>
                </button>
            </div>

            <template v-if="Object.keys(fontAxes).length > 0">
                <div class="sidebar__sub-section section" v-for="(axis, axisTag) in fontAxes" v-bind:key="axisTag">
                    <axes-axis :axis="axis" :axisTag="axisTag" :mic="mediaStreamAxes.includes(axisTag)"></axes-axis>
                </div>
            </template>

            <div
                class="sidebar__sub-section instance"
                style="margin-bottom: calc(var(--padding) * 0.75); margin-top: calc(var(--padding) * 1.5)"
            >
                <button
                    class="instance__button"
                    @click="$store.dispatch('downloadCurrentInstance')"
                    :class="{
                        generating: $store.state.downloadingInstance,
                    }"
                    :disabled="!$store.state.online || $store.state.instanceGenerationDisabled"
                >
                    <span v-if="$store.state.downloadingInstance"
                        ><span class="dota"><span>•</span><span>•</span><span>•</span></span></span
                    >
                    <template v-else
                        ><span class="icon s">{{ !$store.state.online ? "&#57351;" : "&#xf019;" }}</span> Generate
                        Static Font File</template
                    >
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import easingFunctions from "@/data/easingFunctions"
import animationSpeeds from "@/data/animationSpeeds"
import AxesAxis from "@/components/Sidebar/Modules/AxesAxis"

export default {
    name: "axes",
    data() {
        return {
            currentlyAnimating: false,
            animationSpeeds,
            easingFunctions,
            mediaStreamAxes: [],
            mediaStream: null,
        }
    },
    components: {
        AxesAxis,
    },
    computed: {
        fontAxes: function () {
            return !this.$store.state.preferences.showHiddenFontAxes
                ? Object.fromEntries(Object.entries(this.$store.getters.axes).filter(([key, axis]) => !axis.hidden)) // eslint-disable-line  no-unused-vars
                : this.$store.getters.axes
        },
    },
    methods: {
        formatAxisValue(value) {
            if (value % 1 === 0) return parseInt(value)
            else return value.toFixed(2)
        },

        getAxisDecimalPlaces(axis) {
            let range = axis.maxValue - axis.minValue
            if (range < 10) return 2
            if (range < 20) return 1
            return 0
        },
    },
}
</script>

<style lang="scss" scoped>
.instance__button {
    background-color: var(--color--yellow);
    width: 100%;

    &.generating {
        animation: generating 1s ease infinite alternate;
    }
}

.sidebar__sub-section.all-axes {
    margin-bottom: calc(var(--padding) * 1.75);
}
</style>
