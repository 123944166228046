import config from "@/config"
// import { createTabText } from "@/store/defaults/tabText"
import utils from "@/misc/utils"

export default {
    create() {
        return {
            id: utils.uid(),
            created: new Date().getTime(),
            version: config.SESSION_VERSION,
            fonts: [],
            tab: null,
            tabs: [],
        }
    },
}
