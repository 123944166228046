<template>
    <div class="style-range">
        <RecycleScroller
            class="scroller"
            :items="$store.getters.instances"
            :item-size="$store.getters.fg.fontSize * 1.2 + 35"
            v-slot="{ item }"
            :buffer="400"
        >
            <div
                class="style-range__row"
                :key="item.name"
                :style="{ height: `${$store.getters.fg.fontSize * 1.2 + 35}px` }"
            >
                <div class="style-range__row__label flex rw jc-sb">
                    <span>{{ item.name }}</span>
                    <span class="coordinates">{{ getCoordinatesAsPrettyString(item.coordinates) }}</span>
                </div>
                <div
                    class="style-range__row__text"
                    :style="{ padding: `0 0 0 ${padding}px`, height: `${$store.getters.fg.fontSize * 1.2}px` }"
                >
                    <font-text
                        :text="text"
                        :style="getInstanceStyle(item)"
                        @input="handleWaterfallLiveInput"
                        @blur="handleBodyTextChange"
                    >
                    </font-text>
                </div>
            </div>
        </RecycleScroller>
    </div>
</template>

<script>
import { nextTick } from "vue"
import FontText from "./FontText"

export default {
    name: "style-range",
    props: ["tab", "style", "text"],
    components: {
        FontText,
    },
    computed: {
        padding: function () {
            let padding = this.tab.data.style.fg.fontSize * 0.5
            if (padding < 10) padding = 10
            if (padding > 20) padding = null
            return padding
        },
    },
    methods: {
        async handleTextLiveInput(e) {
            if (this.tab.data.liveText === e.target.innerHTML) return

            await this.$store.commit("setTabDataValue", {
                name: "liveText",
                value: e.target.innerHTML,
            })

            nextTick(() => {
                if (this.tab.data.autoSizeText) {
                    this.$store.dispatch("autoSizeText")
                }
            })
        },

        handleBodyTextChange(e) {
            this.$store.dispatch("forceUpdateTabTextValue", e.target.innerHTML)
        },

        handleWaterfallLiveInput(e) {
            this.handleTextLiveInput(e)
            // Update other waterfall view text
            let inputs = document.querySelectorAll(".style-range .font-text")
            for (let input of inputs) {
                if (input != e.target) input.innerHTML = e.target.innerHTML
            }
        },

        getInstanceStyle(instance) {
            let style = {
                ...this.style,
            }
            style.fontVariationSettings = instance.fontVariationSettings
            return style
        },

        getCoordinatesAsPrettyString(coordinates) {
            let strs = []
            for (let axisIndex in coordinates) {
                strs.push(`${this.$store.getters.getAxisByIndex(axisIndex).name}: ${coordinates[axisIndex].toFixed(2)}`)
            }
            return strs.join(", ")
        },
    },
}
</script>

<style lang="scss" scoped>
.style-range {
    &__row {
        position: relative;
        overflow: hidden;
        border-bottom: var(--border);
        display: flex;

        // flex-direction: column;
        align-items: center;
        padding-top: 13px;

        &:hover {
            .coordinates {
                opacity: 1;
            }
        }

        &__label {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: 0 calc(var(--padding) * 1);
            padding-top: calc(var(--padding) * 0.5);
            z-index: 2;

            .coordinates {
                color: var(--color--dark);
                opacity: 0;
                transition: opacity 0.2s 0.1s ease;
            }
        }

        &__text {
            white-space: nowrap;
            overflow: hidden;
            line-height: normal;
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0 0 0 calc(var(--padding) * 2);

            &:deep(.font-text) {
                width: 100%;

                div {
                    display: inline !important;
                }

                br {
                    display: none !important;
                }
            }
        }
    }
}
</style>
