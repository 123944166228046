import appMutations from "@/store/mutations/app"
import axesMutations from "@/store/mutations/axes"
import fontMutations from "@/store/mutations/font"
import notificationMutations from "@/store/mutations/notification"
import panelMutations from "@/store/mutations/panel"
import preferencesMutations from "@/store/mutations/preferences"
import sessionMutations from "@/store/mutations/session"
import tabMutations from "@/store/mutations/tab"
import userMediaMutations from "@/store/mutations/userMedia"
import userMutations from "@/store/mutations/user"

export default {
    ...appMutations,
    ...axesMutations,
    ...fontMutations,
    ...notificationMutations,
    ...panelMutations,
    ...preferencesMutations,
    ...sessionMutations,
    ...tabMutations,
    ...userMediaMutations,
    ...userMutations,
}
